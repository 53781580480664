import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';
import BreadcrumbsComponent from '../component/Breadcrumbs';

const Announcements = () => {
  const dispatch = useDispatch();
  const [FeedAnnouncements, setFeedAnnouncements] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const userData = useSelector((state) => state?.user?.userListName)
  const showPostData = useSelector((state) => state.formData.formToSend);

  const indivisualAPI = 'FeedAnnouncements';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)

  const callApi = () => {
    ApiService.get(indivisualAPI, {userId: userData?.userId })
        .then((res) => {
          let data = res.data.data;
          if (data) {
            setFeedAnnouncements(data.map(d=> formLeader(d)));
            // setLeaderSpeak(data);
          }
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box>
      <Box>
        <Typography pb={1.5} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Announcements</Typography>
      </Box>
      <Box>
      {
        FeedAnnouncements.length ?
        <MyConnectLayout 
            posts={FeedAnnouncements} 
            snackBar={snackBar} 
            isHeader={false} 
            isEvent={true} 
            setPostsList={setFeedAnnouncements} 
            indivisualAPI={indivisualAPI} 
            formTransform={formLeader} 
            reactPost={'reactPostPages'} />
        : <PostSkeleton />
      }
      {/* {
        FeedAnnouncements.length ?
        <MyConnectLayout posts={FeedAnnouncements} snackBar={snackBar} isHeader={false} setPostsList={setFeedAnnouncements} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
        : <PostSkeleton />
      } */}
        </Box>
    </Box>
  )
}

export default Announcements