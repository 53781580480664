import { createSlice } from "@reduxjs/toolkit";

export const dashboardData = createSlice({
    name: "dashboardScroll",
    initialState: {
        pageNo: 0,
        scrollFlag: true
    },
    reducers: {
        setPageNo: (state, action) => {
            state.pageNo = action.payload;
        },
        setScrollFlag: (state, action) => {
            state.scrollFlag = action.payload;
        }
    }
});

export const { setPageNo, setScrollFlag } = dashboardData.actions;

export default dashboardData.reducer;
