import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';
import BreadcrumbsComponent from '../component/Breadcrumbs';

const Promotions = () => {
  const dispatch = useDispatch();
  const [Promotions, setPromotions] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  
  const indivisualAPI = 'Promotions';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)

  const userData = useSelector((state) => state?.user?.userListName)

  const callApi = () => {
    ApiService.get(indivisualAPI,{userId: userData?.userId })
      .then((res) => {
        //console.log({ res });
        let data = res.data.data;
        if (data) {
          //console.log({ data });
          setPromotions(data.map(d=> formLeader(d)));
          // setLeaderSpeak(data);
        }
      })
      .catch((error) => {
        //console.log(error);
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box>
      <Box>
      {/* <Typography variant="h4" gutterBottom>Promotions</Typography> */}
      <Typography pb={1.5} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Promotions</Typography>
      </Box>
      <Box>
      {
          Promotions.length ?
          <MyConnectLayout posts={Promotions} snackBar={snackBar} isHeader={false} isAwardHeader={false} isAwardTo={false} isCelebration={true} isPromotions={true} setPostsList={setPromotions} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
          : <PostSkeleton />
        }
      </Box>
    </Box>
  )
}

export default Promotions