import * as React from 'react';
import './Header.css';
import Logo from '../../assets/images/image4.png';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AppsIcon from '@mui/icons-material/Apps';
import Settings from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import ApiService from '../../utils/ApiService';
import { removeSearch, removeSearchList, searchDataList, searchValueSet } from '../../features/headerSearch/headerSearch';
import { useState } from 'react';
import { useMemo } from 'react';
import { loaderData } from '../../features/loader/loaderDashboard';
import { setUserInfo } from '../../features/userInfoStore/userSlice';
import AvatarTingg from "../../assets/images/AvatarTingg.png"
import LogoTingg from "../../assets/images/LogoTingg.png"
import Skeleton from '@mui/material/Skeleton';

const ITEM_HEIGHT = 48;
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '3rem',
    border: '1px solid #EEF6FF',
    backgroundColor: '#FFFFFF;',
    '&:hover': {
        backgroundColor: '#FFFFFF;',
    },
    // marginRight: theme.spacing(2),
    // width: '80%',
    // [theme.breakpoints.up('sm')]: {
    //     marginLeft: theme.spacing(1),
    //     width: 'auto',
    //     marginLeft: 'none !important'
    // },
    // [theme.breakpoints.up('xs')]: {
    //     // marginLeft: theme.spacing(3),
    //     width: '100% !important',
    //     borderRadius: '2rem !important',
    //     // marginLeft: '23rem !important'
    // },
    // [theme.breakpoints.up('lg')]: {
    //     marginLeft: theme.spacing(1),
    //     width: 'auto',
    //     marginLeft: '36rem !important'
    // },

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),

        width: '100% !important',
        [theme.breakpoints.up('md')]: {
            //  width: '35rem',
        },
    },
}));

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export default function PrimarySearchAppBar() {
    const { image } = useSelector(state => state.profile);
    const [anchorE3, setAnchorE3] = React.useState(null);
    const open2 = Boolean(anchorE3);
    const handleClick2 = (event) => {
        setAnchorE3(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorE3(null);
    };

    const dispatch = useDispatch();
    const componentDrawer = (id) => {
        dispatch(openDrawer(id));
    }
    const cancel = (id) => {
        dispatch(closeDrawer(id));
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [searchValue, setSearchValue] = useState('')
    const [companyData, setCompanyData] = useState([])
    const searchValueState = useSelector((state) => (state.search?.searchValue));
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const userList = useSelector((state) => state?.user?.userListName)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const [anchorE2, setAnchorE2] = React.useState(null);
    const open = Boolean(anchorE2);
    const handleClick1 = (event) => {
        setAnchorE2(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorE2(null);
    };

    ////
    const [anchorE5, setAnchorE5] = React.useState(null);
    const openSearch = Boolean(anchorE5);
    const handleSearchClick = (event) => {
        setAnchorE5(event.currentTarget);
    };
    const handleSearchClose = () => {
        setAnchorE5(null);
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);


    //list view//
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);
    /////

   


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <>
            <Menu
                className='menu-btn-style'
                anchorEl={anchorEl}
                id="account-menu"
                open={isMenuOpen}
                onClose={handleMenuClose}
                // onClick={handleMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        // width: '12rem',
                    },
                }}
            >
                {/* <MenuItem> */}
                <Typography px={2} pt={1} variant="body2" color="text.primary">Welcome {userList?.userName + " " + userList?.lastName},</Typography>
                {/* <Box>
                   
                        <Box> </Box>
                        <Box>
                            
                        </Box>
                    </Box> */}
                {/* </MenuItem> */}
                <Link onClick={handleMenuClose} to="/my-favorite" style={{ textDecoration: 'none' }}>
                    <MenuItem>
                        <ListItemIcon>
                            <StarIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="body2" color="text.primary">My Favorite</Typography>

                    </MenuItem>
                </Link>
                <Link to="/logout" style={{ textDecoration: 'none' }}>
                    <MenuItem>
                        <Typography variant="body2" color="text.primary">LOG OUT</Typography>

                    </MenuItem>

                </Link>
            </Menu>
        </>
    );
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >


            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >

                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    // ----------------- SEARCH API CALLING METHOD ------------
    function userListHandler() {
    }
    useMemo(() => {
        if (searchValueState) {
            setSearchValue(searchValueState)
        } else {
            setSearchValue('')
        }
    }, [searchValueState])
    useEffect(
        function () {
            if (searchValue) {
                let delaySearch;
                dispatch(loaderData(true))
                delaySearch = setTimeout(() => {
                    userListHandler();
                }, 1000);
                return () => clearTimeout(delaySearch);
            } else {
                dispatch(removeSearchList([]));
                dispatch(removeSearch(''));
            }
        },
        [searchValue]
    );
    const [showSearchInput, setShowSearchInput] = useState(false);
    // const [searchValue, setSearchValue] = useState('');

    const handleSearchIconClick = () => {
        setShowSearchInput(!showSearchInput);
    };

    const [isLoading, setIsLoading] = useState(false);
    // const logoFallback = (
    //     <Skeleton variant="circular" width={40} height={40} sx={{ bgcolor: 'red' }} />
    //   );
    
    useEffect(() => {
        ApiService.get('userInfo').then(res => {
            let response = res.data.data;
            console.log('a5',response);
    
            if (response) {
                var avatar = response.userAvatar;
                console.log('a5',avatar);
                if (response.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || avatar === "https://gatewayapi.eela.tech/media/") {
                    response.userAvatar = AvatarTingg;
                }
    
                dispatch(setUserInfo({
                    ...response,
                    userAvatar: response.userAvatar,
                }));
            }
        });
    

        ApiService.get('comapnyLog').then(res => {
            let response = res.data.data
            if (response) {
                setCompanyData(response)
                setIsLoading(true)
            }
        })
    }, [])


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className='app-style'>
                <Toolbar sx={{ pr: '0 !important' }}>
                    <Grid container>
                        <Grid item xs={4} sm={2} md={2}>
                            <Box className='logo' sx={{ paddingTop: '3px' }}>
                                {isLoading ?
                                <img className='logoSize' src={companyData.company_logo}
                                    onError={(e) => {
                                        setIsLoading(false)
                                    }}
                                    alt="React Logo" />  :
                                    <Skeleton variant="circular" width={40} height={40} sx={{marginTop:'5px'}} />
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={3} sm={7} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%' }}>
                            {/* {window.location.pathname == "/" || window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) == "/folder" ?  */}
                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon sx={{ color: '#64748B' }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        value={searchValue}
                                        onChange={(e) => dispatch(searchValueSet(e.target.value))}
                                        placeholder="Search.."
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search>
                            </Box>
                        </Grid>
                        <Grid item xs={5} sm={3} md={2}>
                            <Box sx={{ display: { xs: 'none', sm: 'flex', justifyContent: 'flex-end' } }}>
                                {/* <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                // onClick={handleClick1}
                                >
                                    <Badge color="error">
                                        <NotificationsActiveIcon />
                                    </Badge>
                                </IconButton> */}
                                {/* <Box>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Badge color="error">
                                    <AppsIcon />
                                    </Badge>
                                </IconButton>
                                <Menu
                                    // className='menu-size'
                                    id="basic-menu"
                                    sx={{ top: '-20px', left: '-80px' }}
                                    anchorEl={anchorE2}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Box>
                                        <Box>
                                            <ListItem secondaryAction={
                                                <Box>
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleClick2}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        sx={{ left: '-100px' }}
                                                        anchorEl={anchorE3}
                                                        open={open2}
                                                        onClose={handleClose2}
                                                        PaperProps={{
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem> Mark all as read</MenuItem>
                                                    </Menu>
                                                </Box>}>
                                                <ListItemText primary={<Typography variant='h6'>Notifications (7)</Typography>} secondary={<Typography variant="body2">You have 2 unread messages</Typography>} />
                                            </ListItem>
                                        </Box>
                                    </Box>
                                </Menu>
                                </Box> */}
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    {userList?.userAvatar != "https://gatewayapi.eela.tech/media/?file=default.png" ?
                                        <Avatar id="profile-icon" alt="user-avatar" src={userList?.userAvatar} sx={{ width: 35, height: 35 }} />
                                        :
                                        <AccountCircleIcon />}
                                </IconButton>
                            </Box>
                            <Box sx={{ display: { xs: 'flex', sm: 'none', justifyContent: 'flex-end' } }}>
                                {/* <IconButton
                                    size="large"
                                    color="inherit"
                                >
                                    <Badge color="error">
                                        <NotificationsActiveIcon />
                                    </Badge>
                                </IconButton> */}
                                <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={openSearch ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : 'false'} // Change here
                                        aria-haspopup="true"
                                        onClick={handleSearchClick}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                            left: '40px' // Change here
                                        }}
                                        anchorEl={anchorE5}
                                        open={openSearch}
                                        onClose={handleSearchClose}
                                    >
                                        {/* {window.location.pathname == "/" || window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) == "/folder" ?  */}
                                        <Box className='search-panel'>
                                            <Search isTrash={false} >
                                                <SearchIconWrapper>
                                                    <SearchIcon sx={{ color: '#64748B' }} />
                                                </SearchIconWrapper>
                                                <StyledInputBase
                                                    sx={{ color: 'black' }}
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    placeholder="Search file.."
                                                    inputProps={{ 'aria-label': 'search' }}
                                                />
                                            </Search>
                                        </Box>
                                        {/* : null} */}
                                    </Menu>

                                    {/* <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="search"
                                    onClick={handleSearchIconClick}
                                    color="inherit"
                                >
                                    <SearchIcon />
                                </IconButton> */}
                                    {/* <Search onClick={handleSearchIconClick}>
                                    <SearchIconWrapper>
                                        <SearchIcon  />
                                    </SearchIconWrapper>
                                </Search> */}
                                    {/* <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon sx={{ color: '#64748B' }} />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        value={searchValue}
                                        onChange={(e) => dispatch(searchValueSet(e.target.value))}
                                        placeholder="Search file's name and tag"
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search> */}
                                </Box>
                                {/* <Box>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Badge color="error">
                                    <AppsIcon />
                                    </Badge>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    sx={{ top: '-20px', left: '-80px' }}
                                    anchorEl={anchorE2}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <Box>
                                        <Box>
                                            <ListItem secondaryAction={
                                                <Box>
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleClick2}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="long-menu"
                                                        sx={{ left: '-100px' }}
                                                        anchorEl={anchorE3}
                                                        open={open2}
                                                        onClose={handleClose2}
                                                        PaperProps={{
                                                            style: {
                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                width: '20ch',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem> Mark all as read</MenuItem>
                                                    </Menu>
                                                </Box>}>
                                                <ListItemText primary={<Typography variant='h6'>Notifications (7)</Typography>} secondary={<Typography variant="body2">You have 2 unread messages</Typography>} />
                                            </ListItem>
                                        </Box>
                                    </Box>
                                </Menu>
                                </Box> */}
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    {userList?.userAvatar != "https://gatewayapi.eela.tech/media/?file=default.png" ?
                                        <Avatar id="profile-icon" alt="user-avatar" src={userList?.userAvatar} sx={{ width: 35, height: 35 }} />
                                        :
                                        <AccountCircleIcon />}
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {/* {showSearchInput && (
            <Box className='search-panel'>
                <Search isTrash={false} >
                    <StyledInputBase
                        sx={{ color: 'black' }}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search file, folder's name and tag"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Box>
            )} */}


            {renderMobileMenu}
            {renderMenu}
            {/* <SharedDrawer id='setting'>
                <Box className="tasklist_drawer">
                    <SettingDrawer parentId='setting' />
                </Box>
            </SharedDrawer> */}
        </Box>
    );
}