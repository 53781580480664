import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';

const OrganizationFeed = ({ sharedHelper }) => {
  const userData = useSelector((state) => state?.user?.userListName)
  const userId = userData?.userId
  const dispatch = useDispatch();
  const [favoriteFeeds, setfavoriteFeeds] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  const flag = false
  const indivisualAPI = 'favoriteFeeds';

  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)

  const callApi = () => {
    if (userData?.userId != undefined) {
      ApiService.get(indivisualAPI, {userId: userData?.userId}, userData?.userId )
        .then((res) => {
            
          let data = res.data;
          if (data) {
            setfavoriteFeeds(data.map(d => formLeader(d)));
          }
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
    }
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);


 

  return (
    <Box>
      <Box>
        <MyConnectLayout posts={favoriteFeeds} setFlag={flag} snackBar={snackBar} isHeader={false} isEvent={true} isAwardHeader={false} isAwardTo={false} isCelebration={false} isStories={true} setPostsList={setfavoriteFeeds} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} isOrganisationalFeed={true} />
      </Box>
    </Box>
  )
}

export default OrganizationFeed 