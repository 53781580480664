import React, { useState } from "react";
import { Box, Grid, Card } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import encriptionHelper from '../utils/encriptionHelper';

function PhotoCollage(props,post,reactPost) {
    // const feedPostName = useSelector(state => state.routing.currentRoute);
    const feedPostData = props.feedPostData;
    const feedPostName = props.feedPostName;
    const indivisualAPI = props.indivisualAPI;

    // console.log("differenciate",{feedPostData,feedPostName,indivisualAPI})
    // const [feedPostData, setFeedPostData] = useState(location.state.feedPostData);

    const encodeUrl = (post) => {
        const queryStringObject = {
            indivisualAPI: indivisualAPI,
            reactPost: reactPost,
            feedPostName: feedPostName,
            feedPostData: feedPostData,
        };
        const encodedQueryString = encriptionHelper().encodeAndEncryptURLParams(queryStringObject);
        return `/feed/${props.postId}?query=${encodedQueryString}`;
    }

    return (
        <Box className="image-container">
                {/* <Box className="top-image-container" component={Link} to={`/feed/${props.postId}`} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }}> */}
                <Box className="top-image-container" component={Link} to={encodeUrl(post)} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }}>   
                    {props?.multipleImages?.images.slice(0, 2).map(d => (
                        <Box key={d.id} className="top-image border">
                            <img
                                src={d.carouselItem ? d.carouselItem : d.image}
                                alt="collage-image"
                                className="responsive-img post-radius img-fit-top"
                            />
                        </Box>
                    ))}
                </Box>
                {props?.multipleImages?.images.length > 2 ? 
                <Box className="media-grid bottom-image-container">
                    {props?.multipleImages?.images.length > 4 ?
                        props?.multipleImages?.images.slice(2, 5).map((d, i) => (
                        <Box key={d.id} className="image-source border">
                                {
                                    i === props?.multipleImages?.images.slice(2, 5).length - 1 && (
                                        // <Box className="backdrop-container" sx={{textDecoration: 'none'}}  component={Link} to={`/feed/${props.postId}`} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }} >
                                        <Box className="backdrop-container" sx={{textDecoration: 'none'}}  component={Link} to={encodeUrl(post)} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }} >   
                                            <Box className="backdrop"></Box>
                                            <Box className="span-classes">+{props?.multipleImages?.images.length - props?.multipleImages?.images.slice(1, 5).length}</Box>
                                        </Box>
                                )}
                                <img
                                    src={d.carouselItem ? d.carouselItem : d.image}
                                    alt="collage-image"
                                    className="post-radius responsive-img"
                                />
                        </Box>
                        ))
                        : props?.multipleImages?.images.slice(2, props?.multipleImages?.images.length).map((d, i) => (
                            // <Box key={d.id} className="image-source border" component={Link} to={`/feed/${props.postId}`} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }}>
                            <Box key={d.id} className="image-source border" component={Link} to={encodeUrl(post)} state={{ feedPostData: feedPostData, feedPostName: feedPostName, indivisualAPI: indivisualAPI  }}>    
                                <img
                                    src={d.carouselItem ? d.carouselItem : d.image}
                                    alt="collage-image"
                                    className="responsive-img post-radius img-fit"
                                />
                            </Box>
                        ))}
                </Box> : null}
        </Box>
    );
}

export default PhotoCollage;
