import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';

const CompanyWithAHeart = () => {
  const dispatch = useDispatch();
  const [Social, setSocial] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  const indivisualAPI = 'Social';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)

  const userData = useSelector((state) => state?.user?.userListName)

  const callApi = () => {
    ApiService.get(indivisualAPI,{userId: userData?.userId })
    .then((res) => {
      //console.log({ res });
      let data = res.data.data;
      if (data) {
        console.log({ data,formLeader });
        data.map(v => console.log({v,leader: formLeader(v)}))
        let leader = data.map(d=> { console.log(d,formLeader(d));return formLeader(d)})
        console.log({leader})
        setSocial(data.map(d=> formLeader(d)));
        // setLeaderSpeak(data);
      }
    })
    .catch((error) => {
      //console.log(error);
      setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
    });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);




  return (
    <Box>
      <Box>
      <Typography variant="h6" pb={.7} gutterBottom>Company With A Heart</Typography>
      </Box>
      <Box>
      {
          Social.length ?
          <MyConnectLayout posts={Social} snackBar={snackBar} isHeader={false} isCompany={true} isMore={true} isEclipse={true} setPostsList={setSocial} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
          : <PostSkeleton />
        }
          </Box>
    </Box>
  )
}

export default CompanyWithAHeart