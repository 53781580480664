import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';

const Events = () => {
  const dispatch = useDispatch();
  const [Events, setEvents] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  
  const indivisualAPI = 'Events';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)

  const userData = useSelector((state) => state?.user?.userListName)
  const callApi = () => {
    ApiService.get(indivisualAPI,{userId: userData?.userId })
      .then((res) => {
        //console.log({ res });
        let data = res.data.data;
        if (data) {
          //console.log({ data });
          setEvents(data.map(d=> formLeader(d)));
          // setLeaderSpeak(data);
        }
      })
      .catch((error) => {
        //console.log(error);
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box>
      <Box>
        <Typography variant="h6" pb={.7} gutterBottom>Events</Typography>
      </Box>
      <Box>
      {
        Events.length ?
        <MyConnectLayout 
            posts={Events} 
            snackBar={snackBar} 
            isHeader={false} 
            isEvent={true} 
            setPostsList={setEvents} 
            indivisualAPI={indivisualAPI} 
            formTransform={formLeader} 
            reactPost={'reactPostPages'} />
        : <PostSkeleton />
      }
      </Box>
    </Box>
  )
}

export default Events