import React, { useState, useEffect } from 'react'
import { Card,ContentCopy,Pagination, Typography, Grid, Box, styled, Divider, Tooltip,Tab,
  CardHeader, CardContent, CardActions,ArrowForwardOutlined, Button,Stack} from '../../../utils/AllImportsHelper'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// import PropTypes from 'prop-types';
// import InternalLinks from './InternalLinks';
// import SocialLinks from './SocialLinks';
import { Link } from 'react-router-dom';
import DynamicSnackbar from '../../../features/snackbar/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar, closeSnackbar } from '../../../features/snackbar/snackbarSlice';
import ApiService from '../../../utils/ApiService';
import DOMPurify from 'dompurify';
import SkeletonCard from '../../MoreComponent/SkeletonCard'
import BreadcrumbsComponent from '../../Breadcrumbs';

const MUICard = styled(Card)(({ theme }) => ({
  padding: 0,
  border: '1px solid #E3E3E3',
  ':hover': {
      background: 'rgba(238, 246, 255, 1)'
  }

}));


function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const ImportantLinks = () => {
  const [internal, setInternal] = useState([])
  const [listTabData, setListTabData] = useState([]);
  const [meta, setMeta] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    //     const selectedTabData = internal.find((tab) => tab.id === newValue);
    // if (selectedTabData) {
    //   setListTabData(selectedTabData.Link);
    // }
        console.log(newValue)
      };

  const sanitizeAndStyleHTML = (html) => {
      return DOMPurify.sanitize(html)
          .replace(/<strong>/g, '') // Remove <strong> tags
          .replace(/<\/strong>/g, '') // Remove </strong> tags
          .replace(/<ul>/g, '<div>')
          .replace(/<\/ul>/g, '</div>')
          .replace(/<li>/g, ' ')
          .replace(/<\/li>/g, '<br>');
  };

  const dispatch = useDispatch();
  const handlePageChange = (event, newPage) => {
      setCurrentPage(newPage);
  };

  useEffect(() => {
      ApiService.get('importantLinks').then(internal => {
          setInternal(internal.data.data)
          setMeta(internal?.data?.meta)
           console.log(internal.data.data)
           
      })
  }, []);

  const [copiedLinkId, setCopiedLinkId] = useState(null);

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url)
      .then(() => {
        // Handle the copy success, if needed
        console.log('Link copied:', url);
        dispatch(openSnackbar({ dataLoad: true, message: 'Your link has been copied successfully.', severity: "info" }));
      })
      .catch((error) => {
        console.error('Copy failed: ', error);
      });
  };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
    display: 'grid'
  };  

  return (
    <>
    {/* <Typography variant="subtitle1" fontWeight={500} pb={1}>
          Important Links
          </Typography> */}
          <Typography pb={1} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Important Links</Typography>
      <Card sx={{ minHeight: 'calc(95vh - 138px)',zIndex: (window.innerWidth > 1024) ? 1 : 0 }}>
       <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}  variant="scrollable" aria-label="Important Links">
          {internal.map((tab,index) => (
            <Tab label={tab.name} value={index} key={tab.id} {...a11yProps(tab.id)} sx={{textTransform:'capitalize'}} />
            // <Tab label="Social Links" {...a11yProps(2)} value="2" />
            ))}
          </TabList>
        </Box>
        
        {/* <TabPanel value={value} sx={{px:0}}>
        {listTabData.map((link) => (
           <Typography sx={{ fontSize: 18, fontWeight: 500 }} key={link.id}>
                                                                    {link.name}
                                                                </Typography>
                                                                 ))}
        </TabPanel> */}
        
         {/* <TabPanel value="2" sx={{px:0}}>ygygygy</TabPanel> */}
         {internal.map((tabData,index) => (
            <TabPanel value={index} key={index} sx={{ px: 0 }}>
             
                  <Box sx={{ flexGrow: 1, pt: 1 }}>
                <Grid container spacing={2}>
                {tabData.internalLink.map((tab) => (
                        <Grid item key={tab.id} xs={12} sm={6} md={4} lg={4}>
                            <MUICard>
                                <CardHeader sx={{ pb: '0px' }}
                                    title={
                                        <Tooltip title={tab.jobTitle}>
                                            <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >{tab.name}</Typography>
                                        </Tooltip>
                                    }
                                    subheader={
                                        // <a href={tab.url} target='_blank' style={{textDecoration:'none'}} >
                                        //   <Typography variant="body1" py={1} color='primary' fontWeight={500} >{tab.url}</Typography>
                                        // </a>
                                        // <Grid container direction='row'>
                                        // <Grid item sm={12}>
                                        <Typography
                                        variant="body2"
                                        color='primary'
                                        fontWeight={500}
                                        my={1}
                                        component="a" // Use an anchor tag as the component
                                        href={tab.url}
                                        target='_blank'
                                        className='overflow-text-Announcement'
                                        rel="noopener noreferrer" // For security reasons, add the rel attribute
                                        style={{ textDecoration: 'none',wordBreak: 'break-word' }}
                                      >
                                        {tab.url}
                                      </Typography>
//                                       </Grid>
// </Grid>
                                    }
                                />
                                <CardContent sx={{ pt: '0px' }}>
                                <Tooltip title={tab.description}>
                                    <Typography variant="body2" className='overflow-text' sx={{height:'40px'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(tab.description)) }} />
                                </Tooltip>
                                </CardContent>
                                <Divider variant="fullWidth" />
                                <CardActions sx={{ display:'block',textAlign:'end' }}>

                                <Button
          size="small"
          startIcon={<ContentCopy />}
          onClick={() => handleCopyLink(tab.url)} // Pass the URL you want to copy
        >
          COPY LINK
        </Button>
                                </CardActions>
                            </MUICard>
                        </Grid>
                    ))}
                </Grid>

            </Box>
           
            </TabPanel>
          ))}
      
      </TabContext>
      <DynamicSnackbar />
      </Card>
    </>
  )
}

export default ImportantLinks