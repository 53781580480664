import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';

const HiddenPost = ({sharedHelper}) => {
  const userData = useSelector((state) => state?.user?.userListName)
  console.log(userData.userId)
  const dispatch = useDispatch();
  const [HiddenPost, setHiddenPost] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  
  const indivisualAPI = 'HiddenPostUser';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)
  const flag = false

  const callApi = () => {
    if (userData?.userId != undefined) {
      ApiService.get(indivisualAPI, { userId: userData?.userId })
        .then((res) => {
          let data = res.data;
          if (data) {
            // console.log({data})
            // console.log({formLeader: data.filter(v => v.post.length).map(d=> formLeader(d))})
            setHiddenPost(data.filter(v => v.post.length).map(d=> formLeader(d)));
          }
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
    }
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box>
      
      <Box>
        <MyConnectLayout setFlag={flag} posts={HiddenPost} snackBar={snackBar}  isHeader={true} isLeaderSpeak={false} setPostsList={setHiddenPost} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPost'} isHiddenPost={true} />
      </Box>
    </Box>
  )
}

export default HiddenPost 