import React, { useState, useEffect, useRef } from "react";
import { Typography, Box, CardHeader, Card, Avatar, IconButton } from '@mui/material';
import { Navigation, Pagination, A11y } from 'swiper';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Fab from '@mui/material/Fab';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import PlayArrowIcon from '@mui/icons-material'

function PhotoComponent({ width, height, images, bgColor }) {
  const [imagesSrc, setImageSrc] = useState(images);
  const [currentImage, setCurrentImage] = useState(0);
  const container = useRef();



  //SWIPER FOR VIDEOS
  const swiperRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    swiper.on('slideChange', () => {
      setIsPrevDisabled(swiper.isBeginning);
      setIsNextDisabled(swiper.isEnd);
    });
  }, []);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };



  // useEffect(() => {
  //     setDisablePreviousButton(currentImage == 0 ? true : false);
  //     setDisableNextButton(currentImage == (images.length - 1) ? true : false);
  //     setCurrentImageSrc(imagesSrc[currentImage].url)
  // }, [currentImage])

  // useEffect(() => {
  //     setDisablePreviousButton(currentImage == 0 ? true : false);
  //     setDisableNextButton(currentImage == (images.length - 1) ? true : false);
  //     console.log(container.current.offsetWidth,container.current.offsetHeight)
  //     setContainerWidth(container.current.offsetWidth);
  //     setContainerHeight(container.current.offsetHeight);
  // }, [])

  const previousImage = () => {
    if (currentImage != 0) {
      setCurrentImage(currentImage - 1);
    }
  }

  const nextImage = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  }

  return (
    <Swiper
      ref={swiperRef}
      modules={[Navigation, Pagination, A11y]}

      breakpoints={{
        599: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        899: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1199: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1399: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1599: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      }}
      navigation
      onSlideChange={() => console.log('slide change')}
    >
      {
        images.map((path, index) => {
          return (
            <SwiperSlide key={index}>
              <Box style={{ width: '100%' }}>
                <img src={path.url} style={{ maxWidth: width, maxHeight: height }} />
              </Box>
            </SwiperSlide>
          )
        })
      }
    </Swiper>
  );
}

export default PhotoComponent;
