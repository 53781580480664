import React, { useState, useEffect, useRef } from 'react';

function Textarea(field) {
    const [textField, setField] = useState(field.value);
    const [height, setHeight] = useState(30);
    const area = useRef();

    useEffect(() => {
        field.setTextField(textField)
    }, [textField])


  return (
    <textarea
        ref={area}
        value={textField} 
        onChange={(event) => {
            setField(event.target.value);
            // textarea.style.height = 'auto';
            setHeight(area.current.scrollHeight);
        }}
        placeholder={field.label}
        style={{...field.sx, height: height + 'px'}}
    />
  );
}

export default Textarea;
