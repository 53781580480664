import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

function SinglePostSkeleton() {
    return (
        <Box>
            <List>
               
                <Grid container spacing={2}>
                    <Grid item  xs={12} sm={8} sx={{}}>
                    <Card sx={{mb:2}}>
                    <CardHeader
                        avatar={<Skeleton animation="wave" variant="circular" width={60} height={60} />}
                        title={
                            <Skeleton
                                animation="wave"
                                height={15}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                
                        }
                        subheader={
                            <Skeleton animation="wave" height={15} width="40%" />
                        }
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                    <CardContent>
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    </CardContent>
                    </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <Card>
                       <CardHeader
                        avatar={<Skeleton animation="wave" variant="circular" width={60} height={60} />}
                        title={
                            <Skeleton
                                animation="wave"
                                height={15}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                
                        }
                        subheader={
                            <Skeleton animation="wave" height={15} width="40%" />
                        }
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                    </Card>
                    </Grid>
                </Grid>
            </List>
        </Box>
    );

}

export default SinglePostSkeleton;

