import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

function SkeletonCard() {
    return (
        <Box>
            <List>
               
                    <Grid container spacing={2}>
                    {[...Array(6)].map((_, index) => (
                   <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                    <Card sx={{mb:1}}>
                    <CardHeader
                        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                        title={
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                
                        }
                        subheader={
                            <Skeleton animation="wave" height={10} width="40%" />
                        }
                    />
                    <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                    <CardContent>
                        <React.Fragment>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </React.Fragment>
                    </CardContent>
                </Card>
                </Grid>
                ))}
                </Grid>
            </List>
        </Box>
    );

}

export default SkeletonCard;

