import React from 'react';
import { HashRouter as Router} from "react-router-dom";
import { history } from "./_helper";
import RouteComponet from './RouteComponent';
// import GlobalComponent from './GlobalComponent.js';



function TeamAppComponent(props) {
    
    return (
        <div className="" id="back-to-top-anchor">
            {/* <GlobalComponent/> */}
            <Router history={history}>
             
                <RouteComponet teams={props.teams} />
            </Router>
        </div>
    );
}

export default TeamAppComponent;