import React from "react";
import Feeds from "../myConnectPages/Feeds";
import Sidebar from "../layouts/sidebar/Sidebar";
import Footer from "../layouts/footer/Footer";
import ThemeProvider from "../theme";
import LeaderSpeak from "../myConnectPages/LeaderSpeak";
import CompanyWithHeart from "../myConnectPages/CompanyWithAHeart";
import Events from "../myConnectPages/Events";
import More from "../myConnectPages/More";
import JobDescription from "../component/JobReferral/JobDescription";
import JobReferral from "../myConnectPages/JobReferral";
import SinglePost from "../component/FeedPost.js/SinglePost";
import Awards from "../myConnectPages/Awards";
import Birthday from "../myConnectPages/Birthday";
import Weddings from "../myConnectPages/Weddings";
import Announcements from "../myConnectPages/Announcement";
import NewJoiner from "../myConnectPages/NewJoiner";
import Promotions from "../myConnectPages/Promotions";
import Accolades from "../myConnectPages/Accolades";
import BirthAnnouncement from "../myConnectPages/BirthAnnouncement";
import WorkAniversary from "../myConnectPages/WorkAniversary"
import Stories from "../myConnectPages/Stories";
import AssociateSpotlight from "../myConnectPages/AssociateSpotlight";
import ImageSlider from "../myConnectPages/ImageSlider";
import FindAssociates from "../component/MoreComponent/FindAssociates";
import OrganizationTree from "../component/OrganizationTree/OrganizationTree";
import SupportDirectory from "../component/MoreComponent/SupportDirectory";
import InternalJobs from "../component/MoreComponent/InternalJobs/InternalJobs";
import ImportantLinks from "../component/MoreComponent/ImportantLinks/ImportantLinks";
import MyFavorite from "../myConnectPages/MyFavorite";
import UserviewProfile from "../component/MoreComponent/UserviewProfile";
// import Breadcrumbs from "../features/breadcrumbs/Breadcrumbs";
import Breadcrumbs from "../component/Breadcrumbs";
// import DashboardTrash from "../pages/KnowledgeManagement/DashboardTrash";
// import TagAttachedFolder from "../pages/KnowledgeManagement/TagAttachedFolder";
const windowObject = window;

export function PrivateComponent() {
  <Breadcrumbs />
  let response = [
    {
      url: "/",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Feeds />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/leader-speak",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <LeaderSpeak />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/company-with-heart",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <CompanyWithHeart  />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/events",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Events  />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/birthday",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Birthday  />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/announcements",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Announcements />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/new-joiners",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <NewJoiner />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/promotions",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Promotions />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/accolades",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Accolades />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/birth-announcements",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <BirthAnnouncement />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/anniversaries",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <WorkAniversary />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/associate-spotlight",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <AssociateSpotlight />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/image-slider",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <ImageSlider />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/stories",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Stories />
            </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
    url: "/awards",
    page: (
      <ThemeProvider>
        <Sidebar window={windowObject}>
          <Awards />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/more",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <More />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/job-description/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <JobDescription />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/job-referral",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <JobReferral />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/organization-tree",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <OrganizationTree />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/weddings",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <Weddings />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/contact-directory",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <SupportDirectory />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/find-associates",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <FindAssociates />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/feed/:id",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <SinglePost />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/internal-jobs",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <InternalJobs />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: "/important-links",
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <ImportantLinks />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: 'my-favorite',
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <MyFavorite />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
    {
      url: 'user-view-profile/:id',
      page: (
        <ThemeProvider>
          <Sidebar window={windowObject}>
            <UserviewProfile />
          </Sidebar>{" "}
        </ThemeProvider>
      ),
    },
  ];
  return response;
}
