import {authentication} from '../config/AppConstants';
import {user} from '../config/AppConstants';

export function getSelfToken(){
    var obj;
    var key=user.selfToken;
    obj=(localStorage.getItem(user.user));
    if(obj!=null){
        obj=JSON.parse(obj);
        return obj[key];
    }else{
        return obj;
    }
}
export function setSelfToken(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        obj=JSON.parse(obj);
    }else{
        obj={};
    }
    // var obj={};
    var key=user.selfToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}
export  function getSsoToken(){
    var obj;
    var key=user.ssoToken;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    return obj[key];
}

export function getTempSsoToken() {
    var obj;
    var key = user.tempSsoToken;
    obj = (localStorage.getItem(user.tempUser));
    if (obj != null) {
        obj = JSON.parse(obj);
        return obj[key];
    } else {
        return obj;
    }

}

export function setSsoToken(token){
    var obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    var key=user.ssoToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
    
}

export function setTempSsoToken(token) {
    var obj = (localStorage.getItem(user.tempUser));
    if (obj) {
        obj = JSON.parse(obj);
    } else {
        obj = {};
    }
    var key = user.tempSsoToken;
    obj[key] = token;
    localStorage.setItem(user.tempUser, JSON.stringify(obj));
}

export function getAppToken(){
    var obj;
    var key=user.appToken;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    return obj[key];
}
export function setAppToken(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        obj=JSON.parse(obj);
    }else{
        obj={};
    }
    var key=user.appToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));

}
export function setUserAuthType(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        // obj=(localStorage.getItem(user.user));
        obj=JSON.parse(obj);
    }else{
        obj={};
    }

    // obj=JSON.parse(obj);
    var key=user.authType;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}
export function getUserAuthType(){
    var obj;
    var key=user.authType;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    if(obj)
        return obj[key];
    else
        return false;
}
export function isSsoLogin(){
    if(getSelfToken() && getSsoToken() && getAppToken)
        return true;
    else
        return false;    
}
export function isSelfLogin(){
    if(getSelfToken() && getAppToken())
        return true;
    else
        return false;    
}
export function isLogin(){
    switch(getUserAuthType()){
        case authentication.ssoAuthType:
            return isSsoLogin();
        case authentication.selfAuthType:
            return isSelfLogin();
        default:
            return false;
    }
}
export function removeUserToken(){
    console.log("remove auth called");
    localStorage.removeItem(user.user);
}
export function checkCallbackAuthType(authType){
    console.log("remove auth called");
    if(authType==authentication.ssoAuthType){
        return authentication.ssoAuthType;
    }else{
        return authentication.selfAuthType;
    }
}
export function setTokenForSelfAuth(token,appToken,authType){
    setUserAuthType(authType)
    setSelfToken(token) 
    setAppToken(appToken) 
}
export function checkCallback(token,appToken,authType,callback,ssoToken){
    if(checkCallbackAuthType(authType)==authentication.ssoAuthType){
        if(token && appToken && authType && ssoToken){
            setTokenForSelfAuth(token,appToken,authType) 
            setSsoToken(ssoToken) ;
            window.location.assign(callback);
            return true;
        }else{
            return isLogin()
        }
    }else{
        if(token && appToken && authType){
            setTokenForSelfAuth(token,appToken,authType) 
            window.location.assign(callback);
            return true;
        }else{
            return isLogin()
        }
    
    }
}


// import {authentication} from '../../config/auth/AppConstants';
// import {user} from '../../config/auth/AppConstants';


// export function getSelfToken(){
//     var obj;
//     var key=user.selfToken;
//     obj=(localStorage.getItem(user.user));
//     if(obj!=null){
//         obj=JSON.parse(obj);
//         return obj[key];
//     }else{
//         return obj;
//     }
// }

// export function setSelfToken(token){
//     var obj=localStorage.getItem(user.user);
//     if(obj){
//         obj=JSON.parse(obj);
//     }else{
//         obj={};
//     }
//     // console.debug(obj,"obj self token")
//     // var obj={};
//     var key=user.selfToken;
//     obj[key]=token;
//     localStorage.setItem(user.user, JSON.stringify(obj));
// }

// export  function getSsoToken(){
//     var obj;
//     var key=user.ssoToken;
//     obj=(localStorage.getItem(user.user));
//     obj=JSON.parse(obj);
//     return obj[key];
// }

// export function setSsoToken(token){
//     var obj=(localStorage.getItem(user.user));
//     obj=JSON.parse(obj);
//     var key=user.ssoToken;
//     obj[key]=token;
//     localStorage.setItem(user.user, JSON.stringify(obj));
// }

// export function getAppToken(){
//     var obj;
//     var key=user.appToken;
//     obj=(localStorage.getItem(user.user));
//     obj=JSON.parse(obj);
//     return obj[key];
// }
// export function setAppToken(token){
//     var obj=localStorage.getItem(user.user);
//     if(obj){
//         obj=JSON.parse(obj);
//     }else{
//         obj={};
//     }
//     var key=user.appToken;
//     obj[key]=token;
//     localStorage.setItem(user.user, JSON.stringify(obj));

// }

// export function setUserAuthType(token){
//     var obj=localStorage.getItem(user.user);
//     if(obj){
//         // obj=(localStorage.getItem(user.user));
//         obj=JSON.parse(obj);
//     }else{
//         obj={};
//     }
//     // console.debug(obj,"obj user type")



//     // var obj=(localStorage.getItem(user.user));
//     // obj=JSON.parse(obj);
//     var key=user.authType;
//     obj[key]=token;
//     localStorage.setItem(user.user, JSON.stringify(obj));
// }

// export function getUserAuthType(){
//     var obj;
//     var key=user.authType;
//     obj=(localStorage.getItem(user.user));
//     obj=JSON.parse(obj);
//     if(obj)
//         return obj[key];
//     else
//         return false;
// }

// export function isSsoLogin(){
//     if(getSelfToken() && getSsoToken() && getAppToken)
//         return true;
//     else
//         return false;    
// }

// export function isSelfLogin(){
//     if(getSelfToken() && getAppToken())
//         return true;
//     else
//         return false;    
// }

// export function isLogin(){
//     switch(getUserAuthType()){
//         case authentication.ssoAuthType:
//             return isSsoLogin();
//         case authentication.selfAuthType:
//             return isSelfLogin();
//         default:
//             return false;
//     }
// }

// export function removeUserToken(){
//     // console.log("remove auth called");
//     localStorage.removeItem(user.user);
// }

// // export function checkCallback(token,appToken,authType,callback){
// //     console.debug("check start")
// //     if(token && appToken && authType){
// //         setUserAuthType(authType)
// //         setSelfToken(token) 
// //         setAppToken(appToken) 
// //         // console.debug(window.location.href,"window url")
// //         // console.debug(callback,"window callback")
// //         window.location.assign(callback)
// //         return true;
// //     }else{
// //         // console.debug("checkCallback else block")
// //         // console.debug(isLogin(),"is login called in check")
// //         return isLogin()
// //     }

// //     console.debug("check end")
// // }
// export function checkCallbackAuthType(authType){ 
//     // console.log("remove auth called");
//      if(authType==authentication.ssoAuthType){ 
//         return authentication.ssoAuthType; 
//     }else {
//          return authentication.selfAuthType; 
//         }
//     }

//     export function setTokenForSelfAuth(token,appToken,authType){
//         setUserAuthType(authType)
//         setSelfToken(token)
//         setAppToken(appToken)
//         }

// export function checkCallback(token,appToken,authType,callback,ssoToken){
//     if(checkCallbackAuthType(authType)==authentication.ssoAuthType){
//     if(token && appToken && authType && ssoToken){
//     setTokenForSelfAuth(token,appToken,authType)
//     setSsoToken(ssoToken) ;
//     window.location.assign(callback);
//     return true;
//     }else{
//     return isLogin()
//     }
//     }else{
//     if(token && appToken && authType){
//     setTokenForSelfAuth(token,appToken,authType)
//     window.location.assign(callback);
//     return true;
//     }else{
//     // console.debug("checkCallback else block")
//     // console.debug(isLogin(),"is login called in check")
//     return isLogin()
//     }
//     }
//     console.debug("check end")
//     }

