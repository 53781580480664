import React, { useState, useEffect } from 'react'
import { Card, Typography, GroupAdd, Grid, Box, styled, FindInPage, AccountTree, FolderShared, SwitchAccount, DatasetLinked } from '../utils/AllImportsHelper'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import FlareIcon from '@mui/icons-material/Flare';
import VibrationIcon from '@mui/icons-material/Vibration';
import CampaignIcon from '@mui/icons-material/Campaign';
import PeopleIcon from '@mui/icons-material/People';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CakeIcon from '@mui/icons-material/Cake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import WcIcon from '@mui/icons-material/Wc';
// import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import { setCurrentRoute } from '../features/sidebarRouting/sidebarSelectionSlice';

// import Typography from '../theme/overrides/Typography'
// import { useMenuContext } from '../utils/MenuContext';

const MUICard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  border: '1px solid #BCD3F3',
  background: '#F8FAFF',
  cursor: 'pointer',
  ':hover': {
    background:'#DBEAFE'
  },
}));

const More = () => {
  const menus = useSelector((state) => state?.menu?.menuSetting)
  const dispatch = useDispatch();

  const topLevelMenus = menus.filter((menu) => menu.parentMenu === "0" && menu.subMenu.length > 0);
  console.log("a2c",topLevelMenus)
  topLevelMenus.sort((a, b) => b.sorting - a.sorting);
  function slugify(text) {
    return text
      .toLowerCase()
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '') // Remove non-word characters
      .replace(/--+/g, '-'); // Replace consecutive hyphens with a single hyphen
  }

  const menuNameToIcon = {
    'Job Referral': GroupAdd,
    'Find Associates': FindInPageIcon,
    'Organization Tree': AccountTreeIcon,
    'Support Directory': FolderSharedIcon,
    'Internal Jobs': ContactPageIcon,
    'Important Links': DatasetLinkedIcon,
    'Associate Spotlight': FlareIcon,
    'Stories': VibrationIcon,
    'Announcements': CampaignIcon,
    'PeopleIcon': PeopleIcon,
    'Awards': EmojiEventsIcon,
    'Awards(Quarterly and Spot)': EmojiEventsIcon,
    'Accolades': RecordVoiceOverIcon,
    'Promotions': FolderSharedIcon,
    'Birth Announcements': CakeIcon,
    'Birthday': CelebrationIcon,
    'Work Aniversary': WorkIcon,
    'Stories': VibrationIcon,
    'Announcement': CampaignIcon,
    'Work Anniversary': WorkIcon,
    'Anniversaries': WorkIcon,
    'Weddings': WcIcon,
    'New Joiners': GroupIcon,



  };


  return (
    <>
    {console.debug("toplevel menu", menus)}
     <Typography variant="h6" mb={1}>More</Typography>
    <Card sx={{ minHeight: 'calc(95vh - 138px)' }}>
          {topLevelMenus.map((menu) => (
            <Box key={menu.id}>
              <Typography variant="subtitle1" mb={1}>
                {menu.menuName}
              </Typography>
              <Box sx={{ flexGrow: 1,mb:2 }}>
                <Grid container spacing={2}>
                  {menu.subMenu.map((subMenu) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={subMenu.id}>
                      <Link to={`/${slugify(subMenu.identifier)}`} style={{ textDecoration: 'none' }} onClick={() => {
                        dispatch(setCurrentRoute(subMenu.menuName));
                      }}>
                        <MUICard sx={{height:'120px'}}>
                          {menuNameToIcon[subMenu?.menuName] && React.createElement(menuNameToIcon[subMenu.menuName], { color: 'primary', sx: { fontSize: '45px' } })}
                          <Typography variant="body2" >{subMenu.menuName}</Typography>
                        </MUICard>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          ))}
    </Card>
    </>
  )
}

export default More