// import React, { useState, useEffect } from 'react';
// import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// const SelectBox = (field) => {
// const [textField, setField] = useState(field.value);

//     useEffect(() => {
//         field.setTextField(textField)
//     }, [textField])

//   return (
//     <FormControl>
//      <Select
//         label={field.label} 
//         id={field.id}
//         value={textField}
//         onChange={(event) => {console.log(event.target.value); setField(event.target.value)}}
//       >
//         {field.selectUser.map((user, key) => {
//             return (<MenuItem key={key} value={user.value}>{user.firstName}</MenuItem>)
//         })}
//       </Select>
//     </FormControl>
//   );
// };

// export default SelectBox;

// import React, { useState, useEffect } from 'react';
// import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
// import ApiService from '../../utils/ApiService';

// const SelectBox = (field) => {
//   const [textField, setField] = useState(field.value);
//   const [options, setOptions] = useState([]);

//   useEffect(() => {
//     field.setTextField(textField);
//   }, [textField, field]);

//   useEffect(() => {
//     // Fetch options from the API when the component mounts
//     ApiService.get('orgDepartmentUser').then((selectUser) => {
//       setOptions(selectUser.data.data);
//     });
//   }, []); // Empty dependency array to run the effect only once on mount

//   const handleChange = (event) => {
//     const selectedValue = event.target.value;
//     setField(selectedValue);

   

//     // Call the provided setTextField function
//     if (field.setTextField) {
//       field.setTextField(selectedValue);
//     }
//   };

//   return (
//     <FormControl>
//       <InputLabel id={`${field.label}Label`}>{field.label}</InputLabel>
//       <Select
//         labelId={`${field.label}Label`}
//         id={field.id}
//         value={textField}
//        onChange={handleChange}
//       >
//        {options.map((user, key) => (
//           <MenuItem key={key} value={user.value}>
//             {user.firstName}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default SelectBox;


import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import ApiService from '../../utils/ApiService';

const SelectBox = (field) => {
  const [textField, setField] = useState(field.value);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const loggedInUserId = useSelector((state) => state?.user?.userListName.userId);

  useEffect(() => {
    field.setTextField(textField);
  }, [textField, field]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await ApiService.get('orgDepartmentUser');
        const filteredOptions = response.data.data.filter(user => user.userId !== loggedInUserId);
        setOptions(filteredOptions);
      } catch (error) {
        console.error('Error fetching options:', error);
        // Handle error (e.g., show an error message)
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [loggedInUserId]); // Empty dependency array to run the effect only once on mount

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setField(selectedValue);

    // Call the provided setTextField function if it exists
    if (field.setTextField) {
      field.setTextField(selectedValue);
    }
  };

  return (
    <FormControl>
      <InputLabel id={`${field.label}Label`}>{field.label}</InputLabel>
      <Select
        labelId={`${field.label}Label`}
        id={field.id}
        value={textField}
        onChange={handleChange}
        label={field.label}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />
          </MenuItem>
        ) : (
          options.map((user, key) => (
            <MenuItem key={key} value={user.userName}>
              {user.userName} 
              {user?.designation?.designation &&
              <> ({user?.designation?.designation})</>
}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

export default SelectBox;
