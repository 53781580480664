import { createSlice } from "@reduxjs/toolkit";

export const permissionData = createSlice({
    name: "permission",
    initialState: {
        permissionList: [],
    },
    reducers: {
        permissionDataList: (state, action) => {
            console.log('permissionData redux', action.payload);
            state.permissionList = action.payload;
        },

    }
});

export const { permissionDataList } = permissionData.actions;

export default permissionData.reducer;
