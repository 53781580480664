import React, { useEffect, useState,Suspense } from 'react';
import { Box } from '@mui/material';
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';

const Feeds = () => {
  const dispatch = useDispatch();
  const [posts, setPostsList] = React.useState([]);
  const [Announcements, setAnnouncementsList] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const userData = useSelector((state) => state?.user?.userListName)

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showPostData = useSelector((state) => state.formData.formToSend);
  const showSetPostData = useSelector((state) => state.formData.formToUpdate);

  const indivisualAPI = 'FeedList';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)
  

  useEffect(() => {
    if (showPostData) {
      console.log('abc', showPostData)
      let allPosts = [...posts];
      allPosts.unshift(showPostData);
      // setPostsList(allPosts)
      const formLeader = transformationHelper().getTransformationFunction('FeedList');
      console.log({formLeader})
      setPostsList(allPosts.map(d=> formLeader(d)));
      // const formLeader = transformationHelper().getTransformationFunction('FeedList')
      // setPostsList(allPosts.map(d=> formLeader(d)));

    }
  }, [showPostData])

  useEffect(() => {
    if (showSetPostData) {
      let allPosts = [...posts];
      let indexPost = allPosts.reduce((prev, curr, index) => {
        if (curr.id == showSetPostData.id)
          prev = index;
        return prev;
      }, -1);
      let post = allPosts[indexPost];
      post = { ...post, ...showSetPostData.formToSend }
      allPosts.splice(indexPost, 1, post);
      // setPostsList(allPosts)
      const formLeader = transformationHelper().getTransformationFunction('FeedList');
      console.log({formLeader})
      setPostsList(allPosts.map(d=> formLeader(d)));
      // console.log("showSetPostData", { showSetPostData })
    }
  }, [showSetPostData])

  useEffect(() => {
    if (userData?.userId != undefined) {
      ApiService.get(indivisualAPI, { userId: userData?.userId })
      .then((res) => {
        let data = res.data.data;
        if (data) {
          const formLeader = transformationHelper().getTransformationFunction('FeedList');
          console.log({formLeader})
          setPostsList(data.map(d=> formLeader(d)));
          // setPostsList(data);
        }
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });

    ApiService.get('FeedAnnouncements')
      .then((res) => {
        let data = res.data.data;
        if (data) {
          const formLeader = transformationHelper().getTransformationFunction('FeedAnnouncements')
          setAnnouncementsList(data.map(d=> formLeader(d)));
        }
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  }}, [userData]);
  // const MyConnectLayout = React.lazy(() => import('../component/CommonPage/MyConnectLayout'));

  return (
    <Box>
      {
        posts.length ?
        // <Suspense fallback={<div>Loading...</div>}>
          <MyConnectLayout posts={posts} announcements={Announcements} snackBar={snackBar} isFeed={true} isHeader={true} setPostsList={setPostsList} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPost'} />
          // </Suspense>
          : <PostSkeleton />
      }
    </Box>
  );
};

export default Feeds;
