import { createSlice } from '@reduxjs/toolkit';

const confirmDialogBoxSlice = createSlice({
  name: 'confirmDialog',
  initialState: {
    isOpen: false,
    id: ''
  },
  reducers: {
    openConfirmDialog: (state, actions) => {
      //console.log("hchedgfdcn",{state})
      state.isOpen = true;
      state.id = actions.payload;
      // //console.log("Sar fat jayega",actions)
    },
    closeConfirmDialog: (state, actions) => {  
      state.isOpen = false;
      state.id = actions.payload;
      // //console.log(state,state.isOpen,'closeConfirmDialog')
    },
  },
});

export const { openConfirmDialog, closeConfirmDialog } = confirmDialogBoxSlice.actions;

export default confirmDialogBoxSlice.reducer;
