import React, { useState, useEffect } from 'react'
import { Card, Typography,Close,Avatar,HighlightOff, Grid,Stack, Pagination,FilterList, Box, styled, LocationOnOutlined, 
    ArrowForwardOutlined, Button, Chip, MoreVert, IconButton, CurrencyRupee, ChatBubbleOutlineOutlined,
    CardHeader, CardContent, CardActions,
     Chat, Divider, Tooltip } from '../../utils/AllImportsHelper'
import useMediaQuery from '@mui/material/useMediaQuery';
import ApiService from '../../utils/ApiService';
import Profile from '../../assets/images/Avtaar.png'
import SharedDrawer from '../../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import userImage from '../../assets/images/userImage.png'
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import SupportDirectoryDrawer from '../drawerBody/supportDirectory/SupportDirectoryDrawer';
import SkeletonCard from './SkeletonCard';
import BreadcrumbsComponent from '../Breadcrumbs';
import AvatarTingg from "../../assets/images/AvatarTingg.png"

const MUICard = styled(Card)(({ theme }) => ({
    padding: 0,
    border: '1px solid #E3E3E3',
    ':hover': {
      background:'#DBEAFE'
    },
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
    //   height:'250px'

}));

const SupportDirectory = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const longName = "Gautama Tiwari";
    const [directory, setDirectory] = useState([]);
    const [meta, setMeta] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  const [filters, setFilters] = useState({
          departmentData: '',
          branchChange: '',
    });
    const dispatch = useDispatch();
  const supportDirectoryDrawer = (id) => {
    dispatch(openDrawer(id));
  }

  const [filtersCleared, setFiltersCleared] = useState(false);
const handleClearSearch = () => {
  if (currentPage !== 1) {
    setCurrentPage(1);
    setFiltersCleared(true);
  } else {
    setFilters({
      departmentData: '',
      branchChange: '',
      });
    setFiltersCleared(false);
  }
};

//       const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const displayedData = data.slice(startIndex, endIndex);

useEffect(() => {
    if (!filtersCleared) {
      fetchData();
    } else {
      setFiltersCleared(false);
    }
  }, [currentPage, filters]);

  const fetchData = () => {
    const apiParams = {};
    if (filters.departmentData) {
        // If both deptData and abcChange are selected, exclude limit and page params
        apiParams.departmentCode = filters.departmentData;
    }
    if(filters.branchChange) {
        apiParams.branchCode = filters.branchChange;
    }
    //   else {
        // If either deptData or abcChange is not selected, include limit and page params
        apiParams.limit = itemsPerPage;
        apiParams.page = currentPage;
        // apiParams.platformId = ['Referral']
    //   }
    ApiService.get('contactDirectory', 
    // { limit: itemsPerPage,page:currentPage,departmentCode: filters.departmentData, // Replace with your actual API filter parameter names
    // branchCode: filters.branchChange, }
    apiParams).then(directory => {
        setDirectory(directory.data.data)
        setMeta(directory.data.meta)
              console.log(directory.data.data.user)
          })
    }


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
      };
  
      const updateFilters = (selectedFilters) => {
        setFilters(selectedFilters);
        // Optionally, you can also reset the current page to 1 when filters change
        setCurrentPage(1);
      };
    // useEffect(() => {
    // ApiService.get('myReferral', { requestUserId: 144,
    //         applicantRelated: ["job", "applicantReferralStatus", "status"] }).then(myReferral => {
    //         setMyReferral(myReferral.data.data)
    //          // console.log(myReferral.data.data)
    //       })
    // }, []);
    const textStyle = {
        whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
        overflow: 'hidden',    // Hide any overflow text
        textOverflow: 'ellipsis', // Display ellipsis when text overflows
    };
    return (
        <>
            {/* <Typography variant="subtitle1" fontWeight={500} mb={1}>Supervisors</Typography> */}
            <Typography pb={1} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Support Directory</Typography>
            <Card sx={{minHeight: 'calc(95vh - 138px)'}}>
            <Stack
        direction="row"
        justifyContent="space-between"

      >
         {/* <Button variant="outlined" color='secondary' endIcon={<Close />} onClick={handleClearSearch}>
              Clear Serach
            </Button> */}
         <Box display='flex' alignItems='center'>
        
        {(
          filters.branchChange || 
          filters.departmentData
          ) ? (
            <>
              <Typography variant="subtitle1" pr={2}>{meta.total} results found</Typography>
<Button color='secondary' startIcon={<HighlightOff />} onClick={handleClearSearch}>
                  CLEAR
                </Button>
  </>
  ) : (
<Typography variant="subtitle1" pr={2} >{meta?.total} Supervisors</Typography>
)}
</Box>
        <IconButton aria-label="delete" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: .5 }} onClick={() => supportDirectoryDrawer('supportDirectory')}>
          <FilterList fontSize="medium" />
        </IconButton>
        <SharedDrawer id='supportDirectory'>
                      {/* <Box sx={{ position: 'relative', minWidth: '25em', height: '100vh' }}> */}
                        <SupportDirectoryDrawer onUpdateFilters={updateFilters} filters={filters} />
                      {/* </Box> */}
                    </SharedDrawer>
      </Stack>
      {(directory.length <= 0) && (meta.total != 0) ? <SkeletonCard /> :
          <>
            <Box sx={{ flexGrow: 1, pt: 1 }}>
                <Grid container spacing={2}>
                {directory.map((contact, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>

                        <MUICard>
                            {/* <Grid container spacing={2} p={2} alignItems="center">
                           <Grid item>
                                <Avatar sx={{ width: 45, height: 45 }}> <img src={contact?.user?.userAvatar} width='100%' /></Avatar>
                            </Grid>    
                                <Grid item xs={9.5} sm={9.5}>
                               <Typography variant="body2" color={'rgba(0, 0, 0, 0.5)'}>{contact.category}</Typography>
                                <Tooltip title={contact.user?.userName + ' ' + contact.user?.lastName}>
                                    <Typography variant="body1" style={textStyle} fontWeight={500} >{contact.user?.userName} {contact.user?.lastName}</Typography>
                                    </Tooltip>
                                    <Typography variant="body2" color={'rgba(0, 0, 0, 0.5)'}>{contact.user?.designation?.designation}</Typography>
                                    </Grid>
                                </Grid> */}

<CardHeader 
                        avatar={
                      //     <Avatar sx={{ width: 45, height: 45 }}>
                      //       <img src={contact?.user?.userAvatar === (contact?.user?.userAvatar === 'https://gatewayapi.eela.tech/media/?file=default.png' || contact?.user?.userAvatar === 'https://gatewayapi.eela.tech/media/')
                      // ? userImage
                      // : contact?.user?.userAvatar} width="100%" />
                      //     </Avatar>
                      <Avatar
                      sx={{ width: 45, height: 45 }}
                      src={
                        contact?.user?.userAvatar === 'https://gatewayapi.eela.tech/media/?file=default.png' ||
                        contact?.user?.userAvatar === 'https://gatewayapi.eela.tech/media/'
                          ? AvatarTingg
                          : contact?.user?.userAvatar
                      }
                      // width="100%"
                    />
                        }
                        title={
                            <Box>
                              {/* {contact?.category ? <Typography sx={{fontSize:'12px',fontWeight:500}} color='textSecondary'>{contact.category}</Typography> : <Typography>Aman</Typography> } */}
                              {(contact?.category != '' && contact?.category != null) ? (<Typography sx={{ fontSize: '12px', fontWeight: 500 }} color='textSecondary'>{contact.category}</Typography>) : (<Typography>---</Typography>)}
                             { (contact.user?.userName || contact.user?.lastName != '' && contact.user?.userName || contact.user?.lastName != '')  ?
                          <Tooltip title={contact.user?.userName + ' ' + contact.user?.lastName}>
                            <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >
                            {contact.user?.userName} {contact.user?.lastName}
                            </Typography>
                          </Tooltip> : <Typography>---</Typography>}
                          </Box>
                        }
                        subheader={
                        //   <Tooltip title={associates?.designation?.designation}>
                        <>
                          {(contact.user?.designation?.designation != null && contact.user.designation.designation !== '') ?
                            <Typography variant="subtitle2" className='overflow-text-Announcement' color='secondary.textSecondary' >
                            {contact.user?.designation?.designation}
                            </Typography> : <Typography>---</Typography>}
                            </>
                        //   </Tooltip>

                        }
                    
                      />
                      
                      <CardContent sx={{ pt: '0px' }}>
                                 <Grid container spacing={1}>
                                <Grid item xs={4.5} sm={4} color={'#475569'}>
                                    <Typography variant="body2" >Department:</Typography>
                                </Grid>

                                <Grid item xs={7.5} sm={8}>
                                    <Typography variant="body2">{contact.user?.department?.department ?? '---'}</Typography>
                                </Grid>
                                <Grid item xs={4.5} sm={4} color={'#475569'}>
                                    <Typography variant="body2">Contact:</Typography>
                                </Grid>
                                <Grid item xs={7.5} sm={8}>
                                    <Typography variant="body2">{contact.user?.contactNo ?? '---'}</Typography>
                                </Grid>
                                <Grid item xs={4.5} sm={4} color={'#475569'}>
                                    <Typography variant="body2">Email:</Typography>

                                </Grid>
                                <Grid item xs={7.5} sm={8}>
                                <Tooltip title={contact.user?.email}>
                                    <Typography variant="body2" style={textStyle}>{contact.user?.email ?? '---'}</Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                                 </CardContent>
                            <Divider variant="fullWidth" />

                            <CardActions sx={{ marginTop: 'auto' }}>
                            <Grid container spacing={1} justifyContent="space-between" direction="row">
                                 <Grid item>
                                    {contact.user?.branch?.branchName &&
                                    <Typography variant="caption" fontWeight={500} mt={.5} color="secondary.textSecondary" display="block">
      <LocationOnOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px',marginBottom: 3 }} />
      {contact.user?.branch?.branchName}
    </Typography>
}
                                </Grid>
                                <Grid item>
                                <a href={'mailto:' + (contact ? (contact.user ? contact.user.email : "---") : "---")}>
                                    <Button size="small" endIcon={<ArrowForwardOutlined />}>
                                        SEND EMAIL
                                    </Button>
                                    </a>
                                </Grid>
                            </Grid>
                            </CardActions>
                        </MUICard>


                    </Grid>
                    ))} 
                </Grid>
            </Box>
            <Stack spacing={2} justifyContent="flex-end" direction="row" pt={3}>
      <Pagination count={meta.last_page}
        page={currentPage}
        onChange={handlePageChange} />
    </Stack>
            </>
}
            
            </Card>
        </>
    )
}

export default SupportDirectory