
import { createSlice } from '@reduxjs/toolkit';



const postSlice = createSlice({
    name: 'formData',
    initialState: {
        formToSend:null,
        formToUpdate: null,
    },
    reducers: {
        setFormData: (state, action) => {
            state.formToSend = action.payload;
        },
        setFormUpdate: (state, action) => {
            console.log({state,action});
            state.formToUpdate = action.payload;
        }
    },
});

export const {setFormData,setFormUpdate} = postSlice.actions;
export default postSlice.reducer;
