import AvatarTingg from "../assets/images/AvatarTingg.png"

function transformationHelper() {
    let self = {};
  
    self.getSearchParams = (type, param) => {
        let calculatedValue;
        switch(type){
            case 'leader': calculatedValue = {
                "indivisualAPI" : 'LeaderSpeak',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Leader Speak',
                }
                break;
            case 'Award To User': calculatedValue = {
                "indivisualAPI" : 'Awards',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Awards',
                }
                break;
            case 'event': calculatedValue = {
                "indivisualAPI" : 'Events',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Events',
                }
                break;
            case 'accolade': calculatedValue = {
                "indivisualAPI" : 'Accolades',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Accolades',
                }
                break;
            case 'social': calculatedValue = {
                "indivisualAPI" : 'Social',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Company With A Heart',
                }
                break;
            case 'associate': calculatedValue = {
                "indivisualAPI" : 'AssociateSpotlight',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Associate Spotlight',
                }
                break;
            case 'recipe': calculatedValue = {
                "indivisualAPI" : 'Stories',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Stories',
                }
                break;
            case 'notice': calculatedValue = {
                "indivisualAPI" : 'FeedAnnouncements',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Announcements',
                }
                break;
            case "promotion": calculatedValue = {
                "indivisualAPI" : 'Promotions',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Promotions',
                }
                break;
            case "workAnniversary": calculatedValue = {
                "indivisualAPI" : 'WorkAniversary',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Work Anniversary',
                }
                break;
            case "child" : calculatedValue = {
                "indivisualAPI" : 'birthAnnouncement',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Birth Announcement',
                }
                break;
            case "userWedding": calculatedValue = {
                "indivisualAPI" : 'Weddings',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Weddings',
                }
                break;
            case "userJoining": calculatedValue = {
                "indivisualAPI" : 'Joiners',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'New Joiners',
                }
                break;
            case 'userBirth': calculatedValue = {
                "indivisualAPI" : 'Birthday',
                "reactPost" : 'reactPostPages',
                "feedPostName" : 'Birthday',
                }
                break;
        }
        return calculatedValue[param];
    }

    self.getTransformationFunction = (related) => {

        switch (related) {
            case 'Social': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                console.log({ avatar })
                return {
                    "id": data.socialId,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "postBody": data.description,
                    "title": data.title,
                    "userAvatar": avatar,
                    "image": data.image,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Accolades': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "postBody": data.description,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "lastName": data?.user?.lastName,
                    "promotedDate": data.promotedDate,
                    "userAvatar": avatar,
                    "dateOfBirth": data?.user?.dateOfBirth,
                    "degination": data?.user?.designation?.designation,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                    "image": null,
                }
            };
            case 'FeedAnnouncements': return (data) => {
                let avatar = data?.user?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "userAvatar": avatar,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "postBody": data.textBoard,
                    "title": data.title,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    'image': data?.noticeImage,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'AssociateSpotlight': return (data) => {
                let avatar = data?.user?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "middleName": data?.user?.middleName,
                    "lastName": data?.user?.lastName,
                    "userAvatar": avatar,
                    "postBody": data.description,
                    "title": data.title,
                    'image': data.album,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Awards': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "postBody": data.description,
                    "title": data?.award?.name,
                    "userAvatar": avatar,
                    "image": data.Image,
                    "awardName": data?.user?.userName,
                    "receivedDate": data.receivedDate,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "type": "reward",
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'birthAnnouncement': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "description": data.description,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "lastName": data?.user?.lastName,
                    "promotedDate": data.promotedDate,
                    "userAvatar": avatar,
                    "image": data?.Image,
                    "dateOfBirth": data?.dateOfBirth,
                    "degination": data?.user?.designation?.designation,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Birthday': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "title": data?.award?.name,
                    "userAvatar": avatar,
                    "image": data.Image,
                    "dateOfBirth": data?.user?.dateOfBirth,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Events': return (data) => {
                let avatar = data?.user?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "userAvatar": avatar,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "postBody": data.Description,
                    "title": data.title,
                    'image': data?.eventImage,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };

            // case 'hidePost' : return (v) => v;
            case 'FeedList': return (v) => {
                let avatar = v?.user?.userAvatar || v?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    ...v,
                    "designation": v.designation ?? v?.user?.designation?.name,
                    "userAvatar": avatar,
                }
            };
            case 'savedPosts': return (v) => {
                return {
                    ...v,
                    "designation": v.designation ?? v?.user?.designation?.designation,
                    "userAvatar": (v?.user?.userAvatar || v?.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png"
                    ? AvatarTingg
                    : v?.user?.userAvatar || v?.userAvatar),
                }
            };
            case 'favoriteFeeds': return (data) => {
                // console.log("Hit")
                // let avatar = data?.user?.userAvatar;
                // avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                // avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                // console.log({data})
                let indivisualAPI = self.getSearchParams(data.type,"indivisualAPI")
                let formLeader = self.getTransformationFunction(self.getSearchParams(data.type,"indivisualAPI"))
                console.log({transformedData: formLeader(data) })
                return {
                    ...formLeader(data),
                    "indivisualAPI": indivisualAPI,
                    "reactPost": self.getSearchParams(data.type,"reactPost"),
                    "feedPostName": self.getSearchParams(data.type,"feedPostName"),
                }
            };
            case 'HiddenPostUser': return (data) => {
                let avatar = data?.post.length ? data?.post[0]?.userAvatar : null;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                // console.log({dataHiddenPost: data})
                return {
                    "id": data.id,
                    "comments": data?.post.length ? data?.post[0]?.comments : null,
                    "created_at": data?.post.length ? data?.post[0]?.created_at : null,
                    "userName": data?.post.length ? data?.post[0]?.userName : null,
                    "userAvatar": avatar,
                    "postBody": data?.post.length ? data?.post[0]?.postBody : null,
                    "title": data.title,
                    'image': data?.post.length ? (data?.post[0]?.album?.images?.[0]?.image ?? null) : null,
                    // "album": data?.post[0]?.album ?? null,
                    "reactionCount": data?.post[0]?.reactionCount,
                    "post_reactions": data?.post[0]?.post_reactions,
                    "postId": data?.post[0]?.id,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'HiddenPost': return (data) => {
                let avatar = data?.post.length ? data?.post[0]?.userAvatar : null;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                console.log({data})
                return {
                    "id": data.id,
                    "comments": data?.post.length ? data?.post[0]?.comments : null,
                    "created_at": data?.post.length ? data?.post[0]?.created_at : null,
                    "userName": data?.post.length ? data?.post[0]?.userName : null,
                    "userAvatar": avatar,
                    "postBody": data?.post.length ? data?.post[0]?.postBody : null,
                    "title": data.title,
                    'image': data?.post.length ? data?.post[0]?.album?.images[0].image : null,
                    "reactionCount": data?.post[0]?.reactionCount,
                    "post_reactions": data?.post[0]?.post_reactions,
                    "postId": data?.post[0]?.id,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'LeaderSpeak': return (data) => {
                let avatar = data.leaderImage ? data.leaderImage : data.user.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                // console.log({ avatar })
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    // "userAvatar": data?.user?.userAvatar && data.leaderImage,
                    "userAvatar": avatar,
                    "postBody": data.description,
                    "title": data.title,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    'image': null,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Joiners': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "title": data?.award?.name,
                    "userAvatar": avatar,
                    "image": data?.user?.userAvatar,
                    "dateOfBirth": data?.user?.dateOfBirth,
                    "degination": data?.user?.designation?.designation,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Promotions': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation && null,
                    "userName": data?.user?.userName,
                    "lastName": data?.user?.lastName,
                    "promotedDate": data.promotedDate,
                    "title": data?.award?.name,
                    "userAvatar": avatar,
                    "image": data?.user?.userAvatar,
                    "dateOfBirth": data?.user?.dateOfBirth,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Stories': return (data) => {
                let avatar = data?.user?.userAvatar ? data.user.userAvatar : data.leaderImage;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.recipeId,
                    "postBody": data.description,
                    "comments": data.comment,
                    "title": data.title,
                    "userAvatar": avatar,
                    "created_at": data?.created_at ? data.created_at : data.created_At,
                    "userName": data?.user?.userName,
                    "lastName": data?.user?.lastName,
                    "promotedDate": data.promotedDate,
                    "userAvatar": data?.user?.userAvatar,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "image": data.recipeImage,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'Weddings': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "description": data.description,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "lastName": data.spouse,
                    "promotedDate": data.promotedDate,
                    "userAvatar": avatar,
                    "image": data?.weddingImage,
                    "weddingDate": data?.weddingDate,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };
            case 'WorkAniversary': return (data) => {
                let avatar = data?.comment?.userAvatar;
                avatar = (avatar === "https://gatewayapi.eela.tech/media/?file=default.png" ? AvatarTingg : avatar)
                avatar = (avatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : avatar)
                return {
                    "id": data.id,
                    "description": data.description,
                    "comments": data.comment,
                    "created_at": data.dateOfCreation,
                    "userName": data?.user?.userName,
                    "lastName": data?.user?.lastName,
                    "promotedDate": data.promotedDate,
                    "userAvatar": avatar,
                    "image": data?.Image,
                    "dateOfBirth": data?.user?.dateOfBirth,
                    "totalWorkExperience": data?.totalWorkExperience,
                    "post_reactions": data.reaction.map(v => {
                        return {
                            ...v,
                            "reactionId": parseInt(v.pivot.reaction_id)
                        }
                    }),
                    "reactionCount": data.reaction_count,
                    ...data,
                    "designation": data.designation ?? data?.user?.designation?.designation,
                }
            };

        }
    }

    return self;
}

export default transformationHelper;