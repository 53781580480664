import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function OrganizationSkeleton() {
    return (
        <Box>
            <List>
                {[...Array(6)].map((_, index) => (
                    <ListItem key={index} alignItems="center">
                        <Skeleton variant="rectangular" width={40} height={40} sx={{ marginRight: '10px' }} />
                        <ListItemText
                            primary={<Skeleton variant="text" width={150} />}
                            secondary={<Skeleton variant="text" width={200} />}
                        />

                    </ListItem>
                ))}
            </List>
        </Box>
    );

}

export default OrganizationSkeleton;