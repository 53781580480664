import React, { useState } from 'react';
import './Login.css';
// import logo from "../img/tingg-icon.png";
import logo from "../Login/images/logo.png";
import {
	 useLocation, useNavigate,
} from "react-router-dom";
// import { login } from '../auth/auth.service';
// import {
// 	setSsoToken,
// 	setSelfToken,
// 	setTempSsoToken,
// 	setAppToken,
// 	setUserAuthType
// } from '../_helper/secureToken';
// import { authentication } from '../config/AppConstants'
// import { isLogin } from '../_helper/secureToken';
// import SSODailog from '../Modal/ssoDailog';
// import { TeamsFx, IdentityType, createMicrosoftGraphClient } from "@microsoft/teamsfx";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import { checkLinked } from '../auth/ssoLinking';
import SSODailog from './Modal/SsoDailog';
// import {loginHandler, ssoLoginHandler} from "auth-component"
// import {loginHandler, ssoLoginHandler,TeamsSSOLoginHandler} from "auth-component"
import {loginHandler, ssoLoginHandler} from "auth-component"
// import { ssoTeamsAppLogin } from '../auth/ssoTeamsAppLogin.service';
import  TeamsSSOLoginHandler from '../_services/TeamsSSOLoginHandler';

// import {loginClickHandler} from "auth-component/"
// import ClientErrorDailog from '../Modal/ClientErrorDailog';




function Login(props) {

	// let email = React.createRef();
	// let password = React.createRef();

	// let history = useHistory();
	const navigate = useNavigate();
	let location = useLocation();

	let { from } = location.state || { from: { pathname: "/" } };

	const [showPassword, setShowPassword] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [open, setOpen] = useState(false);
	const [nameErr, setNameErr] = useState(false);
	const [email, setEmail] = useState('')
    const [password, setPassword] = useState("");
    const [emailErr, setEmailErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
	const [clientErrorDialogOpenStatus, setClientErrorDialogOpenStatus] = useState(false);
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);

	};

	async function loginClickHandler(e) {
		e.preventDefault();
		let postData = {};
		if (mailformat.test(email)){ 
            postData.email = email;
            setEmailErr(false);
        }else{
            setEmailErr(true);
        }
		if (password){ 
            postData.password = password;
            setPassErr(false);
        } else {
          setPassErr(true);
        }
		 if(email && password){ 
			loginHandler(postData).then(r => {
				console.debug("loginHandler:r", r)
				if (r != null && r.redirectUrl) {
					window.location.assign(r.redirectUrl)
				} else {
					window.location.assign("/")
				}
			}).catch(e => {
				// handleClickOpen()
				console.debug("ssoLoginHandler : response : ", e);
			});
		}
	}

	const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == "email") {
			if(mailformat.test(e.target.value)){ 
            setEmail(e.target.value);
			setEmailErr(false)
			}else{
			setEmail(e.target.value);
			setEmailErr(true)
			}
          }
		  if (e.target.name == "password") {
            setPassword(e.target.value);
          }
		}

	async function ssoLoginClickHandler(e) {
		e.preventDefault();
		console.debug("ssoLoginClickHandler : start");
		
		TeamsSSOLoginHandler().then(r=>{
			console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : r : ",r);
			if(r){
				// if(r.preferredUserName){
				// 	console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.preferredUserName : ",r.preferredUserName);
				// 	// handleClickOpen();
				// }
				if(r.linkStatus){
					// If User is not Link
					console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.linkStatus : ",r.linkStatus);
					// console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.preferredUserName : ",r.preferredUserName);
					handleClickOpen();
				}
				if(r.redirectUrl){
					console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.redirectUrl : ",r.redirectUrl);
					window.location.assign(r.redirectUrl); 
				}
			}
		}).catch(e=>{
			console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : e : ",e);
			// handleDialogStatus()
			//     if(!error.detail){
			//         error.detail='Something went wrong'
			//     }
			// setDailogMessage(error.detail);
		})
	}

	// async function ssoLoginClickHandlerv2(e) {
	// 	e.preventDefault();
	// 	console.debug("ssoLoginClickHandler : start");
		
	// 	TeamsSSOLoginHandlerv2().then(r=>{
	// 		console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : r : ",r);
	// 		if(r){
	// 			if(r.preferredUserName){
	// 				console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.preferredUserName : ",r.preferredUserName);
	// 				// handleClickOpen();
	// 			}
	// 			if(r.redirectUrl){
	// 				console.debug("ssoLoginClickHandler : TeamsSSOLoginHandler : If Block : r.redirectUrl : ",r.redirectUrl);
	// 				window.location.assign(r.redirectUrl); 
	// 			}
	// 		}
	// 	}).catch(e=>{
	// 		console.error("ssoLoginClickHandler : TeamsSSOLoginHandler : e : ",e);
	// 		// handleDialogStatus()
	// 		//     if(!error.detail){
	// 		//         error.detail='Something went wrong'
	// 		//     }
	// 		// setDailogMessage(error.detail);
	// 	})
	// }

	return (
		<Grid container justifyContent="center" alignItems="center" style={{ height: '100vh', background: '#E9EAED' }}>
			<Grid item xs={12} sm={8} md={6} lg={4}>
				{/* <Paper elevation={3} sx={{ padding: 3 }}> */}
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<a href="#/login"><img src={logo} alt="logo" /></a>
					{/* <LockOutlinedIcon color="primary" sx={{ fontSize: '48px' }} /> */}
					<Typography variant="h5" component="div" sx={{ mt: 1 }}>
						<h2 className="Login_card">Don't have an account? <a href="#/register" className="btn-link" >Sign up</a></h2>
					</Typography>
				</Box>
				<form onSubmit={loginClickHandler}>
					<FormControl fullWidth sx={{ mt: 2 }}>
						<TextField
							id="email"
							type="email"
							label="Email address"
							variant="outlined"
							// value={email}
							name="email"
							// inputRef={email}
							error={emailErr} 
                            helperText={emailErr ? "Please enter a valid email account":""}
							onChange={(e) =>  formInputChangeHandler(e)}
							//   onChange={(e) => setEmail(e.target.value)}
							autoFocus
							required
						/>
					</FormControl>
					<FormControl fullWidth sx={{ mt: 2 }}>
						<TextField
							id="password"
							type={showPassword ? "text" : "password"}
							label="Password"
							variant="outlined"
							// value={password}
							name="password" 
							// inputRef={password}
							error={passErr} 
                            helperText={passErr ? "Please enter password ":""}
							onChange={(e) =>  formInputChangeHandler(e)}
							//   onChange={(e) => setPassword(e.target.value)}
							required
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Button>
									</InputAdornment>
								),
							}}
						/>
					</FormControl>
					{errorMsg}
					<FormControl fullWidth sx={{ mt: 2 }}>
						<Box className="form-group text-left">
							<a className="btn-link" style={{textDecoration: 'none'}} href="#/forgot-password">
								Forgot Password?
							</a>
							<a className="btn-link text-right restButton" style={{textDecoration: 'none'}} id="show-password-ref" href="#/reset-password">
								Reset Password
							</a>
						</Box>
					</FormControl>
					<FormControlLabel
						control={<Checkbox id="defaultLoginFormRemember" />}
						label="Keep me signed in"
						sx={{ mt: 2 }}
					/>
					{/* <TextField type="checkbox" className="custom-control-TextField" id="defaultLoginFormRemember" />
					<label className="custom-control-label keenlabel" htmlFor="defaultLoginFormRemember">Keep me signed in</label> */}

					<Button type="submit" variant="contained" style={{
						background: ' #EC7C25',
						border: '1px solid #fcb116'
					}} onClick={loginClickHandler} fullWidth sx={{ mt: 3 }}>
						Login
					</Button>
					<Button type="submit" variant="contained" style={{
						background: ' #EC7C25',
						border: '1px solid #fcb116'
					}} onClick={ssoLoginClickHandler} fullWidth sx={{ mt: 3 }}>
						SSO Login
					</Button>
					{/* <Button type="submit" variant="contained" style={{
						background: ' #EC7C25',
						border: '1px solid #fcb116'
					}} onClick={ssoLoginClickHandlerv2} fullWidth sx={{ mt: 3 }}>
						SSO Login V2
					</Button> */}
					<SSODailog open={open} handleClose={handleClose} />
					{/* <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
						Don't have an account? <Link to="/register">Sign up</Link>
					</Typography>
					<Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
						<Link to="/forgot-password">Forgot password?</Link>
					</Typography> */}
				</form>
				{/* </Paper> */}
			</Grid>
		</Grid>

	);
}



export default Login;



