import { createSlice } from '@reduxjs/toolkit';

const imageSlice = createSlice({
  name: 'image',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openImage: (state, actions) => {
      //console.log("Reducer Called")
      state.isOpen = true;
    },
    closeImage: (state, actions) => {
      state.isOpen = false;
    }
  },
});

export const { openImage, closeImage } = imageSlice.actions;

export default imageSlice.reducer;
