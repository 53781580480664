import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../Login/images/logo.png";
import { Box, Button, Container, CssBaseline, Dialog, DialogTitle, Grid, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';


function RegisterView(props) {


    const [open, setOpen] = useState(false);
 
 
    const navigate = useNavigate();

    const handleDialogStatus = () => {
        setOpen(!open);
      };
      

   

 

    return (
        <Grid container component="main" sx={{ height: '100vh', background: '#E9EAED' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    mt: 10,
                    alignItems: 'center',
                }}
            >     <Box className="align-content">

                    <a href="#/login"><img width={150} height={130} src={logo} alt="logo" /></a>

                </Box>
                <Box className="align-content">
                    <Box ><Typography variant="h5" component="div" >Every interaction is an opportunity</Typography>   </Box>
                    <Box sx={{
                        mt: 0
                    }}><Typography variant="h5" component="div" >to create <strong>something remarkable.</strong></Typography> </Box>
                    <hr className="custom-register-line" />
                    <Box className="align-content">
                        <i className="">The Tingg Motto</i>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end" }} style={{ marginRight: '2rem' }}>
                    </Box>
                    <Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                    Thank you for creating account on tingg
                    </Typography>
                    <p>Almost there. Keep it going!</p>
                    <Typography component="h1" variant="h5">
                    click here to go to the login 
                    </Typography>
                    <Box sx={{ mt: 3, alignItems: 'end' }}> Go for signin <a href="#/login" style={{ color: '#007bff' }}>Sign in.</a></Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }} ><p>By creating an account, I accept Tingg’s
                    <br />

                    <Link style={{ color: '#007bff' }} to="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></Link> and <Link style={{ color: '#007bff' }} to="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></Link></p>
                </Box>
                </Box>
            </Grid>
        </Grid>


    )
}

export default RegisterView;