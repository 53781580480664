import React, { useEffect, useRef, useState } from 'react'
import EmployeeTree from './EmployeeTree';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from "react-redux";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ApiService from '../../utils/ApiService';
// import { getOrganization } from '../../_services/Organisation/organization.service';
// import { getDepartment } from '../../_services/Organisation/department.service';
// import { getDepartmentUser } from '../../_services/Organisation/departmentUser.service';
import { styled } from "@mui/material/styles";
import OrganizationSkeleton from './OrganizationSkeleton';
import BreadcrumbsComponent from '../Breadcrumbs';
// import Headline from '../InternalPage/Headline';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const StyledTab = styled(Tab)({
    "&.Mui-selected": {
        color: "rgba(0, 0, 0, 0.85);",
    },
    fontWeight: '600', fontSize: '14px', textTransform: 'capitalize'
});

function OrganizationTree() {
    // const userData = useSelector((state) => state.user.users);
    const userData = useSelector((state) => state?.user?.userListName)
    console.log(userData)
    // let getMenu = useSelector((state) => state.titleSubTitle.menu)

    const [value, setValue] = React.useState('0');
    const [currentModule, setCurrentModule] = React.useState('employeeTree');
    const [employeeTreeData, setEmployeeTreeData] = React.useState([]);
    const [departmentTreeData, setDepartmentTreeData] = React.useState([]);
    const [flagEmp, setFlagEmp] = useState(1);
    const [flagDept, setFlagDept] = useState(1);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [deptTreeData, setDeptTreeData] = useState([]);
    const [currentSelectedId, setCurrentSelectedId] = useState(false);
    const deptPrefix = 'dept';

    useEffect(() => {
        setTimeout(() => {

            org();
            // dept();
        }, 2000);
    }, []);

    {/* Org chart Api */ }
    const org = () => {
        ApiService.get('orgUsers').then(function (response) {
            setEmployeeTreeData(response.data.data);
            setFlagEmp(response.data.data.length)
            dept()
        }).catch(function (error) {
            setEmployeeTreeData([]);
            //console.log(error);
            //console.log(JSON.stringify(error));
        });
    }

    {/* Department Api */ }
    const dept = () => {
        ApiService.get('orgDepartment').then(function (response) {
            // if (response.data.data.length) {
            let deptTree = response.data.data.map(v => { v.userId = deptPrefix + v.id; return v; })
            setDeptTreeData(deptTree);
            // } else {
            setFlagDept(response.data.data.length)
            // }
        }).catch(function (error) {
            setDepartmentTreeData([])
            //console.log(error);
            //console.log(JSON.stringify(error));
        });
    }

    useEffect(() => {
        deptUser(userData?.department?.id);
    }, [deptTreeData])

    useEffect(() => {
        setShowSkeleton(false);
        if (window.innerWidth > 599 && departmentTreeData.filter(v => v.id == currentSelectedId)?.[0]?.employee?.length) {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }

    }, [departmentTreeData])

    {/* Department User Api */ }
    const deptUser = (id) => {
        //console.log(id)
        setCurrentSelectedId(id);
        // //console.log(id.substring(4))
        ApiService.get('orgDepartmentUser',{ departmentCode: id }).then(function (response) {
        // getDepartmentUser({ departmentCode: id }).then(function (response) {
            let departMent = [...deptTreeData];
            departMent = departMent.map(dept => {
                if (dept.id == id) {
                    dept.employee = response.data.data;
                }
                return dept;
            })
            //console.log({ departMent });
            setDepartmentTreeData(departMent);
        }).catch(function (error) {
            setDepartmentTreeData([]);
            //console.log(error);
            //console.log(JSON.stringify(error));
        });
    }

    const currentDeptId = (id) => {
        if (currentModule === 'departmentTree' && typeof id == "string" && id.indexOf(deptPrefix) != -1) {
            deptUser(id.substring(4));
            setShowSkeleton(true);
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
       
        <Typography pb={1} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Organization Tree</Typography>
                 
                <Box id='orgChart' sx={{minHeight: 'calc(95vh - 138px)'}}>
                    {/* <Box sx={{ m: 1, pt: 2, pb: .5 }}>
                        <Typography variant="h6" fontWeight={500} gutterBottom>
                            Organization Tree
                        </Typography>
                    </Box> */}
                        <Card>

                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="Organization Chart Tab" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                                    <StyledTab label="Employee Tree"  {...a11yProps(0)} onClick={() => setCurrentModule('employeeTree')} value="0" />
                                    <StyledTab label="department Tree"  {...a11yProps(1)} onClick={() => setCurrentModule('departmentTree')} value="1" />
                                </TabList>
                            </Box>

                            {/* {flag == 0 ? <div className="text-center py-3">
                                <img src="/noDataFeed.svg" />
                            </div> : (employeeTreeData.length || departmentTreeData.length) ? <> */}
                            {/* {(employeeTreeData.length || departmentTreeData.length) ? <> */}
                            <Box sx={{ mt: 4 }}>
                                {showSkeleton && <>
                                    <OrganizationSkeleton />
                                </>}
                                {/* two types structure and expander with Tabs */}
                                <Box sx={(currentModule === 'employeeTree' || !showSkeleton) ? {} : { display: 'none' }}>
                                    {flagEmp == 0 ? <div className="text-center py-3">
                                        <img src="/noDataOrganization.svg" />
                                    </div> : (employeeTreeData.length) ? <>
                                        <TabPanel value='0' index={0} sx={{ padding: 0 }}>
                                            <EmployeeTree treeData={employeeTreeData} currentCandidate={userData.userId} type={'structure'} currentDeptId={currentDeptId} />
                                        </TabPanel>
                                    </> : <>
                                        <OrganizationSkeleton />
                                    </>}
                                </Box>
                                {/* </> : <> */}
                                <Box sx={(currentModule === 'employeeTree' || showSkeleton) ? { display: 'none' } : {}}>
                                    {flagDept == 0 ? <div className="text-center py-3">
                                        <img src="/noDataOrganization.svg" />
                                    </div> : (departmentTreeData.length) ? <>
                                        <TabPanel value='1' index={1} sx={{ padding: 0 }}>
                                            {console.log({ treeData: departmentTreeData, currentCandidate: userData.userId, type: 'expander', currentDeptId: currentDeptId })}
                                            <EmployeeTree treeData={departmentTreeData} currentCandidate={userData.userId} type={'expander'} currentDeptId={currentDeptId} />
                                        </TabPanel>
                                    </> : <>
                                        <OrganizationSkeleton />
                                    </>}
                                </Box>
                                {/* </>} */}
                            </Box>

                        </TabContext>
                    </Card>
                </Box>
                </>
           
    )
}


export default OrganizationTree;