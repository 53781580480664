import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';
// import SkeletonCard from '../component/MoreComponent/SkeletonCard';

const LeaderSpeak = ({sharedHelper}) => {
  const dispatch = useDispatch();
  const [LeaderSpeak, setLeaderSpeak] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  // const userData = useSelector((state) => state?.user?.userListName)

  const showPostData = useSelector((state) => state.formData.formToSend);
  
  const indivisualAPI = 'LeaderSpeak';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)
  
  const userData = useSelector((state) => state?.user?.userListName)
  const callApi = () => {
    ApiService.get(indivisualAPI,{userId: userData?.userId })
      .then((res) => {
        let data = res.data.data;
        if (data) {
          console.log({data:data, formLeader: data.map(d=> formLeader(d))});
          setLeaderSpeak(data.map(d=> formLeader(d)));
        }
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);



  return (
    <Box>
      <Box>
        <Typography variant="h6" pb={.7} gutterBottom>Leader Speak</Typography>
      </Box>
      <Box>
        {
          LeaderSpeak.length ?
        <MyConnectLayout posts={LeaderSpeak} snackBar={snackBar}  isHeader={true} isLeaderSpeak={true} setPostsList={setLeaderSpeak} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
      : <PostSkeleton />
        }
        </Box>
    </Box>
  )
}

export default LeaderSpeak 