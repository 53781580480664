import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { closeConfirmDialog } from './confirmDialogBoxSlice';

function ConfirmDialogBox({ title, body, onCancel, onConfirm, titleOne, titleTwo, disableConfirmBtn = 'allow', ...props }) {
  const dispatch = useDispatch();
  const id = props?.id;
  const isOpen = useSelector((state) => (state.confirmDialog.id === id ? state.confirmDialog.isOpen : false));

  const handleDialogClose = () => {
    dispatch(closeConfirmDialog(id));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        sx={{ '& .MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm': { width: '100%' } }}
      >
        {title && (
          <DialogTitle id="dialog-title">{title}</DialogTitle>
        )}
        <DialogContent>
          {body}
        </DialogContent>
        {titleOne && titleTwo && (
          <DialogActions sx={{p:3}}>
            <Button onClick={onCancel}>{titleOne}</Button>
            <Button onClick={onConfirm} variant="contained" disabled={!disableConfirmBtn.length} >{titleTwo}</Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}

export default ConfirmDialogBox;
