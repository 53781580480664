import React, { useState, useEffect } from 'react'
import { Card, Stack, Typography,Visibility, Pagination,Menu, MenuItem, Grid, Box, styled, Chip, MoreVert, IconButton, CurrencyRupee, ChatBubbleOutlineOutlined, Chat, Divider, Tooltip } from '../../utils/AllImportsHelper'
// import { Divider } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import SkeletonCard from '../MoreComponent/SkeletonCard'

const MUICard = styled(Card)(({ theme }) => ({
    //    textAlign: 'center',
    padding: 0,
    border: '1px solid #E3E3E3',
    ':hover': {
        background:'#DBEAFE'
      },
      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
}));

const MyReferrals = () => {
    const userData = useSelector((state) => state?.user?.userListName)
    console.log(userData.userId)
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const longName = "Gautama Tiwari";
    const [myReferral, setMyReferral] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [meta, setMeta] = useState([])
    const [selectedReferral, setSelectedReferral] = useState({});
    useEffect(() => {
        ApiService.get('myReferral', {
            requestUserId: userData.userId,
            applicantRelated: ["job", "applicantReferralStatus", "status"]
        }).then(myReferral => {
            setMyReferral(myReferral.data.data)
            setMeta(myReferral?.data?.meta)
            // console.log(myReferral.data.data)
        })
    }, []);

    function formatDate(dateString) {
      if (dateString === null) {
        return 'Invalid Date';
    }
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const textStyle = {
        whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
        overflow: 'hidden',    // Hide any overflow text
        textOverflow: 'ellipsis', // Display ellipsis when text overflows
    };

    const statusColorMapping = {
        'Rejected-for-Interview': 'error',  // Red color for this status
        'Rejected by client': 'error',      // Red color for this status
        'Interview-in-Progress': 'warning', // Yellow color for this status
        'Rejected': 'error'
        // Add more status-color pairs as needed
    };

    // Get the color based on the status value, default to "primary" if not found
    const getColorForStatus = (status) => statusColorMapping[status] || 'success';

    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event,referral) => {
    setAnchorEl(event.currentTarget);
    setSelectedReferral(referral);
    console.log(referral)
     };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const getMenuItem = (referral) => {
    if (!referral) {
      return null; // Return null or handle the case when 'job' is undefined
    }
     console.log(referral)
    
      return (
        <MenuItem component={Link} to={`/job-description/${referral.jobId}`} 
        state={{ prevPage: "/job-referral",pageName: 'Job Referral' }}
        key={referral.jobId} onClick={handleClose}>
          <Visibility fontSize="small" color="action" />
          <Typography variant="body1" pl={1}>View Job</Typography>
        </MenuItem>
      );
    
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };


    return (
        <>
            {/* <Typography variant="subtitle1" fontWeight={500} >Referee</Typography> */}
            <Typography variant="subtitle1" pt={2} pb={1} >{myReferral.length} Referee</Typography>
            {(myReferral.length <= 0)  ? <SkeletonCard /> :
            <>
            <Box sx={{ flexGrow: 1, pt: 1 }}>
                <Grid container spacing={2}>
                    {myReferral.map((referral, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>

                            <MUICard>
                                <Grid container spacing={2} p={2}>
                                    <Grid item xs={6} sm={7} >
                                        <Tooltip title={referral.firstName + ' ' + referral.lastName}>
                                            <Typography variant="body1" style={textStyle} fontWeight={500} >{referral.firstName} {referral.lastName}</Typography>
                                        </Tooltip>
                                        {/* <Tooltip title={referral?.job?.jobTitle}>
                                           <Typography variant="subtitle2" style={textStyle} color={'secondary.textSecondary'}>{referral?.job?.jobTitle}</Typography>
                                        </Tooltip> */}
                                    </Grid>
                                    <Grid item xs={6} sm={5} display="flex" justifyContent="center">
                                        <Grid item xs={10} sm={10} textAlign='end'>
                                            {(referral?.status?.name) && (
                                                <Tooltip title={referral?.status?.name}>
                                                    <Chip style={textStyle} label={referral?.status?.name} size="small" color={getColorForStatus(referral?.status?.name)} sx={{ color: '#fff',fontSize:'12px' }} />
                                                </Tooltip>

                                            )}
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                            <IconButton aria-label="more" size="small" sx={{ alignItems: 'flex-start', }}  onClick={(event) => handleClick(event, referral)}>
                                                <MoreVert fontSize="inherit" />
                                            </IconButton>
                                           
                                                
                                        </Grid>
                                    </Grid>
                                    <Typography px={2} variant="subtitle2" style={textStyle} color={'secondary.textSecondary'}>{referral?.job?.jobTitle}</Typography>
                                </Grid>
                                
                                <Grid container spacing={2} py={1} px={2}>
                                    <Grid item sm={6} color={'#475569'}>

                                        <Box><Typography variant="body2" >Referral Date</Typography></Box>
                                        <Box>  <Typography variant="body2">Joining Date</Typography> </Box>
                                        <Box> <Typography variant="body2">Referral Amount</Typography> </Box>

                                    </Grid>
                                    <Grid item sm={6}>
                                        <Box><Typography variant="body2">{formatDate(referral?.created_at)}</Typography></Box>
                                        <Box><Typography variant="body2">{formatDate(referral?.referralPayable?.joiningDate)}</Typography></Box>
                                        <Box display="flex" alignItems="center">
                                            <CurrencyRupee fontSize="20" /><Typography variant="body2">{referral?.job?.referralAmount[0]?.amount}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" />
                                <Box display="flex" alignItems="center" justifyContent="flex-end" p={2}>
                                    {/* <ChatBubbleOutlineOutlined fontSize="small" color='primary' /><Typography variant="subtitle2" color='primary' pl={1}> CHAT</Typography> */}
                                </Box>
                            </MUICard>


                        </Grid>
                    ))}

                </Grid>
                <Stack spacing={2} justifyContent="flex-end" direction="row" pt={3}>
                {/* {(meta?.total >= 10) && */}
                <Pagination count={meta?.last_page}
                  page={currentPage}
                //   hidePrevButton={hidePrevButton}
                //   hideNextButton={hideNextButton}
                  // disabled = {disabled}
                  onChange={handlePageChange}
                // onChange={(_, page) => setCurrentPage(page)}
                />
              {/* } */}
                </Stack>
                {/* <Menu
          // id="save-menu"
          id={"long-menu" + referral?.jobId}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'save-menu',
          }}
        >
          {referral && getMenuItem(referral)}
        </Menu> */}
        <Menu
                id={`viewJob-${selectedReferral?.jobId}`}
                anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
              >
                {selectedReferral && getMenuItem(selectedReferral)}
              </Menu>
            </Box>
            </>
}
        </>
    )
}

export default MyReferrals