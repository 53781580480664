import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import { closeDrawer } from './drawerSlice';

// const useStyles = makeStyles((theme) => ({
//   drawerPaper: {
//     width: '240px',
//   },
// }));

function SharedDrawer({children,...props}) {
  const dispatch = useDispatch();
  const id = props?.id;
  const handleDrawerClose = () => {
    dispatch(closeDrawer(id));
  };

  const isOpen = useSelector((state) => { return (state.drawer.id == id) ? state.drawer.isOpen : false});



  // console.log({isOpen,children})
  return (
    <Drawer
      // sx={{ zIndex: 10000,'& .MuiDrawer-paperAnchorRight': { width: {xs:'100%',sm:'auto'}}}}
      sx={{ '& .MuiDrawer-paperAnchorRight': { width: {xs:'100%',sm:'auto'}}}}
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
    //   classes={{ paper: classes.drawerPaper }}
    >
    {children}
    </Drawer>
  );
}

export default SharedDrawer;
