import React from 'react'
import { useDispatch } from "react-redux";
import { Card, Typography, Grid, Box, styled,Tab,} from '../utils/AllImportsHelper'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
import Jobs from '../component/JobReferral/Jobs';
import MyReferrals from '../component/JobReferral/MyReferrals';
import SavedJobs from '../component/JobReferral/SavedJobs';
import BreadcrumbsComponent from '../component/Breadcrumbs';
import { addLink } from '../features/breadcrumbs/BreadcrumbsSlice'
// import DynamicBreadcrumbs from '../features/breadcrumbs/Breadcrumbs'



function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const JobReferral = (page) => {
    const [value, setValue] = React.useState('1');
    // const navigateToPage = (page) => {
      // Dispatch an action to add the link to the visitedLinks state
      const dispatch = useDispatch();
      dispatch(addLink(page));
      // Navigate to the new page
      // ...
    // };
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
  return (
    <>
    {/* <Typography variant="subtitle1" fontWeight={500} pb={1}>
          Job Referral
          </Typography> */}
{/* <DynamicBreadcrumbs /> */}
     <Typography pb={1} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Job Referral</Typography>
      <Card sx={{ minHeight: 'calc(95vh - 138px)' }}>
       <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} variant="scrollable" aria-label="Job Referral Tabs">
            <Tab label="Jobs" sx={{textTransform:'capitalize !important'}} {...a11yProps(1)} value="1" />
            <Tab label="My Referrals" sx={{textTransform:'capitalize !important'}} {...a11yProps(2)} value="2" />
            <Tab label="Saved Jobs" sx={{textTransform:'capitalize !important'}} {...a11yProps(3)} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{p:0}}><Jobs /></TabPanel>
        <TabPanel value="2" sx={{p:0}}><MyReferrals /></TabPanel>
        <TabPanel value="3" sx={{p:0}}><SavedJobs /></TabPanel>
      </TabContext>
   
      </Card>
    </>
  )
}

export default JobReferral