import React, {useState, useEffect} from "react";
import { TextField } from "@mui/material";

const FormTextField = (field) => {

    const [textField, setField] = useState(field.value);
    // const [fullWidth, setfullWidth] = useState(field.value);
    // const [type, setType] = useState(field.type);
// console.log(field.value)
    useEffect(() => {
        field.setTextField(textField)
    }, [textField])

    // useEffect(() => {
    //     field.setfullWidth(fullWidth)
    // }, [fullWidth])

    return (
    <TextField label={field.label}
    //  helperText={field.helperText} 
    //  error={field.error} 
     type={field.type} id={field.id} value={textField} onChange={(event) => {
        setField(event.target.value);
      }} required={field.required}
      fullWidth={field.fullWidth}
    //   fullWidth
    //   width={{field.fullWidth}}
      InputProps={field.InputProps}
     inputProps={{
        inputMode: field.inputMode,
        pattern: field.pattern,
        
      }}
      onKeyDown={(e) => field.onKeyDown && field.onKeyDown(e)}
      />)

}

export default FormTextField;