import React, { useState } from "react";
import { linkNewAccount, linkExistingAccount } from '../auth/ssoLinking';
import { useNavigate, Link } from "react-router-dom";
import logo from "../Login/images/logo.png";

// import './ssoLogin.css';
import {
    getTempSsoToken, setSelfToken,
    setAppToken, setSsoToken,
    setUserAuthType
} from "../auth/secureToken";


import { authentication } from '../config/AppConstants';
// import { Container, Grid } from "@mui/material";
import { Box, Button, CssBaseline, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';

function SSOLogin(props) {

    const [ssoEmail, setSSOEmail] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [hideButton, setHideButton] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showLinkAccount, setShowLinkAccount] = useState(false);
    const [showExistingAccount, setShowExistingAccount] = useState(false);
    // const [existinghide, setExistingButton] = useState(false)

    // let history = useHistory();
    const navigate = useNavigate();


    function linksso() {
        setHideButton(true)
        var x = document.getElementById("linkaccount");
        if (x.style.display == "none") {
            x.style.display = "block";
            document.getElementById("existingaccount").style.display = "none"
        }
        else {
            x.style.display = "none";
            // setHideButton(false)
        }
    }
    function existingSso() {
        setHideButton(true)
        var x = document.getElementById("existingaccount");
        if (x.style.display == "none") {
            x.style.display = "block";
            //   setExistingButton(true)
            //   setHideButton(false)

            document.getElementById("linkaccount").style.display = "none"
        } else {
            x.style.display = "none";

            //   setExistingButton(false)
        }
    }


    const submitNewHandler = (e) => {
        e.preventDefault();
        let postData = {};
        postData.ssoEmail = ssoEmail;
        postData.email = ssoEmail
        postData.password = "Pass@123"
        linkNewAccount(postData)
            .then(r => {
                // console.log('Registered Successfully');
                console.debug("submitNewHandler success r", r);
                // if(ssoTokenCookies.account) {

                if (r.data.data && r.data.data.linkStatus) {
                    let ssoTokenCookies = getTempSsoToken();
                    if (ssoTokenCookies && ssoTokenCookies.account) {
                        setSelfToken(r.data.data.token);
                        setAppToken(r.data.data.app);
                        setSsoToken(ssoTokenCookies.accessToken);
                        setUserAuthType(authentication.ssoAuthType);
                        navigate("/login");
                    } else {
                        console.debug("Something went wrong");
                    }

                } else {
                    console.debug("Something went wrong");
                }
            })
            .catch(error => {
                // error.message is the error message
            })
    }

    const submitExistingHandler = (e) => {
        e.preventDefault();
        let postData = {};
        postData.email = email
        postData.password = password
        postData.ssoEmail = email
        linkExistingAccount(postData).then(function (r) {
            // console.log('Registered Successfully');
            // history.push("/login");
            // console.log(response);
            console.debug("submitExistingHandler success r", r);

            if (r.data.data && r.data.data.linkStatus) {
                let ssoTokenCookies = getTempSsoToken();
                if (ssoTokenCookies && ssoTokenCookies.account) {
                    setSelfToken(r.data.data.token);
                    setAppToken(r.data.data.app);
                    setSsoToken(ssoTokenCookies.accessToken);
                    setUserAuthType(authentication.ssoAuthType);
                    navigate("/login");
                } else {
                    console.debug("Something went wrong");
                }
            } else {
                console.debug("Something went wrong");
            }
        })
    }

    const formNewChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'ssoEmail') {
            setSSOEmail(e.target.value)
        }

    }
    const formExistingChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'email') {
            setEmail(e.target.value)
        }
        if (e.target.name == 'password') {
            setPassword(e.target.value)
        }

    }

    return (
        <Grid container component="main" sx={{ height: '100vh', background: '#E9EAED' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    mt: 10,
                    alignItems: 'center',
                }}
            >     <Box className="align-content">

                    <a href="#/login"><img width={150} height={130} src={logo} alt="logo" /></a>

                </Box>
                <Box className="align-content">
                    <Box ><Typography variant="h5" component="div" >Every interaction is an opportunity</Typography>   </Box>
                    <Box sx={{
                        mt: 0
                    }}><Typography variant="h5" component="div" >to create <strong>something remarkable.</strong></Typography> </Box>
                    <hr className="custom-register-line" />
                    <Box className="align-content">
                        <i className="">The Tingg Motto</i>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end" }} style={{marginRight:'2rem'}}>
                    <Box sx={{ mt: 5, alignItems: 'end' }}> Got an account? <a href="#/login" style={{ color: '#007bff'}}>Sign in.</a></Box></Box>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Link Your Account
                    </Typography>

                    {hideButton == false ? <Box onSubmit={""} sx={{ mt: 1 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={linksso}
                        >
                            Link With New Account
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={existingSso}
                        >
                            Link With Existing Account
                        </Button>
                    </Box> : ""}
                    <Box id="linkaccount" style={{ display: "none" }} onSubmit={""} sx={{ mt: 0}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="ssoEmail"
                            name="ssoEmail"
                            label="SSO Email"
                            onChange={(e) => setSSOEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={submitNewHandler}
                        >
                            Submit
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={existingSso}
                        >
                            Link With Existing Account
                        </Button>
                    </Box>
                    <Box id="existingaccount" style={{ display: "none" }} sx={{ mt: 0 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            // type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button style={{color:'#1BA1CB', fontSize:"700"}} onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</Button>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={submitExistingHandler}
                        >
                            Submit
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            style={{
                                background: ' #EC7C25',
                                border: '1px solid #fcb116'
                            }}
                            onClick={linksso}
                        >
                            Link With New Account
                        </Button>
                    </Box>
                </Box>
                <Box sx={{display: "flex", justifyContent: "center"  }} className="white"><p>By creating an account, I accept Tingg’s
                    <br />

                    <Link style={{ color: '#007bff'}} to="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></Link> and <Link style={{ color: '#007bff'}} to="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></Link></p>
                </Box>
            </Grid>
        </Grid>

        // <section className="content">
        //     <div className="row m-0">
        //         <div className="col-lg-6 col-md-6 col-sm-12 rightBox">
        //             <div className="inner-box">
        //                 <div className="content-container">
        //                     <div className="logo">
        //                         <a href="#/login"><img src="images/logo/logo.png" alt="logo" /></a>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="inner-box-heading">
        //                 <div className="content-container">
        //                     <h2 className="heading-style">Every interaction is an opportunity</h2>
        //                 </div>
        //                 <div className="content-container">
        //                     <h2 className="heading-style">to create <strong>something remarkable.</strong></h2>
        //                 </div>
        //                 <div className="content-container">
        //                     <hr className="custom-register-line" />
        //                 </div>
        //                 <div className="content-container">
        //                     <i>The Tingg Motto</i>
        //                 </div>
        //             </div>
        //         </div>


        //         <div className="col-lg-6 col-md-6 col-sm-12">
        //             <div className="card">
        //                 <div className="nav-text">Got an account? <a href="#/login">Sign in.</a></div>
        //                 <div className=" flex-column">
        //                     <h2 className="card-title">Link your account</h2>
        //                     <span className="card-title-desc"></span>
        //                     {hideButton == false ? <div className="mb-3">
        //                         <div className="form-group submitwrp">
        //                             <a type="submit" name="submit" onClick={linksso} className="btn btn-custom ">Link With New Account</a>
        //                         </div>
        //                         <div className="form-group submitwrp">
        //                             <a type="submit" name="submit" onClick={existingSso} className="btn btn-custom">Link With Existing Account</a>
        //                         </div>
        //                     </div> : ""}
        //                     <div className="form-content">
        //                         <div>
        //                             <div id="linkaccount" style={{ display: "none" }}>
        //                                 <div className="form-group">
        //                                     <label className="form-label">SSO Email*</label>
        //                                     <input id="ssoEmail" className="form-control" name="ssoEmail" onChange={formNewChangeHandler} />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <div className="captcha">
        //                                         <span className="invalid-feedback" role="alert">
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                                 <div className="form-group submitwrp">

        //                                     <input type="submit" name="submit" onClick={(e) => submitNewHandler(e)} className="btn btn-custom" value="Submit" />
        //                                 </div>
        //                                 <div className="form-group submitwrp"> <a type="submit" name="submit" onClick={existingSso} className="btn btn-custom">Link With Existing Account</a></div>
        //                             </div>
        //                             {/* <a>Link Existing Account</a> */}

        //                             <div id="existingaccount" style={{ display: "none" }}>
        //                                 <div className="form-group">
        //                                     <label className="form-label">Email*</label>
        //                                     <input id="email" className="form-control" name="email" onChange={formExistingChangeHandler} />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <label className="form-label">Password*</label>
        //                                     <input id="password" className="form-control" name="password" onChange={formExistingChangeHandler} />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <div className="captcha">
        //                                         <span className="invalid-feedback" role="alert">
        //                                         </span>
        //                                     </div>
        //                                 </div>
        //                                 <div className="form-group submitwrp">

        //                                     <input type="submit" name="submit" onClick={(e) => submitExistingHandler(e)} className="btn btn-custom" value="Submit" />
        //                                 </div>
        //                                 <div className="form-group submitwrp"><a type="submit" name="submit" onClick={linksso} className="btn btn-custom ">Link With New Account</a>
        //                                 </div>
        //                                 {/* <a>Link New Account</a> */}

        //                             </div>
        //                         </div>
        //                         <hr />
        //                         <div className="form-group">
        //                             <div className="white"><p>By creating an account, I accept Tingg’s
        //                                 <br />

        //                                 <a href="https://tinggit.com/terms-of-service" target="_blank"><u>Terms of Service</u></a> and <a href="https://tinggit.com/privacy-policies" target="_blank"><u>Privacy Policy</u></a></p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>






        //     </div>
        // </section>
    )
}

export default SSOLogin;