import React, { useState, useEffect } from 'react'
import { Card, Close, HighlightOff, CardHeader, CardContent, CardActions, Avatar, Typography, Grid, Stack, Pagination, FilterList, Box, styled, LocationOnOutlined, ArrowForwardOutlined, Button, Chip, MoreVert, IconButton, CurrencyRupee, ChatBubbleOutlineOutlined, Chat, Divider, Tooltip, tooltipClasses } from '../../utils/AllImportsHelper'
import useMediaQuery from '@mui/material/useMediaQuery';
import ApiService from '../../utils/ApiService';
import Profile from '../../assets/images/Avtaar.png'
import SharedDrawer from '../../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import FilterFindAssociateDrawer from '../drawerBody/associate/FilterFindAssociateDrawer';
import ViewProfile from '../drawerBody/associate/ViewProfile';
import SkeletonCard from './SkeletonCard';
import BreadcrumbsComponent from '../Breadcrumbs';
import AvatarTingg from "../../assets/images/AvatarTingg.png"

const MUICard = styled(Card)(({ theme }) => ({
  padding: 0,
  border: '1px solid #E3E3E3',
  ':hover': {
    background: 'rgba(238, 246, 255, 1)'
  },
  height: '100%', display: 'flex', flexDirection: 'column'

}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2065D1',
    color: '#fff',
    maxWidth: 100,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const FindAssociates = () => {

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const longName = "Gautama Tiwari";
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [associate, setAssociate] = useState([]);
  const [filters, setFilters] = useState({
    departmentData: '',
    branchChange: '',
    experienceLevels: '',
    selectSkill: ''
  });

  const dispatch = useDispatch();
  const filterFindAssociateDrawer = (id) => {
    dispatch(openDrawer(id));
  }

  const viewProfileDrawer = (id) => {
    dispatch(openDrawer(id));
  }



  const [filtersCleared, setFiltersCleared] = useState(false);
  console.log(filtersCleared)
  const handleClearSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      setFiltersCleared(true);
    } else {
      setFilters({
        departmentData: '',
        experienceLevels: '',
        branchChange: '',
        selectSkill: '',
      });
      setFiltersCleared(false);
    }
  };


  useEffect(() => {
    if (!filtersCleared) {
      fetchData();
    } else {
      setFiltersCleared(false);
    }
  }, [currentPage, filters]);

  const fetchData = () => {
    const apiParams = {};
    if (filters.departmentData) {
      // If both deptData and abcChange are selected, exclude limit and page params
      apiParams.departmentCode = filters.departmentData;
    }
    if (filters.branchChange) {
      apiParams.branchCode = filters.branchChange;
    }
    if (filters.experienceLevels) {
      apiParams.totalWorkExperience = filters.experienceLevels;
    }
    if (filters.selectSkill) {
      apiParams.skill = filters.selectSkill
      // apiParams.totalWorkExperience = filters.experienceLevels.max
    }
    //   else {
    // If either deptData or abcChange is not selected, include limit and page params
    apiParams.limit = itemsPerPage;
    apiParams.page = currentPage;
    //   }
    console.log(filters)
    if (filters.branchChange || filters.departmentData || filters.experienceLevels != '' || filters.selectSkill != '') {
      ApiService.get('usersFilter',
        apiParams).then(associate => {
          setAssociate(associate.data.data)
          setMeta(associate.data.meta)
          // console.log(associate.data.data)
        })
    } else {
      ApiService.get('orgDepartmentUser',
        { limit: itemsPerPage, page: currentPage }).then(associate => {
          setAssociate(associate.data.data)
          setMeta(associate.data.meta)
          // console.log(associate.data.data)
        })
    }

  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Function to update filters when 'Submit' is clicked in FilterUserDrawer
  const updateFilters = (selectedFilters) => {
    setFilters(selectedFilters);
    // Optionally, you can also reset the current page to 1 when filters change
    setCurrentPage(1);
  };
  const [hovered, setHovered] = useState(null);

  const handleMouseEnter = (index) => {
    setHovered(index);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
    display: 'grid'
  };
  return (
    <>
      <Typography pb={1} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Find Associate</Typography>
      {/* <Typography variant="subtitle1" fontWeight={500} mb={1}>Find Associate</Typography> */}
      <Card sx={{ minHeight: 'calc(95vh - 138px)' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Box display='flex' alignItems='center'>

            {

              (filters.branchChange || filters.departmentData || filters.experienceLevels != '' || filters.selectSkill != ''
              ) ? (
                <>
                  <Typography variant="subtitle1" pr={2}>{meta.total} results found</Typography>
                  <Button color='secondary' startIcon={<HighlightOff />} onClick={handleClearSearch}>
                    CLEAR
                  </Button>
                </>
              ) : (
                <Typography variant="subtitle1" pr={2} >{meta?.total} Associates</Typography>
              )}
          </Box>
          <IconButton aria-label="filter" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: .5 }} onClick={() => filterFindAssociateDrawer('filterAssociate')}>
            <FilterList fontSize="medium" />
          </IconButton>
          <SharedDrawer id='filterAssociate'>
            <FilterFindAssociateDrawer onUpdateFilters={updateFilters} filters={filters} />
          </SharedDrawer>
        </Stack>
        {(associate.length <= 0) && (meta.total != 0) ? <SkeletonCard /> :
          <>
            <Box sx={{ flexGrow: 1, pt: 1 }}>
              <Grid container spacing={2}>
                {associate.map((associates, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <MUICard>

                      <CardHeader
                        avatar={
                          <Avatar sx={{ width: 45, height: 45 }}>
                            {(associates.userAvatar === 'https://gatewayapi.eela.tech/media/?file=default.png' || associates.userAvatar === 'https://gatewayapi.eela.tech/media/') ? (
                              <img src={AvatarTingg} alt="Avatar" width="100%" />
                            ) : (
                              <img src={associates?.userAvatar} alt="Avatar" width="100%" />
                            )}
                          </Avatar>
                        }
                        title={
                          <Tooltip title={associates.userName + ' ' + associates.lastName}>
                            <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >
                              {associates.userName + ' ' + associates.lastName}
                            </Typography>
                          </Tooltip>
                        }
                        subheader={
                          <Tooltip title={associates?.designation?.designation}>
                            <Typography variant="subtitle2" className='overflow-text-Announcement' color={'secondary.textSecondary'} >
                              {associates?.designation?.designation ?? '---'}
                            </Typography>
                          </Tooltip>

                        }
                      />
                      <CardContent sx={{ pt: '0px' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={4.5} sm={4} color='secondary.main'>
                            <Typography variant="body2" >Department:</Typography>
                          </Grid>

                          <Grid item xs={7.5} sm={8}>
                            <Typography variant="body2">{associates.department?.department ?? '---'}</Typography>
                          </Grid>
                          <Grid item xs={4.5} sm={4} color='secondary.main'>
                            <Typography variant="body2">Years of Exp:</Typography>
                          </Grid>
                          <Grid item xs={7.5} sm={8}>
                            <Typography variant="body2">{associates.workExperience[0]?.totalWorkExperience ?? '---'}</Typography>
                          </Grid>
                          <Grid item xs={4.5} sm={4} color='secondary.main'>
                            <Typography variant="body2">Skills:</Typography>

                          </Grid>
                          <Grid item xs={7.5} sm={8} display='flex'>
                            {associates?.skills?.slice(0, 1).map((skill, index, array) => (
                              <Typography variant="body2" key={skill.id}>
                                {skill?.skill}
                                {index < array.length - 1 ? ', ' : ''}
                              </Typography>
                            ))}
                            {associates?.skills?.length > 1 && (
                              <HtmlTooltip
                                title={<Box><b>{associates?.skills?.slice(1).map((skill) => skill?.skill).join(', ')}</b></Box>}
                                placement="right"
                              >
                                <Typography
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                  onTouchStart={() => handleMouseEnter(index)} // Enable touch event for mobile
                                  onTouchEnd={handleMouseLeave}
                                  sx={{ cursor: 'pointer', mt: .3, mx: 1, fontWeight: 600 }}
                                  variant="caption" color='primary'>
                                  +{associates?.skills?.slice(1).length}
                                </Typography>

                                {/* </IconButton> */}
                              </HtmlTooltip>
                            )}
                          </Grid>
                        </Grid>

                      </CardContent>
                      <Divider variant="fullWidth" />
                      <CardActions sx={{ marginTop: 'auto' }}>
                        <Grid container spacing={1} direction="row" justifyContent="space-between">

                          <Grid item >
                            {associates?.branch?.branchName &&
                              // <Button size="small" color="secondary" startIcon={<LocationOnOutlined />}>
                              //   {associates?.branch?.branchName}
                              // </Button>
                              <Typography variant="caption" fontWeight={500} mt={.5} color="secondary.textSecondary" display="block">
                                <LocationOnOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px', marginBottom: 3 }} />
                                {associates?.branch?.branchName}
                              </Typography>
                            }
                          </Grid>
                          <Grid item   >
                            <Button size="small" endIcon={<ArrowForwardOutlined />} onClick={() => viewProfileDrawer(associates.userId)}>
                              VIEW PROFILE
                            </Button>
                            <SharedDrawer id={associates.userId}>
                              <ViewProfile userId={associates.userId} />

                            </SharedDrawer>
                          </Grid>
                        </Grid>
                      </CardActions>

                    </MUICard>


                  </Grid>
                ))}
              </Grid>
              <Stack spacing={2} justifyContent="flex-end" direction="row" pt={3}>
                <Pagination count={meta.last_page}
                  page={currentPage}
                  onChange={handlePageChange} />
              </Stack>

            </Box>
          </>
        }
      </Card>
    </>
  )
}

export default FindAssociates