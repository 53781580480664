import React, { useEffect, useState }  from 'react'
import { Box, Typography } from '@mui/material'
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';
import BreadcrumbsComponent from '../component/Breadcrumbs';

const AssociateSpotlight = () => {
  const dispatch = useDispatch();
  const [AssociateSpotlight, setAssociateSpotlight] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  const userData = useSelector((state) => state?.user?.userListName)
  const indivisualAPI = 'AssociateSpotlight';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI)
  const callApi = () => {
    ApiService.get(indivisualAPI, {userId: userData?.userId })
    .then((res) => {
      //console.log({ res });
      let data = res.data.data;
      if (data) {
        //console.log({ data });
        setAssociateSpotlight(data.map(d=> formLeader(d)));
        // setAssociateSpotlight(data);
      }
    })
    .catch((error) => {
      //console.log(error);
      setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
    });
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);



  return (
    <Box>
      <Box>
        <Typography pb={1.5} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Associate Spotlight</Typography>
      </Box>
      <Box>
      {
        AssociateSpotlight.length ?
        <MyConnectLayout posts={AssociateSpotlight} snackBar={snackBar} isAssociateSpotlight={true} isHeader={true} setPostsList={setAssociateSpotlight} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
        : <PostSkeleton />
      }
        </Box>
    </Box>
  )
}

export default AssociateSpotlight 