import { createSlice } from '@reduxjs/toolkit';

const dialogBoxSlice = createSlice({
  name: 'dialog',
  initialState: {
    isOpen: false,
    data: {},
    id: ''
  },
  reducers: {
    openDialog: (state, actions) => {
      state.isOpen = true;
      state.id = actions.payload;
    },
    closeDialog: (state, actions) => {  
      state.isOpen = false;
      state.id = actions.payload;
    },
    setData: (state,actions) => {
      state.data = actions.payload;
    }
  },
});

export const { openDialog, closeDialog, setData } = dialogBoxSlice.actions;

export default dialogBoxSlice.reducer;
