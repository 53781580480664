import React from 'react';
import './App.css';
import * as microsoftTeams from '@microsoft/teams-js';
import AppWrapper from './AppWrapper';
import ThemeProvider from './theme';

function TeamWrapper(props) {

    function myStopFunction(teamObj) {
        teamObj.appInitialization.notifySuccess();
        // var authTokenRequest = {
        //     // successCallback: function(result) { console.debug("Success: " + result); },
        //     successCallback: function(result) { console.debug("Success: "); },
        //     failureCallback: function(error) { console.debug("Error getting token: " + error); }
        // };
        // teamObj.authentication.getAuthToken(authTokenRequest);
    }
    if (microsoftTeams) {
        microsoftTeams.initialize();

        setTimeout(myStopFunction(microsoftTeams),5000);
        return (
            <ThemeProvider>
            <AppWrapper />
            </ThemeProvider>
            
        );

    } else {
        return (
            <h3>Microsoft Teams SDK not found.</h3>
        );

    }
}

export default TeamWrapper;