// import { setAppToken, setSelfToken, setSsoToken, setTempSsoToken, setUserAuthType } from "../_helper/secureToken";
import { setAppToken, setSelfToken, setSsoToken, setUserAuthType } from "../_helper/secureToken";
// import { ssoTeamsAppLogin } from "../_services/_graphServices/ssoTeamsAppLogin";
import { ssoTeamsAppLogin } from "../auth/ssoTeamsAppLogin.service";
// import { checkLinked } from "../_services/ssoLinking";
import { checkLinked } from "../auth/ssoLinking";
import { authentication } from "../config/AppConstants";
// import { RedirectURI } from "../const/AuthConst";
// import { RedirectURI } from "../const/AuthConst";
// import ApiError from "../error/ApiError";


async function TeamsSSOLoginHandler() {
    console.debug("TeamsSSOLoginHandler : start");
    var ssoTeamsAppLoginResponse=null;
    ssoTeamsAppLoginResponse = await ssoTeamsAppLogin().then(res=>{
        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : res : ",res);
        let checkLinkedResponse = null;
        if(res && res.preferredUserName){
            checkLinkedResponse = checkLinked(res.preferredUserName).then(r=>{
                if(r.data){
                    let linkStatus=r;	
                    // if (linkStatus.data && linkStatus.data.data && linkStatus.data.data.token && ssoResponse.accessToken) {
                    if (linkStatus.data && linkStatus.data.data && linkStatus.data.data.token ) {
                        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : checkLinked : res 3 : ",res);
                        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : checkLinked : res.preferredUserName : ",res.preferredUserName);
                        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : checkLinked : successful : r.data.data : ",r.data.data);
                        // console.log(linkStatus.data.data.token);
                        // add exception handler here***************
                        setSelfToken(linkStatus.data.data.token);
                        setAppToken(linkStatus.data.data.app);
                        // setSsoToken(ssoResponse.accessToken);
                        setSsoToken(res.preferredUserName);
                        setUserAuthType(authentication.ssoAuthType);
                        // history.replace("/");
                        // response={redirectUrl :"/"};
                        return {"redirectUrl" :"/"};
                        // window.location.assign("/login") 
                    }else if (r.data.errors) {
                        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : checkLinked : errors Something Went Wrong, Not able to login")
                        console.debug("TeamsSSOLoginHandler : ssoTeamsAppLogin : checkLinked : errors Need to change the code in else condition of sso login :  r.data.error : ",r.data.errors);
                        // setClientErrorDialogOpenStatus(true);
                        // throw new ApiError(r.data);
                    } else {
                        console.debug("Vikram : Something Went Wrong, Not able to login")
                        console.debug("Vikram : Need to change the code in else condition of sso login : r : ",r);
                        // User is not Linked
                        return r.data.data; 
                        // setTempSsoToken(ssoResponse)
                        // setTempSsoToken(r.preferredUserName)
                        return {'preferredUserName':res.preferredUserName};
                        // handleClickOpen()
                        // props.setLoggedin(false)
                    }
                }else{
                    console.error("Something Went Wrong, Not able to login Handle error : error : ",r)
                    // throw new ApiError(r);
                }
            });
        }
        return checkLinkedResponse;
    }).catch(e=>{
        console.debug("ssoTeamsAppLogin error : ", e);
        // throw new ApiError(e);

        
    });
    console.debug("TeamsSSOLoginHandler : ssoTeamsAppLoginResponse : ",ssoTeamsAppLoginResponse);
    return  ssoTeamsAppLoginResponse;
}


export default TeamsSSOLoginHandler;