import React from 'react'
import { Box } from '@mui/material'
import advertisment from '../../assets/images/advertisment.jpg'

const Advertisment = () => {
  return (
    <Box>
      <img src={advertisment} alt="Advertisment" className="responsive-img" style={{borderRadius:'6px'}}></img>
    </Box>
  )
}

export default Advertisment