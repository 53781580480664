import React, {useState,useEffect} from 'react'
import { Card, Typography, Grid, Box, styled, Group, Chip, MoreVert, Divider, Tooltip,
   MenuItem,Menu, FormControl, Select, Stack, FilterList, IconButton, PeopleOutline,
   WatchLaterOutlined,LocationOnOutlined,ArrowForwardOutlined,Button } from '../../utils/AllImportsHelper'
   import SharedDrawer from '../../features/drawers/Drawer';
   import DynamicSnackbar from '../../features/snackbar/Snackbar';
   import { useSelector, useDispatch } from 'react-redux';
   import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import { openDrawer, closeDrawer,setDrawerData } from '../../features/drawers/drawerSlice';
import FilterReferralJobDrawer from '../drawerBody/jobReferral/FilterReferralJobDrawer';
import ReferDrawer from '../drawerBody/jobReferral/ReferDrawer';
// import AdvertismentImg from '../../assets/images/image1.png';
import Advertisment from '../FeedPost.js/Advertisment'
// import { useState } from 'react';
import {  Link, useParams } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import DOMPurify from 'dompurify';
import BreadcrumbsComponent from '../Breadcrumbs';
import { useNavigate,useLocation } from 'react-router-dom';


const MUICard = styled(Card)(({ theme }) => ({
  //    textAlign: 'center',
  padding: 0,
  border: '1px solid #E3E3E3',
  //     background: '#F8FAFF',
  //     cursor: 'pointer',

}));
const JobDescription = () => {
  const { id } = useParams();
  const [jobDetail, setJobDetail] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterReferralJobDrawer = (id) => {
    dispatch(openDrawer(id));
  }
  
  console.log({jobDetail})
  // const referDrawer = (id) => {
  //   dispatch(openDrawer(id));
  // }

  const location = useLocation();
  const prevPage = location.state?.prevPage;
  const pageName = location.state?.pageName
  console.log(prevPage,{location, prevPage: location.state?.prevPage})

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);
  const handleBredcrumb = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBredcrumbClose = () => {
    setAnchorEl(null);
  };
  
  // useEffect(() => {
  //  ApiService.get('jobDetails',{},id).then(jobDetail => {
  //   if (jobDetail.status === 200) {
  //     setJobDetail(jobDetail.data.data)
  //   } else {
  //     // dispatch(openSnackbar({ dataLoad: true, message: jobDetail.message, severity: "error" }));
  //     alert('abc')
  //   } catch (error) {
  //     console.error('API request failed:', error);
  //     // setSnackBar({ dataLoad: true, message: 'API request failed', severity: 'error' });
  //   }
            
  //            // console.log(myReferral.data.data)
  //         })
  //   }, []);
  useEffect(() => {
    ApiService.get('jobDetails', {}, id)
      .then((jobDetail) => {
        setJobDetail(jobDetail.data.data);
        })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        // alert(error);
        });
  }, []); 
  
  const referDrawer = (id, jobId, jobCode, hiringManagerId, questionGroup) => {
    dispatch(setDrawerData({ jobId: jobId, jobCode: jobCode, hiringManagerId: hiringManagerId, questionGroup: questionGroup }))
    dispatch(openDrawer(id));
  }

    const sanitizeAndStyleHTML = (html) => {
      return DOMPurify.sanitize(html)
        .replace(/<strong>/g, '') // Remove <strong> tags
        .replace(/<\/strong>/g, '') // Remove </strong> tags
        .replace(/<ul>/g, '<div>')
        .replace(/<\/ul>/g, '</div>')
        .replace(/<li>/g, ' ')
        .replace(/<\/li>/g, '<br>');
    };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
  };

  return (
    <>
      <Box sx={{minHeight: 'calc(95vh - 100px)'}}>
        <Grid direction="row" container>
            <Grid item xs={12} sm={8}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          
        {/* <Typography variant='h6' sx={{display: 'flex'}}>
          <BreadcrumbsComponent />
          <Box px={1}>/</Box>
          <Box sx={{cursor: 'pointer','&:hover': {color: '#2563EB',}}} onClick={() => {navigate(prevPage)}}>
            {pageName}
            </Box>
            <Box px={1}>/</Box>
            <Box>{jobDetail?.jobTitle}</Box>
            </Typography> */}
            <Box sx={{display:'flex'}}>
        <Button
        id="bredcrumb-button"
        aria-controls={open ? 'bredcrumb-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleBredcrumb}
        sx={{minWidth:'unset',p:0}}
      >
        <MoreVert />
      </Button> 
      <Typography variant='h6' pr={1} pt={.5} display='flex' alignItems='center'>/</Typography>
      <Typography variant='h6' pt={.4}>{jobDetail?.jobTitle}</Typography>
      <Menu
        id="bredcrumb-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleBredcrumbClose}
        MenuListProps={{
          'aria-labelledby': 'bredcrumb-button',
        }}
      >
        <MenuItem onClick={handleBredcrumbClose}><Typography variant='h6'><BreadcrumbsComponent /></Typography></MenuItem>
        <MenuItem sx={{'&:hover': {color: '#2563EB',}}} onClick={() => {navigate(prevPage)}}><Typography variant='h6'>{pageName}</Typography></MenuItem>
       </Menu>
       </Box>
            <Button variant='contained' startIcon={<Group />} sx={{fontWeight:500}} onClick={() => referDrawer('referJob', jobDetail.jobId, jobDetail.jobCode, jobDetail?.hiringManager?.userId, jobDetail.questionGroup)}>REFER</Button>
            <SharedDrawer id='referJob'>
                      {/* <Box> */}
                        <ReferDrawer />
                      {/* </Box> */}
                    </SharedDrawer>
        </Stack>
        </Grid>
        </Grid>
        <Grid direction="row" container spacing={1} sx={{my:1}}>
        {jobDetail &&
            <Grid item xs={12} sm={8}>
        <Card>
       
            <Card>
            <Grid container spacing={1}>
            
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                        <Typography variant='body2' color='textSecondary'>Department:&nbsp;</Typography>
                        <Typography variant='subtitle2'> {jobDetail.department?.departmentName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                        <Typography variant='body2' color='textSecondary'>Job Type:&nbsp;</Typography>
                        <Typography variant='subtitle2'> {jobDetail.jobType?.jobType}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                        <Typography variant='body2' color='textSecondary'>Job Location:&nbsp;</Typography>
                        <Typography variant='subtitle2'> {jobDetail?.city?.city}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                        <Typography variant='body2' color='textSecondary'>Total Experience:&nbsp;</Typography>
                        <Typography variant='subtitle2'> {jobDetail.experience}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} sx={{display:{xs:'block',sm:'flex'}}}> */}
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                        <Typography variant='body2' color='textSecondary'>No. Of Position:&nbsp;</Typography>
                        <Typography variant='subtitle2'> {jobDetail.numberOfPositions}</Typography>
                    </Grid>
            </Grid>
            </Card>
            <Card sx={{my:2,background:'#F9F9F9'}}>
            <Typography variant='body1' fontWeight={500}>Job Description</Typography> 
            <Typography variant='body2' color='secondary.textSecondary' pt={1} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(jobDetail.jobDescription)) }} />
            
           
            </Card>
             
        </Card>
        </Grid>
        }
        <Grid item xs={12} sm={4} sx={{p:0}}>
             <Advertisment />
           </Grid>
        </Grid>
        <DynamicSnackbar />
      </Box>
      
    </>
  )
}

export default JobDescription