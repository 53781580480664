import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Grid, Box, Typography, IconButton, Hidden } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';

const AddPhotoComponent = ({ selectedFile, setSelectedFile, fileType, deleteBtn, showError, setShowError, showFileError, setShowFileError }) => {

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(showError || showFileError);
  }, [showError, showFileError]);

  const handleButtonClick = () => {
    // console.log("Checking")
    // document.getElementById("addMorePhotos").click();
    fileType.current.click();
  }

  const deleteAllFiles = () => {
    // document.getElementById("deleteAllPhotos").click();
    deleteBtn.current.click();
  }

  return (<Box>
    {selectedFile.length > 0 && (
      <Box sx={{ display: "flex", mx: 2, }}>
        <Grid container>
        <Grid item  xs={12} sm={4} sx={{}}>
        <Box>
          <Tooltip title="ADD MORE PHOTOS" arrow placement="top-end">
            <label onClick={handleButtonClick}>
              <Button startIcon={<ImageOutlinedIcon />}>ADD MORE PHOTOS</Button>
            </label>
          </Tooltip>
        </Box>
        </Grid>
        <Grid item  xs={12} sm={8} sx={{}}>
        <Box>
          <Tooltip title="DELETE ALL" arrow placement="top-end">
            <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={deleteAllFiles}>
              DELETE ALL
            </Button>
          </Tooltip>
        </Box>
        </Grid>
        </Grid>
        {/* <Box>
          <Tooltip title="ADD MORE PHOTOS" arrow placement="top-end">
            <label onClick={handleButtonClick}>
              <Button startIcon={<ImageOutlinedIcon />}>ADD MORE PHOTOS</Button>
            </label>
          </Tooltip>
        </Box> */}
        {/* <Box sx={{ mx: 1 }}>
          <Tooltip title="DELETE ALL" arrow placement="top-end">
            <Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={deleteAllFiles}>
              DELETE ALL
            </Button>
          </Tooltip>
        </Box> */}
      </Box>
    )}
    <Box sx={{ mx: 1, }}>
      <Box>
        <Tooltip title="ADD PHOTO" arrow placement="top-end">
          <IconButton
            aria-label="Add Photo"
            size="large"
            component="span"
            onClick={handleButtonClick}
            disabled={selectedFile.length > 0}
            style={{ display: selectedFile.length > 0 ? 'none' : ' ' }}
          >
            <ImageIcon fontSize="small" style={{ color: selectedFile.length > 0 ? 'grey' : '#1976D2' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  </Box>)
}

export default AddPhotoComponent;