import React,{useState,useEffect,useRef} from 'react'
import ApiService from '../../../utils/ApiService';
import {
    Card, Avatar,Link,Alert, Typography,TextField, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
    MenuItem, FormControl,UploadFile, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close
} from '../../../utils/AllImportsHelper'
import DynamicSnackbar from '../../../features/snackbar/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar, closeSnackbar } from '../../../features/snackbar/snackbarSlice';
import { closeDrawer } from '../../../features/drawers/drawerSlice';
import WordFile from "../../../assets/images/word.png";
import PDFFile from "../../../assets/images/pdf.png";
import ExcelFile from "../../../assets/images/excel.png"
import ImageFile from "../../../assets/images/picUpload.png"
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import { tooltipClasses } from '@mui/material/Tooltip';
import { getAppToken } from '../../../_helper/secureToken';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#2563EB',
  
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: '#000000',
      fontWeight: '400',
      boxShadow: '-1px 0px 6px #aaa',
      // padding: "15px",
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #2563EB',
      borderRadius: 2,
    },
  }));


const ApplyDrawer = ({parentId,onApplicantCount}) => {
    const userData = useSelector((state) => state?.user?.userListName)
    // console.log(userData)
    const [inputKey, setInputKey] = useState(0);
    const [extensionErr, setRxtensionErr] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [message, setMessage] = useState([]);
  const [fileUploadData, setfileUploadData] = useState();
  const [internalApplyJob, setInternalApplyJob] = useState({});
  const [fileName, setFilename] = useState("")
    const progressInfosRef = useRef(null);
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const allowedExtensions = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.ppt', '.pptx', '.xlsx', '.xlsm', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const applyJobId = useSelector((state) => state?.drawer?.drawerData?.jobId)
    const [textFieldValue, setTextFieldValue] = useState('');

    const handleTextFieldChange = (event) => {
      setTextFieldValue(event.target.value);
      console.log(event.target.value)
    };
   
    useEffect(() => {
        // ApiService.get('orgDepartment').then(department => {
        //     setDepartment(department.data.data)
        //         //  console.log(department.data.data)
        //       })

        //       ApiService.get('branch').then(branch => {
        //         setBranch(branch.data.data)
        //             //  console.log(branch.data.data)
        //           })

        }, []);

        const selectFiles = (event) => {

            // console.log({event})
            const file = event.target.files[0];
            // console.log(file)
        
            if (file) {
              const invalidFiles = [];
              const fileExtension = '.' + file.name.split('.').pop();
              if (allowedExtensions.includes(fileExtension.toLowerCase())) {
                const imageUrl = URL.createObjectURL(file);
                setSelectedFiles([file]);
                setImagePreviews([imageUrl]);
                setProgressInfos({ val: [] });
                setMessage([]);
                setInputKey(inputKey + 1);
                setfileUploadData([file]);
                const progressInfo = {
                  percentage: 0,
                  fileName: file.name,
                  fileSize: file.size
                };
                progressInfosRef.current = {
                  val: [progressInfo],
                };
                upload(0, file);
                setRxtensionErr(false)
              } else {
                event.target.value = null; // Clear the input
                setRxtensionErr(true)
              }
        
            }
        
          };

          const handleRemove = (index) => {
            let removeFiles = [...selectedFiles];
            removeFiles.splice(index, 1);
            // setState({ uploadedFiles });
            setSelectedFiles([...removeFiles]);
        
            let removeImagePreviews = [...imagePreviews];
            removeImagePreviews.splice(index, 1);
            setImagePreviews(removeImagePreviews);
        
            let removeProgressInfos = [...progressInfos.val];
            removeProgressInfos.splice(index, 1);
            setProgressInfos({ val: removeProgressInfos });
          }
        
          function formatBytes(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return '0 Bytes';
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
          }
        
          const upload = async (idx, file) => {
            let _progressInfos = [...progressInfosRef.current.val];
        
            setProgressInfos({ val: _progressInfos });
        
          };

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    
    const handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();
    
    
      
      const files = event.dataTransfer.files;
    
      // Validate files and call selectFiles function
      selectFiles({...event, target: {...event.target,files: files}});
    };
    
    const handleApplyJob = async (jobId, userId) => {
        // try {
        //   const apply = {
        //     jobId: applyJobId,
        //     lastName: userData.lastName,
        //     firstName:  userData.firstName,
        //     applicantEmai:  userData.email,
        //     applicantMsg: textFieldValue,
        //     file: fileUploadData[0]   };
        const formData = new FormData();
    formData.append('jobId', applyJobId);
    formData.append('lastName', userData.lastName);
    formData.append('firstName', userData.userName);
    formData.append('applicantEmail', userData.email);
    formData.append('applicantMsg', textFieldValue);
    // formData.append('app', getAppToken());

    // Append selected files to the FormData
    fileUploadData.forEach((file, index) => {
    //   formData.append(`file${index + 1}`, file);
    formData.append(`file`, file);
    });
    try {
          const response = await ApiService.postAdopter('applyJob', formData);
    // console.log(response)
        //   const updatedJob = { ...selectedJob, postSaved: false };
        setInternalApplyJob(response);
        onApplicantCount();
          // dispatch(openSnackbar({ dataLoad: true, message: response.data.data[0].detail, severity: "info" }));
          dispatch(openSnackbar({ dataLoad: true, message: 'You have successfully applied to a job', severity: "info" }));
          dispatch(closeDrawer(parentId));
          
        } catch (error) {
          // Handle API call error
          dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        }
        // fetchData()
        // setAnchorEl(null);
      };

   
    return (
        <>
            {/* <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}> */}
            <Box sx={{width:{sm:'30rem'}}}>
                <Stack sx={{ backgroundColor: "#DBEAFE" }}>
                    <Box sx={{ p: '.5rem .1rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Apply</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
                    {/* <Typography variant="subtitle2" pb={1}>Location</Typography> */}
                  
                    <TextField fullWidth
            // sx={{my:2}}
          id="Q1"
          label="Why this job suitable for you"
          multiline
          rows={3}
          variant="outlined"
          required
        //   defaultValue="Default Value"
          value={textFieldValue}
          onChange={handleTextFieldChange}
        />
           <Box sx={{ mb: 2 }}>
              {/* <Box> */}
              <label htmlFor="fileUploadApply">
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: "0.5rem",
                    border: "2px dashed rgba(0, 0, 0, 0.12)",
                    borderRadius: "12px",
                    backgroundColor: "rgba(201, 201, 201, 0.1)",
                    ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                      <UploadFile color="primary" />
                    </Avatar>
                  </Box>
                  <Box className="upload-btn-wrapper">
                    <span>
                      <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                      Click to upload Resume
                      </Link>
                      <input
                        id="fileUploadRefer"
                        key={inputKey}
                        type="file"
                        name="myfile"
                        multiple
                        accept=".png, .jpg, .jpeg, .xls, .pdf, .ppt, .pptx, .xlsx, .xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={selectFiles}
                      />

                    </span>
                    <span> or drag and drop *</span>
                  </Box>
                  <Box m='1rem 0rem'>
                    {/* <Typography variant="caption" color="text.secondary" display="block" gutterBottom> PNG, JPG, XLS,PDF and PPT (max. 3MB)</Typography> */}
                    {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                    File(s) not supported. Upload a supported format.
                    </Alert> : ""}
                  </Box>
                </Stack>
              </label>
              <Box sx={{ ml: '3rem' }}>
                {progressInfos &&
                  progressInfos.val.length > 0 &&
                  progressInfos.val.map((progressInfo, index) => (
                    <Box key={index} sx={{ my: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2} md={2}>
                          <Box>
                            {(selectedFiles?.[index]?.name.endsWith(".docx")) ?
                              (
                                <>
                                  <img
                                    className="preview"
                                    src={WordFile}
                                    alt={"image-" + index}
                                    key={index}
                                  />
                                </>
                              )
                              : selectedFiles?.[index]?.name.endsWith(".pdf") ? (
                                <img
                                  className="preview"
                                  src={PDFFile}
                                  alt={"image-" + index}
                                  key={index}
                                />
                              ) : selectedFiles?.[index]?.name.endsWith(".xlsx") ||
                                selectedFiles?.[index]?.name.endsWith(".xls") ||
                                selectedFiles?.[index]?.name.endsWith(".csv") ? (
                                <img
                                  className="preview"
                                  src={ExcelFile}
                                  alt={"image-" + index}
                                  key={index}
                                />
                              ) : (
                                <img height={30} width={30} className="preview" src={ImageFile} alt={"image-" + index} key={index} />
                              )
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <Box>
                            <Box>
                              <HtmlTooltip title={progressInfo.fileName}>
                                <span>
                                  {progressInfo.fileName.length > 28
                                    ? progressInfo.fileName.substring(0, 28) + '...'
                                    : progressInfo.fileName}
                                </span>
                              </HtmlTooltip>
                            </Box>
                            <Box sx={{ my: 1 }}>
                              <Stack direction="row" spacing={2}>
                                <span><li>{formatBytes(progressInfo.fileSize)}</li></span>

                                <span>
                                  {message.length > 0 && (
                                    <Box className="alert alert-secondary" role="alert">
                                      <li>
                                        {message[index]}
                                      </li>
                                    </Box>
                                  )}
                                </span>
                              </Stack>
                            </Box>
                            <Box>
                              <LinearProgress variant="determinate" value={100} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Box sx={{ mb: 1 }}>
                            <IconButton aria-label="upload picture" component="label" onClick={() => handleRemove(index)}>
                              <Close />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Box>
            </Box>

                </Box>
                <DynamicSnackbar />
                <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
                    <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
                        <Button variant="contained" size="small"  
                        onClick={() => handleApplyJob()}
                         disabled={
                            (!selectedFiles || !progressInfos.val.length) ||
                            !textFieldValue.length 
                          }
                        >SUBMIT</Button>
                        <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default ApplyDrawer