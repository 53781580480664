
import { createSlice } from '@reduxjs/toolkit';



const imageCropperSlice = createSlice({
    name: 'imageCropper',
    initialState: {
        open: false,
        image: null,
        // onComplete: () => {},
        containerStyle: {},
    },
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setImage: (state, action) => {
            state.image = action.payload;
        },
        // setOnComplete: (state, action) => {
        //     state.onComplete = action.payload;
        // },
        // setContainerStyle: (state, action) => {
        //     state.containerStyle = action.payload;
        // },
    },
});

export const { setOpen, setImage } = imageCropperSlice.actions;
export default imageCropperSlice.reducer;
