import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
import ApiService from '../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import transformationHelper from '../utils/transformationHelper';
import PostSkeleton from '../component/CommonPage/PostSkeleton';
import { openSnackbar, closeSnackbar } from '../features/snackbar/snackbarSlice';

const FavoriteFeed = ({ sharedHelper }) => {
  const userData = useSelector((state) => state?.user?.userListName);
  const dispatch = useDispatch();
  const [savedPosts, setSavedPosts] = useState(false);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });

  const showPostData = useSelector((state) => state.formData.formToSend);
  const indivisualAPI = 'savedPosts';
  const formLeader = transformationHelper().getTransformationFunction(indivisualAPI);
  const flag = false
  // const userData = useSelector((state) => state?.user?.userListName)
  const callApi = () => {
    if (userData?.userId != undefined) {
      ApiService.get(indivisualAPI, { userId: userData?.userId })
        .then((res) => {
          let data = res.data.data;
          if (data) {
            setSavedPosts(data.map((d) => formLeader(d)));
            // dispatch(openSnackbar({ dataLoad: true, message: 'Your Post has been Report successfully.!', severity: 'info' }));
          }
          
        })
        .catch((error) => {
          setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
        });
    }
  }

  useEffect(() => {
    callApi();
  }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  // useEffect(() => {
  //   if (userData?.userId != undefined) {
  //     ApiService.get(indivisualAPI, { userId: userData?.userId })
  //       .then((res) => {
  //         let data = res.data.data;
  //         if (data) {
  //           setSavedPosts(data.map((d) => formLeader(d)));
  //         }
  //         dispatch(openSnackbar({ dataLoad: true, message: 'Your Post has been Report successfully.!', severity: 'info' }));
  //       })
  //       .catch((error) => {
  //         setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
  //       });
  //   }
  // }, [userData]);



  return (
    <Box>
      <Box>
        {savedPosts !== false ? (
          <>
            {savedPosts.length == 0 ? (<>
              <Typography variant="body2">There is no data.</Typography>
            </>) : (<>
              <MyConnectLayout
                posts={savedPosts}
                snackBar={snackBar}
                isHeader={true}
                setFlag={flag}
                setPostsList={setSavedPosts}
                indivisualAPI={indivisualAPI}
                formTransform={formLeader}
                reactPost={'reactPost'}
                isFavoriteFeeds={true}
                // onUnsavePost={handleUnsavePost} // Pass the unsave function to the layout component
              />
            </>)}
          </>
        ) : (
          <PostSkeleton />
        )}
      </Box>

{/* Check if savedPosts is an empty array */}
{/* <Box>
  {!savedPosts.length ? (<PostSkeleton />) : (savedPosts.length > 0 && savedPosts.length) ? (
    <>
    <MyConnectLayout
          posts={savedPosts}
          snackBar={snackBar}
          isHeader={true}
          setPostsList={setSavedPosts}
          indivisualAPI={indivisualAPI}
          formTransform={formLeader}
          reactPost={'reactPost'}
          isFavoriteFeeds={true}
          // onUnsavePost={handleUnsavePost} // Pass the unsave function to the layout component
        />
    </>
    ) : (
      <Typography variant="body2">There is no data.</Typography>
      )}

  
</Box> */}


    </Box>
  );
};

export default FavoriteFeed;
