import React from 'react'
import { Card, Typography, Grid, Box, styled,Tab,} from '../utils/AllImportsHelper'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PropTypes from 'prop-types';
import Feeds from './Feeds';
import FavoriteFeed from './FavoriteFeed';
import OrganizationFeed from './OrganizationFeed';
import SavedJobs from '../component/JobReferral/SavedJobs';
import Advertisment from '../component/FeedPost.js/Advertisment';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MyFavorite = () => {
  const flag = false
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
    <>
    <Grid item xs={12} sm={12} md={8}>
    <Typography variant="subtitle1" fontWeight={500} pb={1}>
          My Favorite
          </Typography>
          </Grid>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
    {/* <Typography variant="subtitle1" fontWeight={500} pb={1}>
          My Favorite
          </Typography> */}
      <Card sx={{ minHeight: 'calc(95vh - 138px)' }}>
       <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}  variant="scrollable" aria-label="lab API tabs example">
            <Tab label="Feeds" {...a11yProps(1)} value="1" sx={{textTransform:'capitalize'}} />
            <Tab label="Organization Feed" {...a11yProps(2)} value="2" sx={{textTransform:'capitalize'}} />
            <Tab label="Jobs" {...a11yProps(3)} value="3" sx={{textTransform:'capitalize'}} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{px:0}}><FavoriteFeed /></TabPanel>
        <TabPanel value="2" sx={{px:0}}><OrganizationFeed /></TabPanel>
        <TabPanel value="3" sx={{px:0}}><SavedJobs savedJobCount={false} /></TabPanel>
      </TabContext>
   
      </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={4} sx={{p:0}}>
           <Advertisment />
             </Grid>
      </Grid>
    </>
  )
}

export default MyFavorite