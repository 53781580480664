import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Card, Typography, Avatar, Button, Menu, MenuItem, ListItemIcon, IconButton } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, setData } from "../../features/dialogBox/dialogBoxSlice";
import { openConfirmDialog, closeConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import HideSourceIcon from '@mui/icons-material/HideSource';
import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import ApiService from '../../utils/ApiService';
// import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../features/snackbar/snackbarSlice';
import TextField from '../../component/forms/TextField';
import AvatarTingg from "../../assets/images/AvatarTingg.png"
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Popover from '@mui/material/Popover';
import Picker from "emoji-picker-react";

const Comment = ({ comment, feedPostComments, index, handleSnackbarClick, updateComment, reactPost, deleteComment, reportComment }) => {
  // console.log({ comment })
  const [comments, setComments] = useState(feedPostComments);
  const userData = useSelector((state) => state?.user?.userListName);
  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isEditing, setIsEditing] = useState(false);
  const [editedComment, setEditedComment] = useState(reactPost == 'reactPost' ? comment.commentBody : comment.comment);

  console.log('c1', editedComment)

  const handleCancelEdit = () => {
    setIsEditing(false);
  };


  const handleConfirmDialog = (id, postData) => {
    deleteComment(postData, comment.id)
  };


  const handleReportDialog = (id, postData) => {
    reportComment(postData, comment.id)
  };


  const inputRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target) && !event.target.
        closest
        (
          '.emoji-picker-container'
        )) {
        setIsEditing(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handleSaveEdit = () => {
    let api = reactPost == 'reactPost' ? 'updateComment' : 'UpdateComment';
    let payloadField = reactPost == 'reactPost' ? 'commentBody' : 'comment';
    let payload = { [payloadField]: editedComment };
    ApiService.put(api, payload, comment.id)

      .then((response) => {
        if (response.status === 200) {
          updateComment(comment.id, editedComment);
          // console.log(('abc1', editedComment))

          setIsEditing(false);
          dispatch(openSnackbar({ dataLoad: true, message: 'Comments Update successfully', severity: 'info' }));
        } else {
          dispatch(openSnackbar({ dataLoad: true, message: response?.data?.data[0].title, severity: 'error' }));
        }
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: 'error' }));
      });

    setIsEditing(false);
  };


  /////////////////////////
  const getMenuItem = (menu) => {
    const handleMenuClick = () => {
      // console.log("Menu clicked: " + menu.type);
      menu.onClick();
      setAnchorEl(null);
    };

    switch (menu.type) {
      case 'edit':
        return (
          <MenuItem key="Edit" onClick={handleMenuClick}>
            <ListItemIcon>
              <EditIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="body1">Edit</Typography>
          </MenuItem>
        );
      case 'delete':
        return (
          <MenuItem key="Delete" onClick={handleMenuClick}>
            <ListItemIcon>
              <DeleteIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="body1">Delete</Typography>
          </MenuItem>
        );
      case 'report':
        return (
          <MenuItem key="Report" onClick={handleMenuClick}>
            <ListItemIcon>
              <ReportIcon fontSize="medium" />
            </ListItemIcon>
            <Typography variant="body1">Report</Typography>
          </MenuItem>
        );
      default:
        return null;
    }
  };

  const getCurrentMenu = () => {
    const currentUserMenu = comment.userId === userData.userId;
    const currentMenus = [];

    if (currentUserMenu) {
      currentMenus.push(
        {
          type: 'edit',
          onClick: () => {
            setEditedComment(reactPost == 'reactPost' ? comment.commentBody : comment.comment);
            setIsEditing(true);
          },
        },
        {
          type: 'delete',
          onClick: () => {
            handleConfirmDialog('ConfirmDialog', comment);
          },
        }
      );
    } else {
      // For users other than the owner
      currentMenus.push(
        { type: 'report', onClick: () => handleReportDialog('ReportDialog', comment) },
      );
    }

    return currentMenus;
  };


  const getMenus = (menus) => {
    return menus.map(menu => getMenuItem(menu));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMoreIcons = (menus) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          aria-label="more"
          id="long-button"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {getMenus(menus)}
        </Menu>
      </Box>
    );
  };

  /////////////////

  /////////////emoji/////////////


  // const [commentText, setCommentText] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [editedCommentRefresh, setEditedCommentRefresh] = useState(true);

  useEffect(() => {
    if (!editedCommentRefresh) {
      setEditedCommentRefresh(true);
    }
  }, [editedCommentRefresh])

  const onEmojiClick = (event, emojiObject) => {
    setEditedComment((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
    setEditedCommentRefresh(false);
    console.log('c1', emojiObject.emoji);
  };

  const handleEmojiClick = (emojiObject) => {
    setShowPicker(emojiObject.currentTarget);
    console.log('c1', emojiObject.currentTarge);
  };

  const handleEmojiClose = () => {
    setShowPicker(false);
  };

  const openEmoji = Boolean(showPicker);
  const idEmoji = openEmoji ? 'simple-popover' : undefined;

  ///////////////////

  return (
    <Card sx={{ my: 1, p: 1 }} key={index}>
      <Box sx={{}}>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <Box sx={{}}>
              <Avatar
                src={comment.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || comment.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : comment.userAvatar}
                // src={comment.userAvatar}
                sx={{
                  backgroundColor: 'transparent !Important',
                  width: '40px',
                  height: '40px',
                  boxShadow: 'none',
                  '& .MuiAvatar-img': {
                    height: '40px',
                    width: '40px',
                    textAlign: 'center',
                    objectFit: 'cover',
                    color: 'transparent',
                    textIndent: '10000px'
                  }
                }}
              />
            </Box>
          </Grid>
          {isEditing ? (
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Box sx={{ '& .MuiFormControl-root': { width: '100%' } }} ref={inputRef}>
                {editedCommentRefresh && (
                  <TextField
                    id="comment"
                    variant="outlined"
                    value={editedComment}
                    setTextField={setEditedComment}
                    // onChange={(e) => setEditedComment(e.target.value)}
                    style={{ border: '2px solid red' }}
                  />
                )}
                <Box sx={{ mt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Box sx={{ color: '#6995D3' }} onClick={handleEmojiClick}>
                        <EmojiEmotionsIcon />
                      </Box>
                      <Popover
                        id={idEmoji}
                        open={openEmoji}
                        anchorEl={showPicker}
                        onClose={handleEmojiClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        className=
                        "emoji-picker-container"
                      >
                        <Picker Style={{ width: '100%', padding: '6px' }} onEmojiClick={onEmojiClick} />
                      </Popover>
                    </Grid>
                    <Grid item xs={10} display='flex' justifyContent='flex-end'>
                      <Box><Button variant="text" size="small" onClick={handleCancelEdit}>Cancel</Button></Box>
                      <Box sx={{ ml: 2 }}><Button variant="contained" size="small" onClick={handleSaveEdit} disabled={editedComment.length == ''}>Comment</Button></Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={8} sm={8} md={8} lg={9}>
              <Box>
                <Box sx={{}}>
                  <Box>
                    <Typography variant="defineSubtitle2" color="primary.heading">{comment['userName'] ? (comment['userName'] + (comment['middleName'] && comment['middleName'] != '--' ? comment['middleName'] : ' ') + (comment['lastName'] ? comment['lastName'] : ' ')) : comment['name']}</Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography variant="Caption" color="secondary.semiHeading2">
                      {new Date(comment.created_at).toLocaleDateString(undefined, {
                        month: 'long',
                        day: 'numeric',
                      })} at {new Date(comment.created_at).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Typography sx={{ mt: 1, overflowWrap: 'break-word' }} color='secondary' fontWeight='var(--font-weight-4)' variant='body1'>
                    {comment.commentBody ? comment.commentBody : comment.comment}
                  </Typography>
                  {/* {editedComment}
                     {isEditing ? editedComment : (comment.commentBody ? comment.commentBody : comment.comment)} */}
                </Box>
              </Box>
            </Grid>
          )}
          {!isEditing && (
            <Grid item xs={2} sm={2} md={2} lg={1}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: '4' }}>
                {renderMoreIcons(getCurrentMenu())}
              </Box>
            </Grid>
          )}
        </Grid>
        {/* <ConfirmDialogBox
          id='ReportDialog'
          title="Report the Comment"
          body={
          <Box sx={{'& .MuiFormControl-root': {mt:2, width: "-webkit-fill-available"}}}>
            <TextField id="outlined-basic" label="Description" multiline maxRows={4} variant="outlined" setTextField={setReportComment} value={reportComment} />
          </Box>}
          onCancel={closeDialogPublish}
          onConfirm={() => handleCommentReport(comments, reportComment)} 
          titleOne="Cancel"
          titleTwo="Report"
        /> */}
      </Box>
    </Card>
  );
}

export default Comment;
