import React, { useState } from 'react';
import { Box, IconButton, Typography, Grid, Stack, Divider, Tabs, Tab, Avatar, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { closeDialog } from '../../features/dialogBox/dialogBoxSlice';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Like from '../../../src/assets/images/like.svg';
import Mood from '../../../src/assets/images/mood.svg';
import Clapping from '../../../src/assets/images/clapping.svg';
import Favourite from '../../../src/assets/images/favourite.svg';
import AvatarTingg from "../../assets/images/AvatarTingg.png"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// const [value, setValue] = useState(0);

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ReactionPostDialog() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const post = useSelector((state) => state?.dialog?.data);
  // console.log({post})

  const handlePostDialogClose = () => {
    dispatch(closeDialog());
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '-webkit-fill-available', height: '30rem', overflow: 'auto' }}>
      <Box sx={{ p: 1, backgroundColor: '#00aeef36' }}>
        <Stack>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10}>
              <Box display="flex" sx={{ flexDirection: 'column', ml: '7px' }}>
                <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block">
                  Reactions
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="upload picture" component="label" color="var(--color-black)" onClick={handlePostDialogClose}>
                <CloseIcon sx={{ color: 'var(--color-black)' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Divider />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
            <Tab label="All" {...a11yProps(0)} value={0} />
            {post && post.post_reactions && post.post_reactions.filter(reaction => reaction.reactionId === 1).length > 0 && (
              <Tab icon={<img src={Like} alt="Like" title="Like" key="Like" style={{ marginRight: '6px' }} />} iconPosition="start" label={post.post_reactions.filter(reaction => reaction.reactionId === 1).length} {...a11yProps(1)} value={1} />
            )}

            {post && post.post_reactions && post.post_reactions.filter(reaction => reaction.reactionId === 2).length > 0 && (
              <Tab icon={<img src={Favourite} alt="Favourite" title="Favourite" key="Favourite" style={{ marginRight: '6px' }}/>} iconPosition="start" label={post.post_reactions.filter(reaction => reaction.reactionId === 2).length} {...a11yProps(2)} value={2} />
            )}

            {post && post.post_reactions && post.post_reactions.filter(reaction => reaction.reactionId === 3).length > 0 && (
              <Tab icon={<img src={Clapping} alt="Clapping" title="Clapping" key="Clapping" style={{ marginRight: '6px' }}/>} iconPosition="start" label={post.post_reactions.filter(reaction => reaction.reactionId === 3).length} {...a11yProps(3)} value={3}/>
            )}

            {post && post.post_reactions && post.post_reactions.filter(reaction => reaction.reactionId === 4).length > 0 && (
              <Tab icon={<img src={Mood} alt="Mood" title="Mood" key="Mood" style={{ marginRight: '6px' }}/>} iconPosition="start" label={post.post_reactions.filter(reaction => reaction.reactionId === 4).length} {...a11yProps(4)} value={4}/>
            )}
          </Tabs>
        </Box>
        <Box sx={{ height: "20rem", overflow: "auto" }}>
          <CustomTabPanel value={value} index={0}>
            {/* {console.log('abc',{post_reactions: post?.post_reactions})} */}
            {post?.post_reactions.map((reaction, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    my: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#ECEFF1',
                      '& .hover-visible': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Box>
                        <Avatar
                          src={reaction.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || reaction.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : reaction.userAvatar}
                          sx={{
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            backgroundColor: 'transparent !Important',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={7} lg={7}>
                      <Box sx={{ p: 0.5 }}>{reaction.userName}</Box>
                    </Grid>
                    <Grid item  xs={3} sm={4} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }} className="hover-visible">
                      <Button variant="text" component={Link} to={`/user-view-profile/${reaction.userId || reaction.pivot.user_id}`} onClick={handlePostDialogClose}>View Profile</Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {post.post_reactions.filter(reaction => reaction.reactionId === 1).map((reaction, index) => {
              // console.log({ reaction });
              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    my: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#ECEFF1',
                      '& .hover-visible': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Box>
                        <Avatar
                        //  src={reaction.userAvatar}
                          src={reaction.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || reaction.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : reaction.userAvatar}
                          sx={{
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            backgroundColor: 'transparent !Important',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={7} lg={7}>
                      <Box sx={{ p: 0.5 }}>{reaction.userName}</Box>
                    </Grid>
                    <Grid item  xs={3} sm={4} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }} className="hover-visible">
                      <Button variant="text" component={Link} to={`/user-view-profile/${reaction.userId || reaction.pivot.user_id}`}>View Profile</Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {post.post_reactions.filter(reaction => reaction.reactionId === 2).map((reaction, index) => {
              // console.log({ reaction });
              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    my: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#ECEFF1',
                      '& .hover-visible': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                 <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Box>
                        <Avatar
                          // src={reaction.userAvatar}
                          src={reaction.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || reaction.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : reaction.userAvatar}
                          sx={{
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            backgroundColor: 'transparent !Important',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={7} lg={7}>
                      <Box sx={{ p: 0.5 }}>{reaction.userName}</Box>
                    </Grid>
                    <Grid item  xs={3} sm={4} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }} className="hover-visible">
                      <Button variant="text" component={Link} to={`/user-view-profile/${reaction.userId || reaction.pivot.user_id}`}>View Profile</Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            {post?.post_reactions.filter(reaction => reaction.reactionId === 3).map((reaction, index) => {
              // console.log({ reaction });
              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    my: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#ECEFF1',
                      '& .hover-visible': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Box>
                        <Avatar
                          // src={reaction.userAvatar}
                          src={reaction.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || reaction.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : reaction.userAvatar}
                          sx={{
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            backgroundColor: 'transparent !Important',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={7} lg={7}>
                      <Box sx={{ p: 0.5 }}>{reaction.userName}</Box>
                    </Grid>
                    <Grid item  xs={3} sm={4} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }} className="hover-visible">
                      <Button variant="text" component={Link} to={`/user-view-profile/${reaction.userId || reaction.pivot.user_id}`}>View Profile</Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            {post.post_reactions.filter(reaction => reaction.reactionId === 4).map((reaction, index) => {
              // console.log({ reaction });
              return (
                <Box
                  key={index}
                  sx={{
                    p: 2,
                    my: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#ECEFF1',
                      '& .hover-visible': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={1} lg={1}>
                      <Box>
                        <Avatar
                          // src={reaction.userAvatar}
                          src={reaction.userAvatar === "https://gatewayapi.eela.tech/media/?file=default.png" || reaction.userAvatar === "https://gatewayapi.eela.tech/media/" ? AvatarTingg : reaction.userAvatar}
                          sx={{
                            height: '40px',
                            boxShadow: 'none',
                            width: '40px',
                            backgroundColor: 'transparent !Important',
                            '& .MuiAvatar-img': {
                              height: '40px',
                              width: '40px',
                              textAlign: 'center',
                              objectFit: 'cover',
                              color: 'transparent',
                              textIndent: '10000px',
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={7} lg={7}>
                      <Box sx={{ p: 0.5 }}>{reaction.userName}</Box>
                    </Grid>
                    <Grid item  xs={3} sm={4} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-end', visibility: 'hidden' }} className="hover-visible">
                      <Button variant="text" component={Link} to={`/user-view-profile/${reaction.userId || reaction.pivot.user_id}`}>View Profile</Button>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default ReactionPostDialog;
