// import React from "react";
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
// import { useNavigate, useLocation } from 'react-router-dom';

// const BreadcrumbsComponent = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <Link onClick={() => navigate("/More")}>More</Link>
//       {pathnames.map((name, index) => {
//         const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
//         const isLastLink = index === pathnames.length - 1;
//         return isLastLink ? (
//           <Typography key={name} color="textPrimary">{name}</Typography>
//         ) : (
//           <Link key={name} onClick={() => navigate(routeTo)}>{name}</Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default BreadcrumbsComponent;

// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
// import { useNavigate, useLocation } from "react-router-dom";

// const BreadcrumbsComponent = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   const [breadcrumbs, setBreadcrumbs] = useState([]);

//   useEffect(() => {
//     // Update breadcrumbs when the route changes
//     const newBreadcrumbs = [];
//     pathnames.forEach((_, index) => {
//       const route = `/${pathnames.slice(0, index + 1).join("/")}`;
//       newBreadcrumbs.push(route);
//     });
//     setBreadcrumbs(newBreadcrumbs);
//   }, [location.pathname]);

//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <Link onClick={() => navigate("/More")}>More</Link>
//       {breadcrumbs.map((route, index) => {
//         const isLastLink = index === breadcrumbs.length - 1;
//         return isLastLink ? (
//           <Typography key={route} color="textPrimary">
//             {pathnames[index]}
//           </Typography>
//         ) : (
//           <Link key={route} onClick={() => navigate(route)}>
//             {pathnames[index]}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default BreadcrumbsComponent;



// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
// import { useNavigate, useLocation } from "react-router-dom";

// const BreadcrumbsComponent = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   const [breadcrumbs, setBreadcrumbs] = useState([]);

//   useEffect(() => {
//     // Update breadcrumbs when the route changes
//     const newBreadcrumbs = [];
//     let breadcrumbPath = "";
    
//     pathnames.forEach((_, index) => {
//       const route = `/${pathnames.slice(0, index + 1).join("/")}`;
//       breadcrumbPath = breadcrumbPath === "" ? route : `${breadcrumbPath}/${route}`;
//       newBreadcrumbs.push(breadcrumbPath);
//     });
//     setBreadcrumbs(newBreadcrumbs);
//   }, [location.pathname]);

//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <Link onClick={() => navigate("/More")}>More</Link>
//       {breadcrumbs.map((route, index) => {
//         return (
//           <Link key={route} onClick={() => navigate(route)}>
//             {pathnames[index]}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default BreadcrumbsComponent;

// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
// import { useNavigate, useLocation } from "react-router-dom";

// const BreadcrumbsComponent = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   const [breadcrumbs, setBreadcrumbs] = useState([]);

//   useEffect(() => {
//     // Update breadcrumbs when the route changes
//     const newBreadcrumbs = [];
//     let breadcrumbPath = "";

//     pathnames.forEach((name, index) => {
//       breadcrumbPath = index === 0 ? "" : `${breadcrumbPath}/${name}`;
//       newBreadcrumbs.push(breadcrumbPath);
//     });

//     setBreadcrumbs(newBreadcrumbs);
//   }, [location.pathname]);

//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <Link onClick={() => navigate("/")}>Home</Link>
//       {breadcrumbs.map((route, index) => {
//         const isLastLink = index === breadcrumbs.length - 1;
//         return isLastLink ? (
//           <Typography key={route} color="textPrimary">
//             {pathnames[index]}
//           </Typography>
//         ) : (
//           <Link key={route} onClick={() => navigate(route)}>
//             {pathnames[index]}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default BreadcrumbsComponent;


// import React, { useEffect, useState } from "react";
// import Breadcrumbs from "@mui/material/Breadcrumbs";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
// import { useNavigate, useLocation } from "react-router-dom";

// const BreadcrumbsComponent = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   const [breadcrumbs, setBreadcrumbs] = useState([]);

//   useEffect(() => {
//     // Update breadcrumbs when the route changes
//     const newBreadcrumbs = [...pathnames];

//     setBreadcrumbs(newBreadcrumbs);
//   }, [location.pathname]);

//   return (
//     <Breadcrumbs aria-label="breadcrumb">
//       <Link onClick={() => navigate("/")}>Home</Link>
//       {breadcrumbs.map((route, index) => {
//         const isLastLink = index === breadcrumbs.length - 1;
//         const routePath = `/${breadcrumbs.slice(0, index + 1).join("/")}`;

//         return isLastLink ? (
//           <Typography key={routePath} color="textPrimary">
//             {route}
//           </Typography>
//         ) : (
//           <Link key={routePath} onClick={() => navigate(routePath)}>
//             {route}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default BreadcrumbsComponent;

import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";

const BreadcrumbsComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  
  useEffect(() => {
    // Create a copy of the current breadcrumbs
    const newBreadcrumbs = [...breadcrumbs];
    const newPathname = `/${pathnames.join("/")}`;
    
    // Only add the current pathname if it's not the same as the last one
    if (newBreadcrumbs.length === 0 || newBreadcrumbs[newBreadcrumbs.length - 1] !== newPathname) {
      newBreadcrumbs.push(newPathname);
    }
    
    // Update the breadcrumbs state
    setBreadcrumbs(newBreadcrumbs);
  }, [location.pathname]);

  const handleBreadcrumbClick = (index) => {
    const route = breadcrumbs[index];
    navigate(route);
     };

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link onClick={() => navigate("/more")} sx={{textDecoration:'none', color:'black', cursor:'pointer'}}>
      <Typography variant='h6' sx={{'&:hover': {color: '#2563EB',}}}> More </Typography>
      </Link>
      {/* {breadcrumbs.map((route, index) => (
        <Link sx={{textDecoration:'none', color:'black'}} key={route} onClick={() => handleBreadcrumbClick(index)}>
         <Typography variant='h6'>
          {pathnames[index]}
          </Typography> 
        </Link>
      ))} */}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;



