import React from 'react'
import { Box } from '@mui/material'
import SimpleImageSlider from "react-simple-image-slider";

const ImageSlider = () => {
    const images = [
        { url: "images/1.jpg" },
        { url: "images/2.jpg" },
        { url: "images/3.jpg" },
        { url: "images/4.jpg" },
        { url: "images/5.jpg" },
        { url: "images/6.jpg" },
        { url: "images/7.jpg" },
      ];
  return (
    <Box>
         <SimpleImageSlider
        width={896}
        height={504}
        images={images}
        showBullets={true}
        showNavs={true}
      />
    </Box>
  )
}

export default ImageSlider