import React, { useState, useEffect } from 'react'
import {
  Card, Typography, ListItem,BookmarkBorder,Bookmark, CircularProgress, HighlightOff, Save, Close, LabelOff, Grid, Pagination, MenuList, Box, styled, Chip, MoreVert, Divider, Tooltip,
  MenuItem, Menu, FormControl, Select, Stack, FilterList, IconButton, PeopleOutline, CardHeader, CardContent, CardActions,
  WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button
} from '../../utils/AllImportsHelper'
import SharedDrawer from '../../features/drawers/Drawer';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import { openDrawer, closeDrawer, setDrawerData } from '../../features/drawers/drawerSlice';
import FilterReferralJobDrawer from '../drawerBody/jobReferral/FilterReferralJobDrawer';
import ReferDrawer from '../drawerBody/jobReferral/ReferDrawer';
// import ReferDrawercopy from '../drawerBody/jobReferral/ReferDrawercopy';
import { Link } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import DOMPurify from 'dompurify';
import SkeletonCard from '../MoreComponent/SkeletonCard'



const MUICard = styled(Card)(({ theme }) => ({
  padding: 0,
  border: '1px solid #E3E3E3',
  ':hover': {
    background: '#DBEAFE'
  },
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
}));
const Jobs = () => {
  const [jobs, setJobs] = useState([])
  const [meta, setMeta] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [links, setLinks] = useState([]);
  const open = Boolean(anchorEl);
  const [selectedJob, setSelectedJob] = useState({});
  const userId = useSelector((state) => state?.user?.userListName.userId)
  const handleClick = (event, job) => {
    console.log("Clicked job:", job);
    setAnchorEl(event.currentTarget);
    setSelectedJob(job);
  };
  const handleClose = () => {
    console.log("Menu closed");
    setAnchorEl(null);
  };
  const [filters, setFilters] = useState({
    departmentData: '',
    experienceLevels: '',
    selectEducation: '',
    jobPriorityValue: '',
    countryData: '',
    cityData: ''
  });


  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
  };

  const sanitizeAndStyleHTML = (html) => {
    return DOMPurify.sanitize(html)
      .replace(/<strong>/g, '') // Remove <strong> tags
      .replace(/<\/strong>/g, '') // Remove </strong> tags
      .replace(/<ul>/g, '<div>')
      .replace(/<\/ul>/g, '</div>')
      .replace(/<li>/g, ' ')
      .replace(/<\/li>/g, '<br>');
  };

  const [filtersCleared, setFiltersCleared] = useState(false);
  const handleClearSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      setFiltersCleared(true);
    } else {
      setFilters({
        departmentData: '',
        selectEducation: [],
        experienceLevels: '',
        jobPriorityValue: '',
        countryData: '',
        cityData: '',
      });
      setFiltersCleared(false);
    }
  };

  useEffect(() => {
    if (!filtersCleared) {
      fetchData();
    } else {
      setFiltersCleared(false);
    }
  }, [currentPage, filters]);

  const fetchData = () => {
    const apiParams = {};
    if (filters.departmentData) {
      apiParams.department = filters.departmentData;
    }
    if (filters.countryData) {
      apiParams.country = filters.countryData;
    }
    if (filters.cityData) {
      apiParams.city = filters.cityData;
    }
    if (filters.jobPriorityValue) {
      apiParams.jobPriorityId = filters.jobPriorityValue;
    }
    if (filters.experienceLevels) {
      console.log(filters.experienceLevels);
      // apiParams.experience = '[' + filters.experienceLevels[0] + ']';
      apiParams.experience = filters.experienceLevels
    }
    if (filters.selectEducation) {
      apiParams.education = filters.selectEducation
      // apiParams.totalWorkExperience = filters.experienceLevels.max
    }
    apiParams.limit = itemsPerPage;
    apiParams.page = currentPage;
    apiParams.platformId = ['Referral']
    ApiService.get('jobFilter',
      apiParams).then(jobs => {
        setJobs(jobs.data.data)
        setMeta(jobs.data.meta)
        setLinks(jobs.data.links)
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        // alert(error);
      });
  }

  const dispatch = useDispatch();
  const filterReferralJobDrawer = (id) => {
    dispatch(openDrawer(id));
  }

  const referDrawer = (id, jobId, jobCode, hiringManagerId, questionGroup, recruitingManagerId) => {
    dispatch(setDrawerData({ jobId: jobId, jobCode: jobCode, hiringManagerId: hiringManagerId, questionGroup: questionGroup, recruitingManagerId: recruitingManagerId }))
    dispatch(openDrawer(id));
  }


  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const updateFilters = (selectedFilters) => {
    setFilters(selectedFilters);
    console.log(selectedFilters)
    setCurrentPage(1);
  };
  console.log(updateFilters)



  const handleSaveJob = async (jobId, userId) => {
    try {
      const saveJob = {
        jobId: jobId,
        userId: userId,
      };
      const response = await ApiService.post('favouriteJob', saveJob);

      const updatedJob = { ...selectedJob, postSaved: false };
      setSelectedJob(updatedJob);

      dispatch(openSnackbar({ dataLoad: true, message: 'You’ve saved this job. See saved jobs.', severity: "info" }));
    } catch (error) {
      // Handle API call error
      dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
    }
    fetchData()
    setAnchorEl(null);
  };


  const handleUnsaveJob = (jobId, index) => {
    ApiService.delete('unsaveJob', {}, jobId)
      .then((response) => {
        const updatedJob = { ...selectedJob, postSaved: true };
        setSelectedJob(updatedJob);
        dispatch(openSnackbar({ dataLoad: true, message: 'This job is no longer saved.', severity: "info" }));
        //console.log(response?.data?.data)
        // } 
      })
      .catch((error) => {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
      });
    fetchData()
    setAnchorEl(null);
  };


  const getMenuItem = (job) => {
    if (!job) {
      return null; // Return null or handle the case when 'job' is undefined
    }
    console.log(job)
    if (job.postSaved) {
      return (
        <MenuItem onClick={() => handleUnsaveJob(job.jobId)}>
          <Bookmark fontSize="small" color="action" />
          <Typography variant="body1" pl={1}>Unsave</Typography>
        </MenuItem>
      );
    } else {
      return (
        // <MenuItem onClick={() => handleSaveJob(job.jobId, job.user[0].userId)}>
        <MenuItem onClick={() => handleSaveJob(job.jobId, userId)}>
          <BookmarkBorder fontSize="small" color="action" />
          <Typography variant="body1" pl={1}>Save</Typography>
        </MenuItem>
      );
    }
  }


  const statusColorMapping = {
    'High': 'error',  // Red color for this status
    'Medium': 'primary',      // Red color for this status
    // 'Interview-in-Progress': 'warning', // Yellow color for this status
    // 'Rejected': 'error'
    // Add more status-color pairs as needed
  };

  // Get the color based on the status value, default to "primary" if not found
  const getColorForStatus = (status) => statusColorMapping[status] || 'warning';

  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ pt: 1 }}
        >
          <Box display='flex' alignItems='center'>
          {/* <Typography variant="subtitle1" pr={2} >Referral Jobs</Typography>
          {

(filters.cityData || filters.countryData || filters.departmentData || filters.experienceLevels != '' || filters.jobPriorityValue || filters.selectEducation != '') && (
            <>
            <Typography variant="subtitle1" pr={2} >{meta.total} results found</Typography>
           
                <Button color='secondary' startIcon={<HighlightOff />} onClick={handleClearSearch}>
                  CLEAR
                </Button>
</>
              )} */}
              {(
  filters.cityData ||
  filters.countryData ||
  filters.departmentData ||
  filters.experienceLevels != '' ||
  filters.jobPriorityValue ||
  filters.selectEducation != ''
) ? (
  <>
    <Typography variant="subtitle1" pr={2}>{meta.total} results found</Typography>
    <Button color='secondary' startIcon={<HighlightOff />} onClick={handleClearSearch}>
      CLEAR
    </Button>
  </>
) : (
  <Typography variant="subtitle1" pr={2}>{meta.total} Referral Jobs</Typography>
  
)}

          </Box>
          <IconButton aria-label="delete" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: .5 }} onClick={() => filterReferralJobDrawer('filterJob')}>
            <FilterList fontSize="medium" />
          </IconButton>
          <SharedDrawer id='filterJob'>
            {/* <Box sx={{ position: 'relative', minWidth: '25em', height: '100vh' }}> */}
            <FilterReferralJobDrawer onUpdateFilters={updateFilters} filters={filters} />
            {/* </Box> */}
          </SharedDrawer>
        </Stack>
        {(jobs.length == 0) && (meta.total != 0) ? (<SkeletonCard />) : jobs.length > 0 ? (
          <>
            <Box sx={{ flexGrow: 1, pt: 1 }}>
              <Grid container spacing={2}>

                {jobs.map((job, index) => (
                  <Grid item key={'jobs' + index} xs={12} sm={6} md={4} lg={4}>

                    <MUICard>
                      {/* <Grid container spacing={2} p={2} pb={0}>
                        <Grid item xs={6} sm={7} >
                          <Tooltip title={job.jobTitle}>
                            <Typography variant="body1" style={textStyle} fontWeight={500} >{job.jobTitle}</Typography>
                          </Tooltip>

                        </Grid>
                        <Grid item xs={6} sm={5} display="flex" justifyContent="center">
                          <Grid item sm={10} textAlign='end'>
                            {(job.jobPriority?.name) && (
                              <Chip label={job.jobPriority?.name} size="small" color="warning" />
                            )}
                          </Grid>
                          <Grid item sm={2}>
                            <IconButton sx={{ alignItems: 'flex-start', }}
                              aria-label="saveUnsaveJob" size="small"
                              id="save-menu-button"
                              aria-controls={open ? 'save-menu-button' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => handleClick(e, job)}
                            >
                              <MoreVert fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Box display="flex" alignItems="center" px={2}>
                          <Box pr={1}>
                            <PeopleOutline fontSize="small" color="action" />
                          </Box>
                          <Typography variant="body2" color="textSecondary" pb={.7}>
                            {job.jobApplication}
                          </Typography>
                        </Box>
                      </Grid> */}
                      <CardHeader sx={{ pb: '0px' }}
                        action={
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            {(job.jobPriority?.name) && (
                              <Chip label={job.jobPriority?.name} size="small" color={getColorForStatus(job.jobPriority?.name)} sx={{ color: '#fff', fontSize: '12px' }} />
                            )}

                            <IconButton sx={{ alignItems: 'flex-start', }}
                              aria-label="saveUnsaveJob" size="small"
                              id="save-menu-button"
                              aria-controls={open ? 'save-menu-button' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => handleClick(e, job)}
                            >
                              <MoreVert fontSize="inherit" />
                            </IconButton>
                          </Box>
                        }
                        title={
                          <Tooltip title={job.jobTitle}>
                            <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >{job.jobTitle}</Typography>
                          </Tooltip>
                        }
                        subheader={
                          //  <Box display="flex" alignItems="center" pt={1} sx={{'&:hover': {cursor: 'pointer','& > *': {color: '#2563EB !important',},},}}>
                          //   <Box pr={1}>
                          //     <PeopleOutline fontSize="small" color="action" sx={{'&:hover': {cursor: 'pointer','& > *': {color: '#2563EB !important',},},}} />
                          //   </Box>
                          //   <Typography variant="subtitle2" color="secondary.textSecondary" pb={.7} >
                          //     {job.jobApplication} Applicants
                          //   </Typography>
                          // </Box>
                          <Box
                          component={Link} to={`/job-description/${job.jobId}`}
                          state={{ prevPage: "/job-referral",pageName: 'Job Referral' }}
                            display="flex"
                            alignItems="center"
                            pt={1}
                            sx={{
                              textDecoration:'none',
                              '&:hover': {
                                cursor: 'pointer',
                                '& *': {
                                  color: '#2563EB !important',
                                },
                              },
                            }}
                          >
                            <Box pr={1}>
                              <PeopleOutline fontSize="small" color="action" />
                            </Box>
                            <Typography variant="subtitle2" color="secondary.textSecondary" pb={0.7}>
                              {job.jobApplication} Applicants
                            </Typography>
                          </Box>
                        }
                      />
                      <CardContent sx={{ pt: '0px' }}>
                        <Typography component={Link} to={`/job-description/${job.jobId}`} state={{ prevPage: "/job-referral",pageName: 'Job Referral' }} sx={{ textDecoration: 'none', height: '40px' }} variant="body2" className='overflow-text-Announcement' color="secondary.textSecondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(job.jobDescription)) }} />
                      </CardContent>
                      <Divider variant="fullWidth" />
                      <CardActions sx={{ marginTop: 'auto' }}>
                        <Grid container spacing={1} px={.5} justifyContent="space-between" direction="row">
                          <Grid item >
                            {/* <Button size="small" color="secondary" startIcon={<WatchLaterOutlined />}>
                            {job.jobType?.jobType}
                          </Button> */}
                            <Typography variant="caption" color='secondary.textSecondary' mt={.5} fontWeight={500} display="block">
                              <WatchLaterOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px', marginBottom: 2 }} />
                              {job.jobType?.jobType}
                            </Typography>
                          </Grid>
                          <Grid item >
                            {/* <Button size="small" color="secondary" startIcon={<LocationOnOutlined />}>
                            {job.location?.city?.city}
                          </Button> */}
                            <Typography variant="caption" fontWeight={500} mt={.5} color="secondary.textSecondary" display="block">
                              <LocationOnOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px', marginBottom: 2 }} />
                              {job.location?.city?.city}
                            </Typography>
                          </Grid>
                          <Grid item  >
                            <Button size="small" endIcon={<ArrowForwardOutlined />} onClick={() => referDrawer('referJob', job.jobId, job.jobCode, job?.hiringManager?.userId, job.questionGroup, job?.recruitingManager?.userId)}>
                              REFER
                            </Button>
                            <SharedDrawer id='referJob'>
                              {/* <Box> */}
                              <ReferDrawer />
                              {/* </Box> */}
                            </SharedDrawer>
                          </Grid>
                        </Grid>
                      </CardActions>
                      {/* </Box> */}
                    </MUICard>


                  </Grid>
                ))}

              </Grid>

            </Box>

            <Stack spacing={2} justifyContent="flex-end" direction="row" pt={3}>
              {/* {(meta.total >= 10) && */}
              <Pagination count={meta.last_page}
                page={currentPage}
                // hidePrevButton={hidePrevButton}
                // hideNextButton={hideNextButton}
                // disabled = {disabled}
                onChange={handlePageChange}
              // onChange={(_, page) => setCurrentPage(page)}
              />
              {/* } */}
            </Stack>
          </>
        ) : (
          // Show "There is no data" when there is no data
          <Typography variant="body2">There is no data.</Typography>
        )}


        {/* (filters.cityData || filters.countryData  || filters.departmentData  || filters.experienceLevels || filters.jobPriorityValue  ||  filters.selectEducation != '') && (  */}

        <DynamicSnackbar />
        <Menu
          // id="save-menu"
          id={"long-menu" + selectedJob?.jobId}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'save-menu',
          }}
        >
          {selectedJob && getMenuItem(selectedJob)}
        </Menu>
      </Box>
      {/* <SharedDrawer id='referJob'>
                        <ReferDrawer />
                       </SharedDrawer> */}
    </>
  )
}

export default Jobs