import React, { useState, useEffect } from 'react'
import {
    Card, Typography, ListItem, SaveOutlined, LabelOff,BookmarkBorder,Bookmark, Grid, Pagination, MenuList, Box, styled, Chip, MoreVert, Divider, Tooltip,
    MenuItem, Menu, FormControl, Select, Stack, FilterList, IconButton, PeopleOutline,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button,CardHeader, CardContent, CardActions,
} from '../../utils/AllImportsHelper'
import SharedDrawer from '../../features/drawers/Drawer';
import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import { openDrawer, closeDrawer,setDrawerData } from '../../features/drawers/drawerSlice';
import FilterReferralJobDrawer from '../drawerBody/jobReferral/FilterReferralJobDrawer';
import ReferDrawer from '../drawerBody/jobReferral/ReferDrawer';
import { Link } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import DOMPurify from 'dompurify';
import SkeletonCard from '../MoreComponent/SkeletonCard'



const MUICard = styled(Card)(({ theme }) => ({
    padding: 0,
    border: '1px solid #E3E3E3',
    ':hover': {
        background:'#DBEAFE'
      },
      boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'

}));
const SavedJobs = ({savedJobCount = true}) => {
    console.log({savedJobCount})
    const userId = useSelector((state) => state?.user?.userListName.userId)
    console.log(userId)
    const [jobs, setJobs] = useState([])
    const [meta, setMeta] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const dispatch = useDispatch();
    const [selectedJob, setSelectedJob] = useState({});
    const handleClick = (event, job) => {
        console.log("Clicked job:", job);
        setAnchorEl(event.currentTarget);
        setSelectedJob(job);
    };
    const handleClose = () => {
        console.log("Menu closed");
        setAnchorEl(null);
    };
    
    const textStyle = {
        whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
        overflow: 'hidden',    // Hide any overflow text
        textOverflow: 'ellipsis', // Display ellipsis when text overflows
    };

    const sanitizeAndStyleHTML = (html) => {
        return DOMPurify.sanitize(html)
            .replace(/<strong>/g, '') // Remove <strong> tags
            .replace(/<\/strong>/g, '') // Remove </strong> tags
            .replace(/<ul>/g, '<div>')
            .replace(/<\/ul>/g, '</div>')
            .replace(/<li>/g, ' ')
            .replace(/<\/li>/g, '<br>');
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = () => {
        // const apiParams = {};
        //  apiParams.limit = itemsPerPage;
        // apiParams.page = currentPage;
        // apiParams.platformId = ['Referral']
        ApiService.get('favouriteJob',{limit : itemsPerPage,page : currentPage},userId,
            ).then(jobs => {
                setJobs(jobs.data.data)
                setMeta(jobs.data.meta)
            })
            .catch((error) => {
                // Handle API call error
                dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
              });
    //     ApiService.get('favouriteJob', { limit: itemsPerPage, page: currentPage, userId: userId })
    // .then((jobs) => {
    //   setJobs(jobs.data.data);
    //   setMeta(jobs.data.meta);
    // })
    // .catch((error) => {
    //   // Handle API call error
    //   dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
    // });
    }

   
    // const referDrawer = (id) => {
    //     dispatch(openDrawer(id));
    //   }
    
    const referDrawer = (id, jobId, jobCode, hiringManagerId, questionGroup) => {
        dispatch(setDrawerData({ jobId: jobId, jobCode: jobCode, hiringManagerId: hiringManagerId, questionGroup: questionGroup }))
        dispatch(openDrawer(id));
      }
    
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    
    const handleSaveJob = async (jobId, userId) => {
        try {
            const saveJob = {
                jobId: jobId,
                userId: userId,
            };

            // Make the API call to save the job
            const response = await ApiService.post('favouriteJob', saveJob);
            const updatedJob = { ...selectedJob, postSaved: false };
            setSelectedJob(updatedJob);
            dispatch(openSnackbar({ dataLoad: true, message: 'You’ve saved this job. See saved jobs.', severity: "info" }));
        } catch (error) {
            // Handle API call error
            dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        }
        fetchData()
        setAnchorEl(null);
    };


    const handleUnsaveJob = (jobId, index) => {
        ApiService.delete('unsaveJob', {}, jobId)
            .then((response) => {
                const updatedJob = { ...selectedJob, postSaved: true };
                setSelectedJob(updatedJob);
                dispatch(openSnackbar({ dataLoad: true, message: 'This job is no longer saved.', severity: "info" }));
            })
            .catch((error) => {
                dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
            });
        fetchData()
        setAnchorEl(null);
    };

   const getMenuItem = (job) => {
        if (!job) {
            return null; // Return null or handle the case when 'job' is undefined
        }
        if (job.postSaved) {
            return (
                <MenuItem onClick={() => handleUnsaveJob(job.jobId)}>
                    <Bookmark fontSize="small" color="action" />
                    <Typography variant="body1" pl={1}>Unsave</Typography>
                </MenuItem>
            );
        } else {
            return (
                <MenuItem onClick={() => handleSaveJob(job.jobId, job.user[0].userId)}>
                    <BookmarkBorder fontSize="small" color="action" />
                    <Typography variant="body1" pl={1}>Save</Typography>
                </MenuItem>
            );
        }
    }

    const statusColorMapping = {
        'High': 'error',  // Red color for this status
        'Medium': 'primary',      // Red color for this status
        
    };
    
    // Get the color based on the status value, default to "primary" if not found
    const getColorForStatus = (status) => statusColorMapping[status] || 'warning';

    return (
        <>
            <Box>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    pt={savedJobCount ? 2 : 0} pb={1}
                >
                    {/* <Typography variant="body1" fontWeight={500} >Referral Jobs</Typography> */}
                    {/* { savedJobCount ? (
        <> */}
        { savedJobCount &&
             <Typography variant="subtitle1" >{meta?.total} Saved Jobs</Typography>
        }
             {/* </>
                     ) :
                     (
                        <></>
                     ) */}
{/* } */}
                    {/* <IconButton aria-label="delete" size="small" sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: .5 }} onClick={() => filterReferralJobDrawer('filterJob')}>
                        <FilterList fontSize="medium" />
                    </IconButton>
                    <SharedDrawer id='filterJob'>
                        <FilterReferralJobDrawer onUpdateFilters={updateFilters} />
                       </SharedDrawer> */}
                </Stack>

                <Box sx={{ flexGrow: 1, pt: 1 }}>
                    {(jobs.length == 0) && (meta.total != 0) ? (<SkeletonCard />) : jobs.length > 0 ? (
                  <>
                  <Grid container spacing={2}>
                        {jobs.map((job, index) => (
                            <Grid item key={'jobs' + index} xs={12} sm={6} md={savedJobCount ? 4 : 6} lg={savedJobCount ? 4 : 6}>

                                <MUICard>
                                    {/* <Grid container spacing={2} p={2} pb={0}>
                                        <Grid item xs={6} sm={7} >
                                            <Tooltip title="ABC">
                                                <Typography variant="body1" style={textStyle} fontWeight={500} >{job.jobTitle}</Typography>
                                            </Tooltip>

                                        </Grid>
                                        <Grid item xs={6} sm={5} display="flex" justifyContent="center">
                                            <Grid item sm={10} textAlign='end'>
                                                {(job.jobPriority?.name) && (
                                                    <Chip label={job.jobPriority?.name} size="small" color="warning" />
                                                )}
                                            </Grid>
                                            <Grid item sm={2}>
                                                <IconButton sx={{ alignItems: 'flex-start', }}
                                                    aria-label="saveUnsaveJob" size="small"
                                                    id="save-menu-button"
                                                    aria-controls={open ? 'save-menu-button' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(e) => handleClick(e, job)}
                                                >
                                                    <MoreVert fontSize="inherit" />
                                                </IconButton>

                                            </Grid>
                                        </Grid>
                                        <Box display="flex" alignItems="center" px={2}>
                                            <Box pr={1}>
                                                <PeopleOutline fontSize="small" color="action" />
                                            </Box>
                                            <Typography variant="body2" color="textSecondary" pb={.7}>
                                                {job.jobApplication}
                                            </Typography>
                                        </Box>

                                    </Grid> */}
                                    <CardHeader sx={{ pb: '0px' }}
                      action={
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {(job.jobPriority?.name) && (
                          <Chip label={job.jobPriority?.name} size="small" color={getColorForStatus(job.jobPriority?.name)} sx={{ color: 'white' }} />
                        )}
                        
                        <IconButton sx={{ alignItems: 'flex-start', }}
                              aria-label="saveUnsaveJob" size="small"
                              id="save-menu-button"
                              aria-controls={open ? 'save-menu-button' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => handleClick(e, job)}
                            >
                              <MoreVert fontSize="inherit" />
                            </IconButton>
                            </Box>
                      }
                        title={
                          <Tooltip title={job.jobTitle}>
                            <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >{job.jobTitle}</Typography>
                          </Tooltip>
                        }
                        subheader={
                          <Box display="flex" alignItems="center" pt={1} 
                          component={Link} to={`/job-description/${job.jobId}`}
                          sx={{
                            textDecoration: 'none',
                            '&:hover': {
                              cursor: 'pointer',
                              '& *': {
                                color: '#2563EB !important',
                              },
                            },
                          }}>
                          <Box pr={1}>
                            <PeopleOutline fontSize="small" color="action" />
                          </Box>
                          <Typography variant="subtitle2" color="secondary.textSecondary" pb={.7}>
                            {job.jobApplication} Applicants
                          </Typography>
                        </Box>
                        }
                      />
                      <CardContent sx={{ pt: '0px' }}>
                      <Typography component={Link} to={`/job-description/${job.jobId}`} sx={{ textDecoration: 'none',height:'40px' }} variant="body2" className='overflow-text-Announcement' color="secondary.textSecondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(job.jobDescription)) }} />
                      </CardContent>
                                    {/* <Typography px={2} mb={2} component={Link} to={`/job-description/${job.jobId}`} sx={{ textDecoration: 'none' }} variant="body2" className='overflow-text' color="textSecondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(job.jobDescription)) }} /> */}
                                    <Divider variant="fullWidth" />
                                    {/* <Grid container spacing={1} p={2} justifyContent="space-between" direction="row">
                                        <Grid item sm={4}>
                                            <Button size="small" color="secondary" startIcon={<WatchLaterOutlined />}>
                                                {job.jobType?.jobType}
                                            </Button>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button size="small" color="secondary" startIcon={<LocationOnOutlined />}>
                                                {job.location?.city?.city}
                                            </Button>
                                        </Grid>
                                        <Grid item sm={4} >
                                            <Button size="small" endIcon={<ArrowForwardOutlined />} onClick={() => referDrawer('referJob')}>
                                                REFER
                                            </Button>
                                            <SharedDrawer id='referJob'>
                                               <ReferDrawer />
 </SharedDrawer>
                                        </Grid>
                                    </Grid> */}
 <CardActions sx={{ marginTop: 'auto' }}>
                      <Grid container spacing={1} px={.5} justifyContent="space-between" direction="row">
                        <Grid item>
                          {/* <Button size="small" color="secondary" startIcon={<WatchLaterOutlined />}>
                            {job.jobType?.jobType}
                          </Button> */}
                           <Typography variant="caption" fontWeight={500} mt={.5} color="secondary.textSecondary" display="block">
      <WatchLaterOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px',marginBottom: 2 }} />
      {job.jobType?.jobType}
    </Typography>
                        </Grid>
                        <Grid item>
                          {/* <Button size="small" color="secondary" startIcon={<LocationOnOutlined />}>
                            {job.location?.city?.city}
                          </Button> */}
                           <Typography variant="caption" fontWeight={500} mt={.5} color="secondary.textSecondary" display="block">
      <LocationOnOutlined fontSize="small" style={{ verticalAlign: 'middle', marginRight: '4px',marginBottom: 2 }} />
      {job.location?.city?.city}
    </Typography>
                        </Grid>
                        <Grid item >
                          <Button size="small" endIcon={<ArrowForwardOutlined />} onClick={() => referDrawer('referJob',job.jobId,job.jobCode, job?.hiringManager?.userId, job.questionGroup)}>
                            REFER
                          </Button>
                          <SharedDrawer id='referJob'>
                            {/* <Box> */}
                            <ReferDrawer />
                            {/* </Box> */}
                          </SharedDrawer>
                        </Grid>
                      </Grid>
</CardActions>
                                    {/* </Box> */}
                                </MUICard>


                            </Grid>
                        ))}

                    </Grid>
                    </>
                      ) : (
 <Typography variant="body2">There is no data.</Typography>
 )}
                </Box>

                <Stack spacing={2} justifyContent="flex-end" direction="row" pt={3}>
                {/* {(meta.total >= 10) && */}
                    <Pagination count={meta.last_page}
                        page={currentPage}
                        onChange={handlePageChange} />
                {/* } */}
                </Stack>
                <DynamicSnackbar />
                <Menu
                    // id="save-menu"
                    id={"long-menu" + selectedJob?.jobId}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'save-menu',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                >

                    {selectedJob && getMenuItem(selectedJob)}
                </Menu>
            </Box>
        </>
    )
}

export default SavedJobs