import axios from 'axios';

export async function checkLinked(ssoEmail){
    const response = await axios.post(process.env.REACT_APP_BASEURL+'api/v2/sso/islink', {
            // ssoEmail: 'vikram.singh@forethought.in',
            ssoEmail: ssoEmail,
            existingUser:1,
          })
          .then(function (response) {
            // console.debug(response);
            return response;
          })
          .catch(function (error) {
            // console.debug(error);
            return error.response;
          });
    return response;
}

export async function createNewUser(){
  const response =  await axios.post(process.env.REACT_APP_BASEURL+'api/v2/sso/islink', {
            email: 'vikram.singh@forethought.in',
          })
          .then(function (response) {
            console.debug(response);
            return response;
          })
          .catch(function (error) {
            console.debug(error);
          });
  return response;
}

export async function linkWithExistingUser(){
  const response =   await axios.post(process.env.REACT_APP_BASEURL+'api/v2/sso/islink', {
            email: 'vikram.singh@forethought.in',
            existingUser:1,

          })
          .then(function (response) {
            console.debug(response);
            return response;
          })
          .catch(function (error) {
            console.debug(error);
          });
  return response;
}

export async function linkNewAccount(postData){
  const response = await axios.post(process.env.REACT_APP_BASEURL+'api/v2/linked/sso/create', {
     
          // ssoEmail: ssoEmail.ssoEmail,
            ssoEmail: postData.ssoEmail,
          email: postData.email,
          password: postData.password
          // email:ssoEmail.email
          // ssoEmail: ssoEmail.ssoEmail,
          // email:ssoEmail.email
          // existingUser:1,

        })
        .then(function (response) {
          console.debug("linkNewAccount  request call",response);
          return response;
        })
        .catch(function (error) {
          console.debug(error);
        });
  return response;
}
export async function linkExistingAccount(postData){
  const response = await axios.post(process.env.REACT_APP_BASEURL+'api/v2/linked/sso', {
     
          // ssoEmail: postData.ssoEmail,
          // email: postData.email,
          password: postData.password,
          ssoEmail: postData.ssoEmail,
          email:postData.email
          // existingUser:1,

        })
        .then(function (response) {
          console.debug("linkExistingAccount  request call",response);
          return response;
        })
        .catch(function (error) {
          console.debug(error);
        });
  return response;
}
