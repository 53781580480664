import React from 'react';
// import { makeStyles } from '@mui/styles';
import TeamsSwitchComponet from './TeamsSwitchComponent';
import AppSwitchComponet from './AppSwitchComponent';


function RouteComponet(props) {
    // let openBackdrop = useSelector((state) => state.loader.loader)

    // const [open, setOpen] = React.useState(openBackdrop);
    // const classes = useStyles();
    // let authenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;

    if (props.teams == true) {
        return (
            <TeamsSwitchComponet />
        )

    } else {
        return (
         
            <AppSwitchComponet />
        )
    }
}

export default RouteComponet;