import { createSlice } from "@reduxjs/toolkit";

export const headerSearch = createSlice({
    name: "search",
    initialState: {
        searchList: [],
        searchValue: ""
    },
    reducers: {
        searchDataList: (state, action) => {
            state.searchList = action.payload;
        },
        removeSearchList: state => {
            state.searchList = [];
        },
        searchValueSet: (state, action) => {
            state.searchValue = action.payload;
        },
        removeSearch: state => {
            state.searchValue = "";
        }
    }
});

export const { searchDataList, removeSearch, searchValueSet, removeSearchList } = headerSearch.actions;

export default headerSearch.reducer;
