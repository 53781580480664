import React, {useState, useEffect} from "react";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";

const FormRadioGroup = (field) => {

    const [textField, setField] = useState(field.value);

    useEffect(() => {
        field.setTextField(textField)
    }, [textField])

    return (<RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        // defaultValue={field.defaultValue}
        value={textField}
        onChange={(event) => {console.log(event.target.value); setField(event.target.value)}}
      >
        {field.controls.map((control, key) => {
            return (<FormControlLabel key={key} value={control.value} control={<Radio />} label={control.label} />)
        })}
      </RadioGroup>)

}

export default FormRadioGroup;