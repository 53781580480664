import { TeamsFx,IdentityType } from "@microsoft/teamsfx";
import * as microsoftTeams from '@microsoft/teams-js';

export async function ssoTeamsAppLogin() {
  let userObj=null;
  let errorObj=null;
  const teamsfx = new TeamsFx(IdentityType.User);
  var authTokenRequest = {
    successCallback: function(res) { 
      userObj={...userObj,ssoAccessToken:res};
      console.debug("Success: res:",res); 
      console.debug("Success: userObj:",userObj); 
      console.debug("Success: "); 
    },
    failureCallback: function(error) { console.debug("Error getting token: " + error); }
  };

  console.debug("ssoTeamsAppLogin : REACT_APP_CLIENT_ID : ",process.env.REACT_APP_CLIENT_ID);
  console.debug("ssoTeamsAppLogin : REACT_APP_START_LOGIN_PAGE_URL : ",process.env.REACT_APP_START_LOGIN_PAGE_URL);

  try {
    let scopes="User.Read";
    const result = await teamsfx.login(scopes);
    try {
      const resultgetUserInfo = await teamsfx.getUserInfo().then(r=>{
          console.debug("ssoTeamsAppLogin successful : teamsfx.getUserInfo Callback : ",r);
          userObj={...r};

        }).catch(e=>{
          console.debug("ssoTeamsAppLogin error : teamsfx.getUserInfo Callback :", e);
        });
      } catch (error) {
        console.debug("ssoTeamsAppLogin :  resultgetUserInfo : error:", error);
      }
      await microsoftTeams.authentication.getAuthToken(authTokenRequest);

    } catch (error) {
      errorObj=error;
      console.debug("ssoTeamsAppLogin : Login error:", error);
    }
    if(userObj){
      console.debug("ssoTeamsAppLogin successful : teamsfx.getUserInfo userObj : ",userObj);
      return userObj;
    }else{
      return errorObj;
    }
}

