import React from 'react';
import './index.css';
import "./App.css";
import TeamAppComponent from './TeamAppComponent.js';
import AppComponent from './AppComponent';


function AppWrapper(props) {
    return (
      
         
            <>
             {process.env.REACT_APP_ENV === 'teamDev' || process.env.REACT_APP_ENV === 'teamProd'? <TeamAppComponent teams={true}/> : <AppComponent teams={false}/> } 
                {/* <TeamAppComponent teams={true}/> */}
          
        </>
    );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

export default AppWrapper;