import React from 'react';
import * as _ from "underscore";
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@material-ui/core/Button';
import Button from '@mui/material/Button';

import { useHistory, useLocation, useNavigate, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SSODailog(props) {
  const [open, setOpen] = React.useState();
//   const history = useHistory();
const navigate = useNavigate();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  console.debug(props, "setPropos")

  const handleClose = () => {
    setOpen(false);
  };

  function redirectLink() {
    navigate('/sso-link')
    clearInterval(counter);
  }


  if (props.open == true) {
    var count = 5;
    var counter = setInterval(timer, 1000); //1000 will  run it every 1 second
    function timer() {
      count = count - 1;
      if (count == 0) {
        clearInterval(counter);
        redirectLink()
        //counter ended, do something here
        return;
      }
      console.debug(document.getElementById("timer"), "document.getElementById")
      document.getElementById("timer").innerHTML = count;
      //Do code for showing the number of seconds here
    }
}




  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> User is Not Registered or Linked with Tingg</DialogTitle>
        <DialogContent>
          The page  will automatically redirect after <span id="timer"></span> second to linking page
        </DialogContent>
        <DialogActions>
          <Button className="" onClick={redirectLink}>Link</Button>
          <Button className="" onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
